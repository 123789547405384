import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Pdfview from "../routes/pdfview.js";

import Home from "../routes/home.js";

import Reports from "../routes/reports.js";

import Messages from "../routes/messages.js";

import Prescriptions from "../routes/prescriptions.js";

import PrescriptionTransfer from "../routes/prescriptionTransfer.js";

import Profile from "../routes/profile.js";

import Meeting from "../routes/meeting.js";

import Calendar from "../routes/calendar.js";

import Help from "../routes/help.js";

import Settings from "../routes/settings.js";
import RxList from "../routes/rxlist.js";
import RxDetails from "../routes/rxDetails.js";
import PatientCalendar from "../routes/patientCalendar.js";
import MonitorVitals from "../screens/vitals/MonitorVitals.js";
import PillsRoute from "../routes/PillsRoute.js";
import Notifications from "../screens/notifications/Notifications.js";
import RecordVitals from "../screens/vitals/RecordVitals.js";

const RouteSwitcher = (props) => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path="/pdfview" exact>
        <Pdfview />
      </Route>

      <Route path="/home" exact>
        <Home />
      </Route>

      <Route path="/reports" exact>
        <Reports />
      </Route>

      <Route path="/messages" exact>
        <Help />
      </Route>

      <Route path="/prescriptions" exact>
        <Prescriptions />
      </Route>

      <Route path="/prescriptionTransfer" exact>
        <PrescriptionTransfer />
      </Route>

      <Route path="/profile" exact>
        <Profile />
      </Route>

      <Route path="/meeting" exact>
        <Meeting />
      </Route>

      <Route path="/calendar" exact>
        <PatientCalendar />
      </Route>

      <Route path="/help" exact>
        <Help />
      </Route>

      <Route path="/settings" exact>
        <Settings />
      </Route>

      <Route path="/rxlist" exact>
        <RxList />
      </Route>
      <Route path="/rxdetails" exact>
        <RxDetails />
      </Route>
      <Route path="/pills" exact>
        <PillsRoute />
      </Route>
      <Route path="/vitals" exact>
        <MonitorVitals />
      </Route>

      <Route path="/vitals/record" exact>
        <RecordVitals />
      </Route>

      <Route exact path="/">
        <Redirect to={"/home"} />
      </Route>

      <Route path="/notifications" exact>
        <Notifications />
      </Route>

      <Route path="/">
        <>Page Not Found</>
      </Route>
    </Switch>
  );
};

export default RouteSwitcher;
