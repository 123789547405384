import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Card } from "react-bootstrap";
import { Colours } from "../Colours";
import moment from "moment";
import { dbstore } from "../dataLayer/stores/dbStore";
import { vitalsStore } from "../dataLayer/stores/pages/vitalsStore";
import { vitalsActionStore } from "../dataLayer/stores/actions/vitalsActionStore";

const VitalRecords = observer(({ data }) => {
  const styles = {
    itemContainer: {
      color: Colours.primaryText,
      display: "flex",
      justifyContent: "space-between",
      fontSize: 18,
      lineHeight: 2.5,
      padding: "0 30px",
    },
    itemChild: { textAlign: "center" },
  };

  useEffect(() => {
    dbstore.getVitalRecords(vitalsStore.comp1Data.key, 20, 0);
  }, []);

  return (
    <Card
      style={{
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        border: "none",
        height: "100%",
      }}
    >
      <Card.Body>
        <div
          style={{
            color: Colours.primaryText,
            display: "flex",
            justifyContent: "space-between",
            fontSize: 18,
            lineHeight: 3,
            borderBottom: "1px black solid",
            padding: "0 30px",
          }}
        >
          <div style={styles.itemChild}>Record</div>
          <div style={styles.itemChild}>Date</div>
        </div>
        <div style={{
          height: "85vh",
          overflowY: "scroll",
        }} >
          {vitalsStore.comp4Data.values.map((item, index) => (
            <div key={index} style={styles.itemContainer}>
              <div style={styles.itemChild}>{item.value}</div>
              <div style={styles.itemChild}>{item.date}</div>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
});
export default VitalRecords;
