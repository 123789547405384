import { Col, message, Row } from "antd";
import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { dbstore } from "../../../dataLayer/stores/dbStore";
// import VitalInput from "../../VitalInput";
import VitalRecords from "../../VitalRecords";
import VitalInput from "./VitalInput";

const RecordVitals = () => {
  const history = useHistory();

  return (
    <Row>
      <Col span={24}>
        <IoIosArrowRoundBack
          title="back"
          size={50}
          className="mx-2"
          type="button"
          onClick={() => history.goBack()}
        />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <VitalInput
              onSubmit={async (obj) => {
                console.log(obj);
                if (obj.value || (obj.systolic && obj.diastolic)) {
                  var res = await dbstore.newVital(obj);
                  if (res == 200) {
                    message.success("Vital values successfully submitted")
                  } else {
                  message.error("Oops! something went wrong, please try again");
                  }
                } else {
                  message.error("Please select and submit")
                }
              }}
            />
          </Col>
          <Col span={12}>
            <VitalRecords />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RecordVitals;
