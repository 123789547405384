import { makeAutoObservable } from "mobx";

export default class LoginStore {
  isUserLoggedIn = false;

  constructor() {
    makeAutoObservable(this, {
      // setter: action
    });
  }

  // async *loginFunc(phone, otp) {
  //     this.loading = true;
  //     let cookies =  await CookieManager.get(endpoint);

  //     var raw = {
  //       phone: phone,
  //       otp: otp,
  //       deviceDetails: {
  //         // deviceId: DeviceInfo.getUniqueId(),
  //         // appBundleId: DeviceInfo.getBundleId(),
  //         deviceId: cookies['deviceId']?.value,
  //         appBundleId: cookies['appBundleId']?.value,
  //         deviceOS: Platform.OS
  //       }
  //     }
  //     var res = yield fetch(
  //       `${endpoint}/auth/patients/login`,
  //       {
  //         method: "post",
  //         headers: { "Content-Type": "application/json" },
  //         credentials: 'include',
  //         body: JSON.stringify(raw)
  //       }
  //     );

  //     var status = res.status;
  //     res = yield res.json();
  //     console.log(res, status);
  //     if (status == 200) {
  //       console.log("login suc")
  //       this.login = true;
  //       this.userid = res._id;
  //       yield this.getData()
  //     }
  //     this.loading = false
  //     return status;
  //   }

  //   *requestOTP(phone) {
  //     this.loading = true;
  //     console.log('requestotp ', phone)

  //     var raw = {
  //       phone: phone
  //     }
  //     var res = yield fetch(
  //       `${endpoint}/auth/patients/otp`,
  //       {
  //         method: "post",
  //         headers: { "Content-Type": "application/json" },
  //         credentials: 'include',
  //         body: JSON.stringify(raw)
  //       }
  //     );

  //     var status = res.status;
  //     console.log("status", status);
  //     res = yield res.json();
  //     console.log(res, status);
  //     if (status == 200) {
  //       this.loading = false;
  //     }
  //     return status;
  //   }

  //   *logoutFunc() {
  //     yield CookieManager.clearAll();
  //     var res = yield fetch(
  //       `${endpoint}/auth/patients/logout`,
  //       {
  //         method: "post",
  //         headers: { "Content-Type": "application/json" },
  //         credentials: 'include',

  //       }
  //     );

  //     var status = res.status;
  //     res = yield res.json();
  //     console.log(res, status);
  //     if (status == 200) {
  //       console.log("login suc")
  //       this.login = false;

  //     }
  //     return status;
  //   }

  *resetLoginStore() {
    yield (this.isUserLoggedIn = false);
  }
}

const loginStore = new LoginStore();
export { loginStore };
