const domain = process.env.DOMAIN;

export const bloodPressureObj = {
  name: "bloodPressure",
  displayName: "Blood Pressure",
  units: "cmHg",
};

export const bloodGlucoseObj = {
  name: "bloodGlucose",
  displayName: "Blood Glucose",
  units: "mgdl",
};

export const oxygenLevelsObj = {
  name: "oxygenLevels",
  displayName: "Oxygen Levels",
  units: "mmHg",
};

export const weightObj = {
  name: "weight",
  displayName: "Weight",
  units: "lbs",
};

export const temperatureObj = {
  name: "temperature",
  displayName: "Temperature",
  units: "F",
};

export const vitalTypes = {
  [bloodPressureObj.name]: bloodPressureObj,
  [bloodGlucoseObj.name]: bloodGlucoseObj,
  [oxygenLevelsObj.name]: oxygenLevelsObj,
  [weightObj.name]: weightObj,
  [temperatureObj.name]: temperatureObj,
};

export const vitalValueTypes = {
  [bloodPressureObj.name]: {
    systolic: {
      name: "systolic",
      displayName: "Systolic",
      defaultValue: 120,
    },
    diastolic: {
      name: "diastolic",
      displayName: "Diastolic",
      defaultValue: 80,
    },
    pulse: {
      name: "pulse",
      displayName: "Pulse",
      defaultValue: 72,
    },
  },
  [bloodGlucoseObj.name]: {
    fasting: {
      name: "fasting",
      displayName: "Fasting",
    },
    premeal: {
      name: "premeal",
      displayName: "Pre Meal",
    },
    postmeal: {
      name: "postmeal",
      displayName: "Post Meal",
    },
    beforesleep: {
      name: "beforesleep",
      displayName: "Before Sleep",
    },
    general: {
      name: "general",
      displayName: "General",
    },
  },
  [oxygenLevelsObj.name]: {
    value: {
      name: "value",
    },
  },
  [weightObj.name]: {
    value: {
      name: "value",
    },
  },
  [temperatureObj.name]: {
    value: {
      name: "value",
    },
  },
};

export const cookieAttributes = {
  path: "/",
  domain: domain,
};
