import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { dbstore } from "../../../dataLayer/stores/dbStore";
import {
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  List,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import HelpStore from "../../../dataLayer/stores/pages/help";
import { useHistory } from "react-router-dom";

const { Panel } = Collapse;
const { Text } = Typography;

const Notifications = observer(() => {
  let history = useHistory();

  useEffect(() => {
    dbstore.getUnreadData();
  }, []);

  return (
    <Row>
      <Col span={24}>
        {dbstore.unreadArr.length === 0 ? (
          <Empty description="No Notifications" />
        ) : (
          <Collapse onChange={() => {}}>
            <Panel
              style={{
                height: "70px",
              }}
              header={
                <Space>
                  <p>You have unread message(s)</p>
                  <Button
                    style={{
                      backgroundColor: "#23D098",
                      borderColor: "#23D098",
                      maxWidth: 140,
                      borderRadius: 20,
                      color: "#fff",
                    }}
                  >
                    View All
                  </Button>
                </Space>
              }
              key="1"
            >
              <List
                dataSource={dbstore?.unreadArr || []}
                renderItem={(unreadItem) => (
                  <List.Item>
                    <Space>
                      <Text>
                        You have unread message(s) from {unreadItem.users.name}
                      </Text>
                      <Button
                        style={{
                          backgroundColor: "#23D098",
                          borderColor: "#23D098",
                          maxWidth: 140,
                          borderRadius: 20,
                          color: "#fff",
                        }}
                        onClick={async () => {
                            await HelpStore.setCurrentUserName(unreadItem.users.name);
                            await HelpStore.setCurrentUser(unreadItem.users.id);
                            await HelpStore.setCurrentUserType(unreadItem.users.type);
                            history.push(`/messages`);
                        }}
                      >
                        View Message
                      </Button>
                    </Space>
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
        )}
      </Col>
    </Row>
  );
});

export default Notifications;
