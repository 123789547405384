import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Table } from "react-bootstrap";
import { Colours } from "../../../Colours";
import { observer } from "mobx-react";
import { dbstore } from "../../../dataLayer/stores/dbStore";
import moment from "moment";

const ActiveMedicationProfile = observer(({ data, onClick = () => {} }) => {
  const tableTitles = useRef([
    "Drug Name",
    "Date Filled",
    "Quantity",
    "Remaining Refills",
    "Start date", 
    "End date",
    "Current Status",
    "Rx Number",
  ]);

  const [maxRows, setMaxRows] = useState(5);

  return (
    <Card
      className={"mt-5"}
      style={{
        width: "100%",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <Card.Body className="pb-0">
        <Card.Title
          style={{
            fontFamily: "SourceSansPro-Regular",
            fontSize: 18,
            color: Colours.secondaryText,
          }}
        >
          Active Medication Profile
        </Card.Title>
        <Table
          responsive
          className="mb-0"
          style={{
            fontFamily: "SourceSansPro-Regular",
            fontSize: 13,
            color: Colours.secondaryText,
          }}
        >
          <thead
            style={{
              border: "none",
              backgroundColor: "#F5F6FA",
              color: "#A3A6B4",
            }}
          >
            <tr style={{ textTransform: "uppercase" }}>
              {tableTitles.current.map((title, index) => (
                <th key={`th-${index}`}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dbstore.activeRxes.slice(0, maxRows).map((rx, index) => {
              return (
                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    {rx.drugName}
                    <p>
                    Dr.{" "}
                    {`${rx?.prescriberDetails?.FirstName} ${rx?.prescriberDetails?.LastName}`}
                    </p>
                  </td>
                  <td>{moment(rx.dateFilledUTC).format("MMM DD YYYY")}</td>
                  <td>{rx.quantityLeft}</td>
                  <td>{rx.refillsRemaining}</td>
                  <td>{rx.refillStartDate ? moment.utc(rx.refillStartDate).format("DD MMM YYYY") : '-'}</td>
                  <td>{rx.refillEndDate ? moment.utc(rx.refillEndDate).format("DD MMM YYYY") : '-'}</td>
                  <td>{rx.currentRxStatusText}</td>
                  <td>{rx.rxNumber}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
      {maxRows <= data.length ? (
        <Button
          className="col-6 col-md-4"
          variant="link"
          size="sm"
          style={{
            fontSize: 13,
            boxShadow: "none",
            alignSelf: "center",
          }}
          onClick={() => {
            setMaxRows(maxRows + 5);
          }}
        >
          Show More
        </Button>
      ) : (
        <Button
          className="col-6 col-md-4"
          variant="link"
          size="sm"
          style={{
            fontSize: 13,
            boxShadow: "none",
            alignSelf: "center",
          }}
          onClick={() => {
            setMaxRows(5);
          }}
        >
          Show Less
        </Button>
      )}
    </Card>
  );
});

export default ActiveMedicationProfile;
