import { Col, Row } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import {
  IoChatbubblesSharp,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { observer } from "mobx-react";

const ChatWithPharmacyCard = observer(({ onClick }) => {
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "30px",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Card.Body
        style={{
          fontFamily: "Nunito-SemiBold",
          fontSize: "32px",
          color: "#272D5C",
          flex: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row>
          <Col span={5} offset={1}>
            <IoChatbubblesSharp
              size={50}
              className=" justify-content-center"
              color="#f50"
            />
          </Col>
          <Col span={14}>
            <span className="col text-center justify-content-center">
              Chat with your pharmacy
            </span>
          </Col>
          <Col span={4}>
            <IoChevronForwardCircleOutline size={40} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
});

export default ChatWithPharmacyCard;
