import { Col, Row, Layout, Space } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import PillAdherenceStatusCard from "./PillAdherenceStatusCard";
const PillsByDate = observer(({ data, onClick }) => {
  return (
    <Row gutter={[16, 16]} justify="center" >
      {data?.arr?.map((compliance, i) => {
        return (
          <Col span={8}>
            <PillAdherenceStatusCard
              data={{
                ...compliance,
                onclick: () => {
                  data.onclick(i);
                },
              }}
            />
          </Col>
        );
      })}
    </Row>
  );
});
export default PillsByDate;
