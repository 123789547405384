import React from "react";

const BloodGlucoseDeviceIcon = (props) => {
  return (
    <svg x="0px" y="0px" width="100%" viewBox="0 0 512 512">
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M226.000000,513.000000 
	C151.031357,513.000000 76.062698,513.000000 1.047026,513.000000 
	C1.047026,342.436035 1.047026,171.872055 1.047026,1.000000 
	C101.355133,1.000000 201.710587,1.000000 302.520020,1.428011 
	C302.645050,3.585333 302.071747,5.308893 302.030853,7.044984 
	C301.882812,13.334968 301.913422,19.629156 301.447662,25.948849 
	C292.940430,24.984976 284.867462,23.212345 276.782806,23.157219 
	C256.700806,23.020290 236.630219,22.287867 216.594879,25.799490 
	C194.038712,29.752941 172.459122,36.348534 151.827499,46.078674 
	C137.186905,52.983383 123.153809,60.997787 110.271286,70.860962 
	C106.764046,64.695259 103.431129,58.813339 99.952621,52.607189 
	C98.230095,49.641144 96.753815,46.932442 95.052948,44.372986 
	C92.418839,40.409203 90.326256,40.377747 87.817177,44.609741 
	C82.101547,54.250134 76.390022,63.909042 71.190712,73.829750 
	C69.616325,76.833809 68.426758,81.120399 69.347237,84.117310 
	C71.056938,89.683807 74.448051,94.733887 77.002747,100.319290 
	C73.514084,104.654678 69.824944,108.437973 66.872726,112.728340 
	C58.069443,125.521812 49.076649,138.224854 41.049229,151.502106 
	C30.971434,168.170700 24.139055,186.428452 19.538010,205.259247 
	C16.004154,219.722351 13.391287,234.589554 12.300130,249.419159 
	C11.246713,263.735809 12.048689,278.283661 13.193458,292.637573 
	C14.118795,304.240143 16.206074,315.831421 18.766872,327.205078 
	C23.979990,350.358765 33.165016,372.063965 45.199303,392.466370 
	C57.385696,413.126648 72.083504,431.876831 90.050491,448.380188 
	C97.164528,454.317261 103.879150,460.279755 111.133469,465.490906 
	C118.452431,470.748474 126.306938,475.260468 134.091919,480.409607 
	C144.550369,485.807343 154.595978,491.498322 165.192490,495.846863 
	C183.160446,503.220490 201.926163,508.048401 221.599884,510.900024 
	C223.345688,511.643677 224.672836,512.321838 226.000000,513.000000 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M352.000000,1.000000 
	C405.640472,1.000000 459.280975,1.000000 512.960693,1.000000 
	C512.960693,171.557220 512.960693,342.114471 512.960693,513.000000 
	C438.646088,513.000000 364.291718,513.000000 289.141357,512.658081 
	C289.184265,511.879639 289.977386,511.231476 290.869019,511.038483 
	C303.022125,508.407715 315.312500,506.309662 327.323578,503.147675 
	C345.850250,498.270416 363.097961,490.168121 379.741089,480.724762 
	C396.690857,471.107452 412.140808,459.445587 426.227112,446.075806 
	C433.383453,439.283478 439.720551,431.612457 446.266235,424.195923 
	C456.946442,412.094849 465.511444,398.549561 473.226776,384.412262 
	C484.128662,364.436096 492.243683,343.346588 496.748718,321.114502 
	C499.525726,307.409943 501.005737,293.341736 501.790833,279.365082 
	C502.466766,267.331757 501.925140,255.142990 500.830292,243.122375 
	C499.787628,231.674347 497.992035,220.199844 495.359863,209.011688 
	C490.085022,186.591125 481.350494,165.437851 469.848938,145.447556 
	C459.976135,128.288162 448.204651,112.510773 434.581146,98.203209 
	C420.091370,82.985855 404.020660,69.531059 385.738892,59.076309 
	C374.828766,52.837139 363.333740,47.620766 352.055969,41.479553 
	C352.006897,27.676323 352.003448,14.338161 352.000000,1.000000 
z"
      />
      <path
        fill="#8AC9FE"
        opacity="1.000000"
        stroke="none"
        d="
M352.101624,41.944626 
	C363.333740,47.620766 374.828766,52.837139 385.738892,59.076309 
	C404.020660,69.531059 420.091370,82.985855 434.581146,98.203209 
	C448.204651,112.510773 459.976135,128.288162 469.848938,145.447556 
	C481.350494,165.437851 490.085022,186.591125 495.359863,209.011688 
	C497.992035,220.199844 499.787628,231.674347 500.830292,243.122375 
	C501.925140,255.142990 502.466766,267.331757 501.790833,279.365082 
	C501.005737,293.341736 499.525726,307.409943 496.748718,321.114502 
	C492.243683,343.346588 484.128662,364.436096 473.226776,384.412262 
	C465.511444,398.549561 456.946442,412.094849 446.266235,424.195923 
	C439.720551,431.612457 433.383453,439.283478 426.227112,446.075806 
	C412.140808,459.445587 396.690857,471.107452 379.741089,480.724762 
	C363.097961,490.168121 345.850250,498.270416 327.323578,503.147675 
	C315.312500,506.309662 303.022125,508.407715 290.869019,511.038483 
	C289.977386,511.231476 289.184265,511.879639 288.672668,512.658081 
	C268.312439,513.000000 247.624878,513.000000 226.468658,513.000000 
	C224.672836,512.321838 223.345688,511.643677 221.606232,510.441895 
	C221.198532,484.533600 221.116364,459.148560 221.241959,433.764526 
	C221.311600,419.688721 220.817627,420.732544 208.617432,420.461609 
	C208.453583,420.457947 208.290833,420.405762 208.174057,420.062653 
	C210.578278,411.196045 216.645309,405.339752 223.103943,399.773407 
	C224.252960,400.908081 225.209335,401.832458 226.078186,402.832855 
	C244.859360,424.458313 268.699890,436.729706 297.073456,438.626862 
	C316.259308,439.909668 335.602478,438.839447 355.302032,438.862061 
	C358.866730,438.554932 362.015045,438.282318 365.141083,437.848755 
	C414.592010,430.990387 453.647217,386.678040 453.908203,336.621948 
	C454.158356,288.649780 454.072815,240.675049 453.944397,192.701950 
	C453.864197,162.731964 430.893494,140.096100 400.870789,140.000778 
	C384.548492,139.948959 368.226227,139.894791 351.915680,139.385376 
	C351.879700,108.308571 351.831940,77.688080 351.831970,46.641594 
	C351.953674,44.791943 352.027649,43.368286 352.101624,41.944626 
z"
      />
      <path
        fill="#CECECE"
        opacity="1.000000"
        stroke="none"
        d="
M352.055969,41.479553 
	C352.027649,43.368286 351.953674,44.791943 351.383423,46.623848 
	C341.582458,47.007748 332.277771,46.983402 322.980408,46.493019 
	C322.992218,36.269978 322.887024,26.511192 323.045288,16.756680 
	C323.130554,11.500673 323.667084,6.251988 324.000000,1.000000 
	C333.020905,1.000000 342.041779,1.000000 351.531342,1.000000 
	C352.003448,14.338161 352.006897,27.676323 352.055969,41.479553 
z"
      />
      <path
        fill="#BFBFBF"
        opacity="1.000000"
        stroke="none"
        d="
M323.531464,1.000000 
	C323.667084,6.251988 323.130554,11.500673 323.045288,16.756680 
	C322.887024,26.511192 322.992218,36.269978 322.998962,46.515884 
	C323.010162,47.004784 323.040283,47.054688 322.568420,47.037613 
	C315.746552,47.010586 309.396545,47.000637 303.018127,46.523590 
	C302.618713,39.344917 302.247650,32.633339 301.876617,25.921759 
	C301.913422,19.629156 301.882812,13.334968 302.030853,7.044984 
	C302.071747,5.308893 302.645050,3.585333 302.987000,1.428011 
	C309.687653,1.000000 316.375275,1.000000 323.531464,1.000000 
z"
      />
      <path
        fill="#8AC9FE"
        opacity="1.000000"
        stroke="none"
        d="
M301.447662,25.948849 
	C302.247650,32.633339 302.618713,39.344917 302.980225,46.992180 
	C302.955444,78.568825 302.940155,109.209778 302.463501,139.901733 
	C285.203949,139.968857 268.405701,139.974991 251.607529,140.005096 
	C242.582718,140.021271 234.142685,142.395355 226.509842,147.102158 
	C210.173325,157.176041 201.525482,171.900116 200.410583,190.928925 
	C198.104340,189.247910 196.047089,187.763947 193.641418,186.097107 
	C179.601151,180.515030 166.131836,186.509201 161.616547,199.655807 
	C159.989288,190.360703 157.254257,182.144882 147.881439,177.555634 
	C144.753891,176.541428 141.918549,175.262619 139.024002,175.110474 
	C126.912460,174.473923 119.567352,181.127457 114.903915,191.091888 
	C114.829063,174.131165 114.819862,157.675522 114.740707,141.220215 
	C114.692642,131.227539 111.295464,123.033707 101.643364,117.961075 
	C86.663795,111.473694 71.871414,119.216110 68.675522,134.862305 
	C68.443794,135.996811 68.176369,137.154648 68.176849,138.301529 
	C68.195724,183.201035 68.243835,228.100540 68.104111,273.296570 
	C63.060123,278.668030 59.805229,284.357574 59.852089,291.719879 
	C59.987461,312.988403 59.973362,334.258148 59.933975,355.527283 
	C59.919849,363.155396 62.079979,370.369720 66.933052,375.854584 
	C76.155205,386.277344 86.278091,395.903107 96.180145,406.165253 
	C98.869850,410.979614 101.406303,415.488617 103.513260,420.078766 
	C102.254349,420.180206 101.425041,420.213165 100.595543,420.218750 
	C91.620773,420.279053 90.452263,421.537750 90.364120,430.718018 
	C90.308563,436.505005 90.053406,442.290100 89.887787,448.076050 
	C72.083504,431.876831 57.385696,413.126648 45.199303,392.466370 
	C33.165016,372.063965 23.979990,350.358765 18.766872,327.205078 
	C16.206074,315.831421 14.118795,304.240143 13.193458,292.637573 
	C12.048689,278.283661 11.246713,263.735809 12.300130,249.419159 
	C13.391287,234.589554 16.004154,219.722351 19.538010,205.259247 
	C24.139055,186.428452 30.971434,168.170700 41.049229,151.502106 
	C49.076649,138.224854 58.069443,125.521812 66.872726,112.728340 
	C69.824944,108.437973 73.514084,104.654678 77.366745,100.322784 
	C84.784470,103.403351 91.758820,106.241409 99.770721,102.166618 
	C112.294952,96.031624 115.615982,86.077728 110.445610,71.144737 
	C123.153809,60.997787 137.186905,52.983383 151.827499,46.078674 
	C172.459122,36.348534 194.038712,29.752941 216.594879,25.799490 
	C236.630219,22.287867 256.700806,23.020290 276.782806,23.157219 
	C284.867462,23.212345 292.940430,24.984976 301.447662,25.948849 
z"
      />
      <path
        fill="#FE646F"
        opacity="1.000000"
        stroke="none"
        d="
M208.127563,420.376221 
	C208.290833,420.405762 208.453583,420.457947 208.617432,420.461609 
	C220.817627,420.732544 221.311600,419.688721 221.241959,433.764526 
	C221.116364,459.148560 221.198532,484.533600 221.187592,510.376373 
	C201.926163,508.048401 183.160446,503.220490 165.192490,495.846863 
	C154.595978,491.498322 144.550369,485.807343 134.111328,479.936035 
	C133.978088,463.419189 133.867020,447.684662 134.090988,431.954895 
	C134.146072,428.086884 135.293716,424.234375 136.353745,420.290405 
	C147.578247,420.402588 158.387894,420.743622 169.197968,420.758423 
	C182.174301,420.776215 195.151016,420.516052 208.127563,420.376221 
z"
      />
      <path
        fill="#FC4856"
        opacity="1.000000"
        stroke="none"
        d="
M135.939072,420.374756 
	C135.293716,424.234375 134.146072,428.086884 134.090988,431.954895 
	C133.867020,447.684662 133.978088,463.419189 133.946228,479.625549 
	C126.306938,475.260468 118.452431,470.748474 111.133469,465.490906 
	C103.879150,460.279755 97.164528,454.317261 90.050491,448.380188 
	C90.053406,442.290100 90.308563,436.505005 90.364120,430.718018 
	C90.452263,421.537750 91.620773,420.279053 100.595543,420.218750 
	C101.425041,420.213165 102.254349,420.180206 103.982582,420.148254 
	C115.233971,420.215973 125.586517,420.295349 135.939072,420.374756 
z"
      />
      <path
        fill="#FD4856"
        opacity="1.000000"
        stroke="none"
        d="
M99.433769,102.203354 
	C91.758820,106.241409 84.784470,103.403351 77.503113,100.002464 
	C74.448051,94.733887 71.056938,89.683807 69.347237,84.117310 
	C68.426758,81.120399 69.616325,76.833809 71.190712,73.829750 
	C76.390022,63.909042 82.101547,54.250134 87.817177,44.609741 
	C90.326256,40.377747 92.418839,40.409203 95.052948,44.372986 
	C96.753815,46.932442 98.230095,49.641144 99.694305,52.835339 
	C96.335770,58.996120 93.060020,64.587456 89.850136,70.216354 
	C82.334862,83.395210 85.996986,95.576485 99.433769,102.203354 
z"
      />
      <path
        fill="#FD6470"
        opacity="1.000000"
        stroke="none"
        d="
M99.770721,102.166618 
	C85.996986,95.576485 82.334862,83.395210 89.850136,70.216354 
	C93.060020,64.587456 96.335770,58.996120 99.839905,53.159565 
	C103.431129,58.813339 106.764046,64.695259 110.271286,70.860962 
	C115.615982,86.077728 112.294952,96.031624 99.770721,102.166618 
z"
      />
      <path
        fill="#9C9C9C"
        opacity="1.000000"
        stroke="none"
        d="
M351.903931,139.841675 
	C368.226227,139.894791 384.548492,139.948959 400.870789,140.000778 
	C430.893494,140.096100 453.864197,162.731964 453.944397,192.701950 
	C454.072815,240.675049 454.158356,288.649780 453.908203,336.621948 
	C453.647217,386.678040 414.592010,430.990387 365.141083,437.848755 
	C362.015045,438.282318 358.866730,438.554932 355.004944,438.652710 
	C341.144592,436.107605 328.301147,432.725586 316.818146,425.770874 
	C310.835602,422.147461 305.512085,417.435974 300.365967,413.147217 
	C314.909485,413.001801 328.977936,412.925171 343.509460,412.871033 
	C347.971283,412.876343 352.008087,413.212860 355.962982,412.787628 
	C398.477783,408.216675 427.928925,375.266937 427.972717,332.366455 
	C427.983795,321.533691 427.975220,310.700928 427.974854,299.868164 
	C427.973694,264.203369 428.026245,228.538437 427.944794,192.873825 
	C427.911316,178.218399 416.926422,166.157532 403.047485,166.093750 
	C367.384583,165.929794 331.720093,166.122314 295.584900,166.116150 
	C287.041504,165.975128 278.969421,165.890289 270.977112,165.469177 
	C275.092590,160.392807 278.350037,154.460648 283.349426,151.196548 
	C290.553741,146.492874 299.023682,143.727570 307.420197,140.070343 
	C312.940857,139.951660 317.994781,139.897659 323.511383,139.898697 
	C333.283997,139.916367 342.593964,139.879013 351.903931,139.841675 
z"
      />
      <path
        fill="#FDD1A4"
        opacity="1.000000"
        stroke="none"
        d="
M136.353745,420.290375 
	C125.586517,420.295349 115.233971,420.215973 104.412094,420.067078 
	C101.406303,415.488617 98.869850,410.979614 96.520004,405.941620 
	C98.686180,405.275055 100.665222,405.027863 102.645401,405.018372 
	C113.084602,404.968384 122.844505,402.494507 131.044266,395.885712 
	C143.762833,385.634888 149.488861,372.292816 147.183746,355.909393 
	C145.199142,341.803772 136.437607,332.784393 124.484695,326.223450 
	C118.313995,322.836426 112.210289,319.327362 106.076317,315.873444 
	C117.318893,314.990234 128.277405,314.622742 139.199387,315.096710 
	C149.496552,315.543518 158.687775,313.685455 166.412292,306.058868 
	C167.932632,307.000244 169.142838,307.851318 170.298431,308.771027 
	C177.119202,314.199463 188.531189,314.631775 193.622269,309.491333 
	C193.001846,308.759064 192.454697,307.908569 191.719208,307.277588 
	C185.719025,302.129822 183.761475,295.669830 183.862106,287.792358 
	C184.186798,262.372772 184.198608,236.943634 183.866684,211.524231 
	C183.749435,202.546021 185.192215,194.705170 192.574600,188.821701 
	C193.276047,188.262680 193.528793,187.140640 193.989838,186.279984 
	C196.047089,187.763947 198.104340,189.247910 200.467010,191.383301 
	C200.796295,238.766190 200.674057,285.498566 200.970612,332.228302 
	C201.028122,341.289856 202.098801,350.459259 203.796478,359.370422 
	C206.645279,374.323944 214.183411,387.288971 222.870575,399.598511 
	C216.645309,405.339752 210.578278,411.196045 208.174057,420.062653 
	C195.151016,420.516052 182.174301,420.776215 169.197968,420.758423 
	C158.387894,420.743622 147.578247,420.402588 136.353745,420.290375 
z"
      />
      <path
        fill="#848484"
        opacity="1.000000"
        stroke="none"
        d="
M223.103943,399.773407 
	C214.183411,387.288971 206.645279,374.323944 203.796478,359.370422 
	C202.098801,350.459259 201.028122,341.289856 200.970612,332.228302 
	C200.674057,285.498566 200.796295,238.766190 200.716003,191.580353 
	C201.525482,171.900116 210.173325,157.176041 226.509842,147.102158 
	C234.142685,142.395355 242.582718,140.021271 251.607529,140.005096 
	C268.405701,139.974991 285.203949,139.968857 302.866455,139.955597 
	C304.805023,140.017319 305.879242,140.076187 306.953491,140.135040 
	C299.023682,143.727570 290.553741,146.492874 283.349426,151.196548 
	C278.350037,154.460648 275.092590,160.392807 270.520325,165.526184 
	C264.988403,165.947159 259.950317,166.408173 255.005600,165.921143 
	C240.270065,164.469772 226.410141,177.002975 226.652008,194.149170 
	C227.307312,240.603607 226.818848,287.073517 226.948563,333.536865 
	C226.965607,339.645538 227.228806,345.916351 228.593719,351.831573 
	C236.516678,386.168091 263.130737,408.899780 299.890900,413.215973 
	C305.512085,417.435974 310.835602,422.147461 316.818146,425.770874 
	C328.301147,432.725586 341.144592,436.107605 354.578247,438.612366 
	C335.602478,438.839447 316.259308,439.909668 297.073456,438.626862 
	C268.699890,436.729706 244.859360,424.458313 226.078186,402.832855 
	C225.209335,401.832458 224.252960,400.908081 223.103943,399.773407 
z"
      />
      <path
        fill="#F7F6F6"
        opacity="1.000000"
        stroke="none"
        d="
M351.915680,139.385361 
	C342.593964,139.879013 333.283997,139.916367 323.497375,139.442078 
	C323.027222,108.305199 323.033752,77.679939 323.040283,47.054688 
	C323.040283,47.054688 323.010162,47.004784 322.991608,46.981918 
	C332.277771,46.983402 341.582458,47.007748 351.335693,47.049843 
	C351.831940,77.688080 351.879700,108.308571 351.915680,139.385361 
z"
      />
      <path
        fill="#E2E2E2"
        opacity="1.000000"
        stroke="none"
        d="
M322.568420,47.037613 
	C323.033752,77.679939 323.027222,108.305199 323.034729,139.387054 
	C317.994781,139.897659 312.940857,139.951660 307.420197,140.070343 
	C305.879242,140.076187 304.805023,140.017319 303.327820,139.904602 
	C302.940155,109.209778 302.955444,78.568825 303.008606,47.459274 
	C309.396545,47.000637 315.746552,47.010586 322.568420,47.037613 
z"
      />
      <path
        fill="#FFE5C2"
        opacity="1.000000"
        stroke="none"
        d="
M166.076920,306.000244 
	C158.687775,313.685455 149.496552,315.543518 139.199387,315.096710 
	C128.277405,314.622742 117.318893,314.990234 106.076317,315.873444 
	C112.210289,319.327362 118.313995,322.836426 124.484695,326.223450 
	C136.437607,332.784393 145.199142,341.803772 147.183746,355.909393 
	C149.488861,372.292816 143.762833,385.634888 131.044266,395.885712 
	C122.844505,402.494507 113.084602,404.968384 102.645401,405.018372 
	C100.665222,405.027863 98.686180,405.275055 96.366760,405.636230 
	C86.278091,395.903107 76.155205,386.277344 66.933052,375.854584 
	C62.079979,370.369720 59.919849,363.155396 59.933975,355.527283 
	C59.973362,334.258148 59.987461,312.988403 59.852089,291.719879 
	C59.805229,284.357574 63.060123,278.668030 68.440338,273.223114 
	C75.669090,268.267029 83.329376,268.865967 91.352509,268.878723 
	C99.593330,268.897369 107.354881,268.868011 115.573212,268.863892 
	C122.978020,268.871338 129.926056,268.853546 137.353302,268.882874 
	C145.595566,268.900452 153.358643,268.870880 161.514633,268.859558 
	C166.997589,268.879120 169.821106,271.807343 169.860001,276.503998 
	C169.942474,286.469879 171.639862,296.730316 166.076920,306.000244 
z"
      />
      <path
        fill="#FEBD88"
        opacity="1.000000"
        stroke="none"
        d="
M90.873245,268.830719 
	C83.329376,268.865967 75.669090,268.267029 68.624352,272.926575 
	C68.243835,228.100540 68.195724,183.201035 68.176849,138.301529 
	C68.176369,137.154648 68.443794,135.996811 68.675522,134.862305 
	C71.871414,119.216110 86.663795,111.473694 101.476517,118.235580 
	C98.737930,122.833130 95.851730,126.954460 92.582855,131.100739 
	C86.415695,131.963760 83.354240,134.559174 82.934563,138.980774 
	C82.451012,144.075287 84.686394,146.866165 91.019478,149.545013 
	C90.967148,189.615921 90.920197,229.223328 90.873245,268.830719 
z"
      />
      <path
        fill="#FDD2A4"
        opacity="1.000000"
        stroke="none"
        d="
M91.352509,268.878723 
	C90.920197,229.223328 90.967148,189.615921 91.386383,149.473206 
	C96.260986,148.157913 99.502899,145.986008 100.076462,141.096481 
	C100.713295,135.667648 97.489105,132.889191 92.965538,131.075790 
	C95.851730,126.954460 98.737930,122.833130 101.790970,118.437302 
	C111.295464,123.033707 114.692642,131.227539 114.740707,141.220215 
	C114.819862,157.675522 114.829063,174.131165 114.946465,191.500854 
	C115.055595,217.889603 115.086014,243.364151 115.116440,268.838684 
	C107.354881,268.868011 99.593330,268.897369 91.352509,268.878723 
z"
      />
      <path
        fill="#FFBD87"
        opacity="1.000000"
        stroke="none"
        d="
M166.412292,306.058868 
	C171.639862,296.730316 169.942474,286.469879 169.860001,276.503998 
	C169.821106,271.807343 166.997589,268.879120 161.475891,268.408936 
	C161.024002,248.369736 160.962418,228.799240 161.025848,209.229156 
	C161.035950,206.110596 161.607391,202.993866 161.919312,199.876282 
	C166.131836,186.509201 179.601151,180.515030 193.641418,186.097107 
	C193.528793,187.140640 193.276047,188.262680 192.574600,188.821701 
	C185.192215,194.705170 183.749435,202.546021 183.866684,211.524231 
	C184.198608,236.943634 184.186798,262.372772 183.862106,287.792358 
	C183.761475,295.669830 185.719025,302.129822 191.719208,307.277588 
	C192.454697,307.908569 193.001846,308.759064 193.622269,309.491333 
	C188.531189,314.631775 177.119202,314.199463 170.298431,308.771027 
	C169.142838,307.851318 167.932632,307.000244 166.412292,306.058868 
z"
      />
      <path
        fill="#FFBD86"
        opacity="1.000000"
        stroke="none"
        d="
M115.573212,268.863892 
	C115.086014,243.364151 115.055595,217.889603 114.982635,192.006104 
	C119.567352,181.127457 126.912460,174.473923 139.024002,175.110474 
	C141.918549,175.262619 144.753891,176.541428 147.707977,177.860931 
	C138.621750,185.832657 136.767960,195.836548 136.923172,206.982864 
	C137.210205,227.596268 136.922424,248.217682 136.874084,268.835754 
	C129.926056,268.853546 122.978020,268.871338 115.573212,268.863892 
z"
      />
      <path
        fill="#FDD2A4"
        opacity="1.000000"
        stroke="none"
        d="
M137.353302,268.882874 
	C136.922424,248.217682 137.210205,227.596268 136.923172,206.982864 
	C136.767960,195.836548 138.621750,185.832657 147.972961,178.114532 
	C157.254257,182.144882 159.989288,190.360703 161.616547,199.655807 
	C161.607391,202.993866 161.035950,206.110596 161.025848,209.229156 
	C160.962418,228.799240 161.024002,248.369736 161.082977,268.390717 
	C153.358643,268.870880 145.595566,268.900452 137.353302,268.882874 
z"
      />
      <path
        fill="#CECECE"
        opacity="1.000000"
        stroke="none"
        d="
M296.056213,166.172363 
	C331.720093,166.122314 367.384583,165.929794 403.047485,166.093750 
	C416.926422,166.157532 427.911316,178.218399 427.944794,192.873825 
	C428.026245,228.538437 427.973694,264.203369 427.974854,299.868164 
	C427.975220,310.700928 427.983795,321.533691 427.972717,332.366455 
	C427.928925,375.266937 398.477783,408.216675 355.962982,412.787628 
	C352.008087,413.212860 347.971283,412.876343 343.371094,412.541931 
	C337.648560,410.450714 332.387115,409.037903 327.433075,406.909485 
	C313.158295,400.776611 300.897308,392.092804 293.287842,378.030121 
	C308.421356,367.547302 307.225128,348.545746 290.924866,340.486938 
	C286.749542,339.777435 282.883575,339.298706 279.013214,338.363770 
	C278.995514,332.628693 278.982208,327.349731 279.438141,322.039978 
	C284.952484,321.933777 289.997589,321.858398 295.507202,321.817078 
	C315.612823,321.863495 335.253937,321.879059 354.895050,321.887207 
	C367.545410,321.892426 380.196411,321.963623 392.845978,321.862274 
	C403.300751,321.778534 407.004059,317.994690 407.005951,307.725708 
	C407.011902,275.101044 407.023956,242.476395 406.994934,209.851776 
	C406.988159,202.211426 402.902130,197.998566 395.370178,197.991852 
	C362.246704,197.962372 329.123108,198.003967 295.532593,197.994720 
	C289.686005,197.892426 284.306396,197.815216 278.942688,197.296112 
	C278.259827,186.727966 280.954529,177.948715 289.150940,171.396317 
	C291.404541,169.594757 293.751892,167.910477 296.056213,166.172363 
M383.557404,378.631134 
	C391.417145,374.618011 395.716034,368.100708 395.506866,359.379578 
	C395.287994,350.255280 390.505249,343.749359 381.170105,339.924286 
	C370.867828,336.974976 360.896118,339.553925 355.288727,346.617889 
	C349.323029,354.133270 349.205414,365.031464 355.009521,372.496094 
	C361.368713,380.674622 372.724304,383.237091 383.557404,378.631134 
z"
      />
      <path
        fill="#BFBFBF"
        opacity="1.000000"
        stroke="none"
        d="
M293.005402,378.233948 
	C300.897308,392.092804 313.158295,400.776611 327.433075,406.909485 
	C332.387115,409.037903 337.648560,410.450714 342.908020,412.519440 
	C328.977936,412.925171 314.909485,413.001801 300.365967,413.147217 
	C263.130737,408.899780 236.516678,386.168091 228.593719,351.831573 
	C227.228806,345.916351 226.965607,339.645538 226.948563,333.536865 
	C226.818848,287.073517 227.307312,240.603607 226.652008,194.149170 
	C226.410141,177.002975 240.270065,164.469772 255.005600,165.921143 
	C259.950317,166.408173 264.988403,165.947159 270.440552,165.862473 
	C278.969421,165.890289 287.041504,165.975128 295.584900,166.116150 
	C293.751892,167.910477 291.404541,169.594757 289.150940,171.396317 
	C280.954529,177.948715 278.259827,186.727966 278.483124,197.354462 
	C272.511749,197.874313 267.015808,197.895599 261.519897,197.913177 
	C250.918274,197.947083 247.126022,201.683044 247.107040,212.259003 
	C247.076538,229.247391 247.098053,246.235886 247.098557,263.224335 
	C247.099014,278.214142 247.011230,293.204681 247.134659,308.193481 
	C247.212372,317.627838 251.655258,321.898193 260.984009,321.965576 
	C266.978912,322.008881 272.973938,322.036285 278.968933,322.070801 
	C278.982208,327.349731 278.995514,332.628693 278.613525,338.429199 
	C267.257141,340.309601 259.266113,348.695007 259.031769,359.084137 
	C258.666656,375.270569 274.801971,385.302338 291.235260,378.898834 
	C292.005890,378.651367 292.505646,378.442657 293.005402,378.233948 
z"
      />
      <path
        fill="#FD4A57"
        opacity="1.000000"
        stroke="none"
        d="
M92.582855,131.100739 
	C97.489105,132.889191 100.713295,135.667648 100.076462,141.096481 
	C99.502899,145.986008 96.260986,148.157913 91.391754,149.009674 
	C84.686394,146.866165 82.451012,144.075287 82.934563,138.980774 
	C83.354240,134.559174 86.415695,131.963760 92.582855,131.100739 
z"
      />
      <path
        fill="#FDFEFF"
        opacity="1.000000"
        stroke="none"
        d="
M295.999573,198.019791 
	C329.123108,198.003967 362.246704,197.962372 395.370178,197.991852 
	C402.902130,197.998566 406.988159,202.211426 406.994934,209.851776 
	C407.023956,242.476395 407.011902,275.101044 407.005951,307.725708 
	C407.004059,317.994690 403.300751,321.778534 392.845978,321.862274 
	C380.196411,321.963623 367.545410,321.892426 354.895050,321.887207 
	C335.253937,321.879059 315.612823,321.863495 295.428101,321.498230 
	C289.400146,316.066467 288.394989,309.611420 288.962006,302.559662 
	C289.253967,298.928772 289.100555,295.262054 289.490356,291.806763 
	C291.289703,292.724884 292.730835,294.030365 294.207214,294.071442 
	C301.173126,294.265411 308.173492,294.464172 315.113129,293.974762 
	C320.780914,293.575012 325.038879,288.718109 325.114746,282.727264 
	C325.316620,266.781128 325.269958,250.829819 325.120941,234.882446 
	C325.072540,229.707535 322.156738,225.369507 317.327667,224.452621 
	C308.267975,222.732468 298.928070,221.480606 289.831909,225.718109 
	C289.377716,221.163956 289.338684,216.919678 288.970428,212.704147 
	C288.416290,206.360855 290.834656,201.563660 295.999573,198.019791 
M367.257050,229.549683 
	C364.161713,227.551926 361.264313,224.382065 357.926788,223.818420 
	C352.264099,222.862091 346.292969,223.172638 340.508514,223.646637 
	C334.093262,224.172333 329.636993,228.642365 329.504120,235.190201 
	C329.183014,251.012543 329.149902,266.852386 329.535858,282.672089 
	C329.709595,289.793152 334.794739,293.998077 342.076477,294.278442 
	C346.403229,294.445007 350.742157,294.394592 355.073547,294.321442 
	C364.438873,294.163269 369.008820,289.485992 369.037170,279.983551 
	C369.079437,265.818298 369.110779,251.652267 368.986938,237.487961 
	C368.965668,235.054779 368.149231,232.628525 367.257050,229.549683 
M394.996338,299.514526 
	C389.313171,295.283386 382.492615,297.043030 382.257294,303.171143 
	C382.158844,305.734833 384.036713,309.429810 386.160767,310.707550 
	C388.042419,311.839417 392.073547,311.180847 394.151428,309.800659 
	C397.572754,307.528137 397.715454,303.734558 394.996338,299.514526 
z"
      />
      <path
        fill="#E9F5FE"
        opacity="1.000000"
        stroke="none"
        d="
M289.148376,291.611542 
	C289.100555,295.262054 289.253967,298.928772 288.962006,302.559662 
	C288.394989,309.611420 289.400146,316.066467 294.963623,321.464172 
	C289.997589,321.858398 284.952484,321.933777 279.438110,322.039978 
	C272.973938,322.036285 266.978912,322.008881 260.984009,321.965576 
	C251.655258,321.898193 247.212372,317.627838 247.134659,308.193481 
	C247.011230,293.204681 247.099014,278.214142 247.098557,263.224335 
	C247.098053,246.235886 247.076538,229.247391 247.107040,212.259003 
	C247.126022,201.683044 250.918274,197.947083 261.519897,197.913177 
	C267.015808,197.895599 272.511749,197.874313 278.467224,197.796356 
	C284.306396,197.815216 289.686005,197.892426 295.532593,197.994720 
	C290.834656,201.563660 288.416290,206.360855 288.970428,212.704147 
	C289.338684,216.919678 289.377716,221.163956 289.667999,226.029205 
	C288.422913,230.076813 286.381439,233.394867 285.893097,236.927628 
	C285.220337,241.794174 285.877411,246.829330 285.674377,251.780350 
	C285.479492,256.532745 286.864563,260.536224 289.966736,264.166870 
	C289.853729,270.027649 290.033569,275.572998 290.053345,281.454651 
	C287.134521,284.874054 287.661652,288.206390 289.148376,291.611542 
M257.967316,282.704407 
	C258.619965,288.737854 262.486938,292.058990 267.282532,290.593994 
	C270.845825,289.505402 272.885193,286.953857 272.816650,283.260010 
	C272.748169,279.570679 270.654388,276.875793 267.021118,276.166473 
	C262.447784,275.273621 259.393585,277.423096 257.967316,282.704407 
M267.001740,311.715851 
	C271.124908,310.306641 273.853394,307.330017 272.505493,303.159760 
	C271.711243,300.702698 268.801025,298.101349 266.268524,297.226776 
	C262.383911,295.885223 258.385071,299.485504 257.981659,303.614136 
	C257.523895,308.298920 260.443481,311.212097 267.001740,311.715851 
z"
      />
      <path
        fill="#FD4856"
        opacity="1.000000"
        stroke="none"
        d="
M290.964386,378.937561 
	C274.801971,385.302338 258.666656,375.270569 259.031769,359.084137 
	C259.266113,348.695007 267.257141,340.309601 278.617920,338.885345 
	C282.883575,339.298706 286.749542,339.777435 290.698151,340.765167 
	C279.125763,350.497864 276.816772,359.975037 283.874695,371.474670 
	C285.630341,374.335205 288.569305,376.469482 290.964386,378.937561 
z"
      />
      <path
        fill="#0894FC"
        opacity="1.000000"
        stroke="none"
        d="
M382.893555,378.788452 
	C372.724304,383.237091 361.368713,380.674622 355.009521,372.496094 
	C349.205414,365.031464 349.323029,354.133270 355.288727,346.617889 
	C360.896118,339.553925 370.867828,336.974976 381.508850,340.384827 
	C380.930634,341.755402 380.081390,342.748291 379.085815,343.562134 
	C372.285736,349.120697 368.942108,358.547211 372.596252,366.337036 
	C374.811127,371.058655 379.381439,374.675354 382.893555,378.788452 
z"
      />
      <path
        fill="#27A6FE"
        opacity="1.000000"
        stroke="none"
        d="
M383.225464,378.709778 
	C379.381439,374.675354 374.811127,371.058655 372.596252,366.337036 
	C368.942108,358.547211 372.285736,349.120697 379.085815,343.562134 
	C380.081390,342.748291 380.930634,341.755402 381.906128,340.520142 
	C390.505249,343.749359 395.287994,350.255280 395.506866,359.379578 
	C395.716034,368.100708 391.417145,374.618011 383.225464,378.709778 
z"
      />
      <path
        fill="#FD6570"
        opacity="1.000000"
        stroke="none"
        d="
M291.235260,378.898834 
	C288.569305,376.469482 285.630341,374.335205 283.874695,371.474670 
	C276.816772,359.975037 279.125763,350.497864 291.007507,340.995911 
	C307.225128,348.545746 308.421356,367.547302 293.287842,378.030121 
	C292.505646,378.442657 292.005890,378.651367 291.235260,378.898834 
z"
      />
      
      <path
        fill="#2EA9FE"
        opacity="1.000000"
        stroke="none"
        d="
M395.237854,299.819550 
	C397.715454,303.734558 397.572754,307.528137 394.151428,309.800659 
	C392.073547,311.180847 388.042419,311.839417 386.160767,310.707550 
	C384.036713,309.429810 382.158844,305.734833 382.257294,303.171143 
	C382.492615,297.043030 389.313171,295.283386 395.237854,299.819550 
z"
      />
      <path
        fill="#FC4E5B"
        opacity="1.000000"
        stroke="none"
        d="
M258.005737,282.306702 
	C259.393585,277.423096 262.447784,275.273621 267.021118,276.166473 
	C270.654388,276.875793 272.748169,279.570679 272.816650,283.260010 
	C272.885193,286.953857 270.845825,289.505402 267.282532,290.593994 
	C262.486938,292.058990 258.619965,288.737854 258.005737,282.306702 
z"
      />
      <path
        fill="#2DA9FE"
        opacity="1.000000"
        stroke="none"
        d="
M266.623718,311.787964 
	C260.443481,311.212097 257.523895,308.298920 257.981659,303.614136 
	C258.385071,299.485504 262.383911,295.885223 266.268524,297.226776 
	C268.801025,298.101349 271.711243,300.702698 272.505493,303.159760 
	C273.853394,307.330017 271.124908,310.306641 266.623718,311.787964 
z"
      />
      <g>
        <text
          dz="M260.88"
          transform="translate(270 280)"
          style={{
            fill: "black",
            fontSize: "64px",
            fontFamily: "Nunito-SemiBold, Nunito",
            fontWeight: "600",
          }}
        >
          <tspan>{props.value}</tspan>
        </text>
      </g>
    </svg>
  );
};

export default BloodGlucoseDeviceIcon;
