import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { dbstore } from "../dbStore";

export default class Store {
  selectedVital = "bloodPressure";
  comp1 = {};
  comp2 = {};
  comp3 = {};
  comp4 = {};
  comp5 = {};
  constructor() {
    makeAutoObservable(this, {
      setVital: action,
    });
  }

  setVital(vital) {
    console.log("set vital::::;", vital)
    dbstore.vitalRecords = [];
    this.selectedVital = vital;
  }
}

const vitalsActionStore = new Store();
export { vitalsActionStore };
