import { makeAutoObservable, action } from "mobx";
import { dbstore } from "../dbStore";
import { homeActionStore } from "../actions/homeActionStore";
import moment from "moment";
import _ from "lodash";

export default class Store {
  comp1 = {};
  comp2 = {};
  comp3 = {};
  comp4 = {};
  comp5 = {};
  comp6 = {};
  nextDoseDateTime = null;
  nextDoseTimeInSeconds = null;
  timeRemainingInSeconds = null;
  nextDoseInfoLoaded = false;

  constructor() {
    makeAutoObservable(this, {
      nextDoseTime: action,
      setTimeRemainingInSecs: action,
    });
  }

  get comp1Data() {
    var pcfrom = moment().subtract(3, "days").format("x") + "";
    var pcto = moment().add(3, "days").format("x") + "";
    dbstore.getPcArr(pcfrom, pcto);
    console.log("***************", dbstore.pillcompliancePercentage);
    let val = dbstore.pillcompliancePercentage;
    Object.assign(this.comp1, { percentage: val });

    return this.comp1;
  }
  get comp2Data() {
    var period = 30;
    var datalist = [
      {
        date: "1622678400000",
        docid: "60ab4e837c0d2b4b74a9c979",
        missed: 3,
        patid: "60ab4e7a574c694b60994106",
        phid: "60aaaeb9cb311c3955146896",
        rxid: "60ac9bf4d06b017e5df767c3",
        taken: 2,
        total: 9,
        unanswered: 4,
      },
    ];
    if (dbstore.pcarrDaily) {
      period = homeActionStore.comp2.period;
      datalist = [];
      for (var i = 0; i < period; i++) {
        if (i < dbstore.pcarrDaily.length) {
          datalist.push(dbstore.pcarrDaily[i]);
        }
      }
      // datalist = dbstore?.pcarrDaily;
      // datalist = []
      // console.log(datalist)
    }

    Object.assign(this.comp2, { graphData: datalist, timePeriod: period });
    return this.comp2;
  }
  get comp3Data() {
    var now = moment().format("x");
    var dateFound = null;

    if (dbstore.pcarr) {
      // console.log('getting next', dbstore.pcarr.length)

      for (let i = 0; i < dbstore.pcarr.length; i++) {
        const pc = dbstore.pcarr[i];
        var time = pc.time;
        console.log(time, "pc time");

        var [hr, min] = time.split(":");
        // console.log(hr, min);
        var pcDate = moment(parseInt(pc.date))
          .add(parseInt(hr), "hours")
          .add(parseInt(min), "minutes")
          .format("x");
        if (pcDate > now) {
          dateFound = pcDate;
          console.log(dateFound);
          break;
        }
      }
    }
    Object.assign(this.comp3, { time: dateFound });
    return this.comp3;
  }
  get comp4Data() {
    var now = moment().format("x");
    var dateFound = moment().add(3, "years").format("x");
    var temp = moment().add(3, "years").format("x");
    if (dbstore.rxarr) {
      for (let i = 0; i < dbstore.rxarr.length; i++) {
        const refillarr = dbstore.rxarr[i].refills;
        for (let j = 0; j < refillarr.length; j++) {
          const rf = refillarr[j];
          var rfDate = parseInt(rf.deliveryDate);
          // console.log(rfDate);
          if (rfDate > now && rfDate < dateFound) {
            dateFound = rfDate;
          }
        }
      }
    }
    if (dateFound == temp) {
      dateFound = null;
    }
    Object.assign(this.comp4, { time: dateFound });
    return this.comp4;
  }
  get comp5Data() {
    this.comp5 = {
      hipaa: {
        signed: true,
      },
      prescriptionTransfer: {
        signed: false,
      },
      autoRefill: {
        signed: true,
      },
      autoPay: {
        signed: false,
      },
      autoDelivery: {
        signed: false,
      },
      digitalSignature: {
        signed: false,
      },
      pillPackEnrollment: {
        signed: true,
      },
    };

    if (!dbstore.patientDetails) {
      return;
    }

    var obj = dbstore.patientDetails.compliance;
    Object.assign(this.comp5, obj);
    return this.comp5;
  }
  get comp6Data() {
    var data = {
      pillsList: [],
      time: "a",
      date: "a",
      visible: homeActionStore.comp1.visible,
    };
    if (dbstore.pcarr && dbstore.rxarr) {
      for (let i = 0; i < dbstore.pcarr.length; i++) {
        if (!dbstore.pcarr[i]["name"]) {
          var rx = _.find(dbstore.rxarr, {
            _id: dbstore.pcarr[i].rxid,
          });
          // console.log(rx);
          dbstore.pcarr[i]["name"] = rx.name;
        }
      }
      var dates = _.groupBy(dbstore.pcarr, "date");

      for (var date in dates) {
        var pills = _.groupBy(dates[date], "time");

        for (var key in pills) {
          // var obj = {
          //   time: key,
          //   date: date,
          //   totalTablets: pills[key].length,
          // };
          var statusGroup = _.groupBy(pills[key], "status");

          var status = false;
          status = statusGroup["taken"]?.length > 0 ? true : status;
          status =
            statusGroup[null]?.length == pills[key].length ? null : status;
          // obj["status"] = status;
          // console.log('found', date, key)
          if (status == null) {
            console.log("found", date, data.date, date < data.date, key);
            if (date < data.date) {
              if (key < data.time) {
                console.log("found", date, key);
                data.date = date;
                data.time = key;
                var pillsarr = [];
                pills[key].forEach((p) => {
                  var temp = {
                    name: p.name,
                    status: p.status,
                    rxid: p.rxid,
                    date: p.date,
                    time: p.time,
                    selected: true,
                  };
                  pillsarr.push(temp);
                });
                data.pillsList = pillsarr;
              }
            }
          }
        }
      }
    }
    console.log(data);
    Object.assign(this.comp6, data);
    return this.comp6;
  }

  nextDoseTime() {
    let upcomingDoseDateTime = null;
    this.nextDoseInfoLoaded = false;

    console.log(
      "dbstore?.currentPillBox?.schedule:::::",
      dbstore?.currentPillBox?.schedule
    );
    if (dbstore?.currentPillBox?.schedule) {
      console.log(
        "dbstore?.currentPillBox?.schedule if:::::",
        dbstore?.currentPillBox?.schedule
      );
      let pillBoxSchedule = dbstore.currentPillBox.schedule;
      let pillBoxObjectKeys = Object.keys(pillBoxSchedule);

      for (let i = 0; i < pillBoxObjectKeys.length; i++) {
        let dateScheduled = pillBoxObjectKeys[i];
        let pillsScheduled = pillBoxSchedule[dateScheduled];
        let pillsScheduledObjectKeys = Object.keys(pillsScheduled);

        for (let j = 0; j < pillsScheduledObjectKeys.length; j++) {
          let timeScheduled = pillsScheduledObjectKeys[j];

          let pillAdministeredDateTime =
            dateScheduled + " " + ("0" + timeScheduled).slice(-5);
          if (moment(pillAdministeredDateTime).isAfter(moment())) {
            upcomingDoseDateTime = moment(pillAdministeredDateTime);
            console.log(
              "nextDoseTime if entered::::::::::::::",
              upcomingDoseDateTime,
              timeScheduled
            );
            break;
          }
        }
        if (upcomingDoseDateTime) {
          break;
        }
      }
      this.nextDoseDateTime = upcomingDoseDateTime;
      this.nextDoseTimeInSeconds = upcomingDoseDateTime
        ? moment(upcomingDoseDateTime).diff(moment(), "seconds")
        : null;
      this.timeRemainingInSeconds = this.nextDoseTimeInSeconds;
      this.nextDoseInfoLoaded = true;
      console.log(
        "next dose time in ms2::::::::::::::",
        upcomingDoseDateTime,
        this.nextDoseDateTime,
        this.nextDoseTimeInSeconds,
        this.timeRemainingInSeconds
      );
      return this.nextDoseTimeInSeconds;
    }
  }

  setTimeRemainingInSecs(timeInSecs) {
    this.nextDoseInfoLoaded = false;
    let time = new Date();
    time.setSeconds(time.getSeconds() + 10);
    this.timeRemainingInSeconds = this.nextDoseDateTime;
    this.nextDoseInfoLoaded = true;

    console.log(
      "timeRemainingInSeconds::::::::::::::::::::",
      moment(this.nextDoseDateTime).diff(moment(), "seconds")
    );
    // this.timeRemainingInSeconds = moment(this.nextDoseDateTime).diff(
    //   moment(),
    //   'seconds',
    // );
  }

  // setTimeRemainingInSecs(timeInSecs) {
  //   this.timeRemainingInSeconds = moment(this.nextDoseDateTime).diff(
  //     moment(),
  //     'seconds',
  //   );
  // }

  // startBackgroundTimer(timerInterval) {
  //   let a = BackgroundTimer.runBackgroundTimer(() => {
  //     console.log(
  //       'timeRemainingInSeconds startBackgroundTimer:::::::::::::::',
  //       this.timeRemainingInSeconds,
  //     );
  //     this.setTimeRemainingInSecs(
  //       moment(this.nextDoseDateTime).diff(moment(), 'seconds'),
  //     );
  //   }, timerInterval);
  // }

  // stopBackgroundTimer() {
  //   BackgroundTimer.stopBackgroundTimer(); //after this call all code on background stop run.
  //   console.log(
  //     'timeRemainingInSeconds stopBackgroundTimer:::::::::::::::',
  //     this.timeRemainingInSeconds,
  //   );
  // }

  *resetHomeStore() {
    yield (this.comp1 = {});
    yield (this.comp2 = {});
    yield (this.comp3 = {
      timeInMilliseconds: null,
      time: null,
      units: null,
    });
    yield (this.comp4 = {});
    yield (this.comp5 = {});
    yield (this.comp6 = {});
    yield (this.pdfObj = {});
    yield (this.nextDoseDateTime = null);
    yield (this.nextDoseTimeInSeconds = null);
    yield (this.timeRemainingInSeconds = null);
  }
}

const homeStore = new Store();
export { homeStore };
