import _ from "lodash";
import { makeAutoObservable, action, toJS } from "mobx";
import { dbstore } from "../dbStore";
import { prescriptionsActionStore } from "../actions/prescriptionsActionStore";
import { leadingZero } from "../../../utils/Common.fns";
const fetch = require("node-fetch");

export default class Store {
  comp1 = [];
  comp2 = { pillCard: [], pillsList: [], selectedTime: null };
  comp3 = {};
  pcNotifications = { arr: [] };
  comp3 = {};
  selectedPillPouchImages = {};
  loadingPillPouchImages = true;
  showDoseAdherenceModal = false;

  constructor() {
    makeAutoObservable(this, {
      setShowDoseAdherenceModal: action,
    });
  }

  get comp1Data() {
    if (dbstore.doctorDetails) {
      //Object.assign(this.comp1, dbstore.doctorDetails);
      this.comp1 = dbstore.doctorDetails;
      console.log(toJS(this.comp1));
    }
    return this.comp1;
  }
  get comp2Data() {
    var date = prescriptionsActionStore.comp2.date;
    var arr = [];
    if (dbstore.pcarr && dbstore.rxarr) {
      for (let i = 0; i < dbstore.pcarr.length; i++) {
        console.log(dbstore.pcarr[i]);
        if (!dbstore.pcarr[i]["name"]) {
          var rx = _.find(dbstore.rxarr, {
            _id: dbstore.pcarr[i].rxid,
          });
          console.log(rx);
          dbstore.pcarr[i]["name"] = rx.name;
        }
      }
      var dates = _.groupBy(dbstore.pcarr, "date");

      var pills = _.groupBy(dates[date], "time");
      for (var key in pills) {
        var obj = {
          time: key,
          date: date,
          totalTablets: pills[key].length,
        };
        var statusGroup = _.groupBy(pills[key], "status");
        console.log(statusGroup);
        var status = false;
        status = statusGroup["taken"]?.length > 0 ? true : status;
        status = statusGroup[null]?.length == pills[key].length ? null : status;
        obj["status"] = status;
        arr.push(obj);
      }
      var pillsarr = [];
      if (
        prescriptionsActionStore.comp2.selectedTime &&
        Object.keys(pills).length > 0 &&
        prescriptionsActionStore.comp2.selectedTime?.length > 0
      ) {
        pills[prescriptionsActionStore.comp2.selectedTime].forEach((p) => {
          var temp = {
            name: p.name,
            status: p.status,
            rxid: p.rxid,
            date: p.date,
            time: p.time,
            pouchGuid: p.pouchGuid,
            selected:
              _.filter(pills[prescriptionsActionStore.comp2.selectedTime], {
                status: null,
              }).length ==
              pills[prescriptionsActionStore.comp2.selectedTime].length
                ? true
                : p.status == "taken",
          };
          pillsarr.push(temp);
        });
      } else {
        pillsarr = [];
      }
      var o = {
        pillCard: arr,
        pillsList: pillsarr,
        selectedTime: prescriptionsActionStore.comp2.selectedTime,
        showSelect: prescriptionsActionStore.comp2.showSelect,
      };
      console.log(o);
      Object.assign(this.comp2, o);
    }
    return this.comp2;
  }
  get comp3Data() {
    return this.comp3;
  }

  async setSelectedDayPillBox(date) {
    dbstore.loading = true;
    console.log(
      "dbstore.pillboxDetails.schedule:::::::",
      dbstore.pillboxDetails.schedule
    );
    if (dbstore.pillboxDetails.schedule) {
      let mod_date =
        new Date(date).getUTCFullYear() +
        "-" +
        leadingZero(new Date(date).getUTCMonth() + 1, 2) +
        "-" +
        leadingZero(new Date(date).getUTCDate(), 2);
      let selectedDayPillBox = dbstore.pillboxDetails.schedule[mod_date];
      var pillCardArray = [];
      var pouchList = [];

      for (const key in selectedDayPillBox) {
        let pouchesForSelectedDate = dbstore.pillboxDetails.pouches.filter(
          (item) => item.pouchId === selectedDayPillBox[key]["pouchId"]
        );
        pouchesForSelectedDate = pouchesForSelectedDate[0];
        let obj = {};
        obj.date = mod_date;
        obj.imagePath = dbstore.pillboxDetails.rxSafeJobDataImportPath
          .split("/")
          .slice(0, 3);
        obj.imagePath =
          obj.imagePath[0] +
          "/" +
          obj.imagePath[1] +
          "/" +
          obj.imagePath[2] +
          "/Images/";
        obj.pouchId = selectedDayPillBox[key]["pouchId"];
        obj.docId = dbstore.pillboxDetails.docId;
        obj.phId = dbstore.pillboxDetails.phId;
        obj.patId = dbstore.pillboxDetails.patId;
        obj.boxId = dbstore.pillboxDetails.boxId;
        obj.status = null;
        obj.time = key;
        obj.rxes = pouchesForSelectedDate.rxes;
        obj.totalTablets = pouchesForSelectedDate.rxes.length;
        pouchList.push(obj.pouchId);
        pillCardArray.push(obj);
      }
      var res = await fetch(
        `${dbstore.endpoint}/patients/${dbstore.pillboxDetails.patId}/pouchstatus/pouch`,
        {
          method: "post",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          cache: "no-cache",
          body: JSON.stringify({ pouches: pouchList }),
        }
      );
      let pouchArr = await res.json();
      pillCardArray.map((item, i) => {
        for (var j = 0; j < pouchArr.length; j++) {
          if (pouchArr[j].pouchid === item.pouchId) {
            item.status = pouchArr[j].status;
            break;
          }
        }
      });
      var o = {
        pillCard: pillCardArray,
        selectedPouch:
          pillCardArray[prescriptionsActionStore.comp2.selectedPouchIndex] ||
          {},
        pillsList:
          pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
            ?.rxes || [],
        selectedTime:
          pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
            ?.time,
        showSelect: prescriptionsActionStore.comp2?.showSelect,
      };
      Object.assign(this.comp2, o);
      prescriptionsActionStore.setComp2({
        date: date,
        selectedDayPillBox: selectedDayPillBox,
        pillCard: pillCardArray,
        selectedPouch:
          pillCardArray[prescriptionsActionStore.comp2.selectedPouchIndex] ||
          {},
        pillsList:
          pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
            ?.rxes || [],
        selectedTime:
          pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
            ?.time,
        showSelect: prescriptionsActionStore.comp2?.showSelect,
      });
    }
    dbstore.loading = false;
  }

  // async setSelectedDayPillBox(date) {
  //   dbstore.loading = true;
  //   if (dbstore.pillboxDetails.schedule) {
  //     let mod_date =
  //       new Date(date).getUTCFullYear() +
  //       "-" +
  //       leadingZero(new Date(date).getUTCMonth() + 1, 2) +
  //       "-" +
  //       leadingZero(new Date(date).getUTCDate(), 2);
  //     let selectedDayPillBox = dbstore.pillboxDetails.schedule[mod_date];
  //     var pillCardArray = [];
  //     var pouchList = [];

  //     for (const key in selectedDayPillBox) {
  //       let pouchesForSelectedDate = dbstore.pillboxDetails.pouches.filter(
  //         (item) => item.pouchId === selectedDayPillBox[key]["pouchId"]
  //       );
  //       pouchesForSelectedDate = pouchesForSelectedDate[0];
  //       let obj = {};
  //       obj.date = mod_date;
  //       obj.imagePath = dbstore.pillboxDetails.rxSafeJobDataImportPath
  //         .split("/")
  //         .slice(0, 3);
  //       obj.imagePath =
  //         obj.imagePath[0] +
  //         "/" +
  //         obj.imagePath[1] +
  //         "/" +
  //         obj.imagePath[2] +
  //         "/Images/";
  //       obj.pouchId = selectedDayPillBox[key]["pouchId"];
  //       obj.docId = dbstore.pillboxDetails.docId;
  //       obj.phId = dbstore.pillboxDetails.phId;
  //       obj.patId = dbstore.pillboxDetails.patId;
  //       obj.boxId = dbstore.pillboxDetails.boxId;
  //       obj.status = null;
  //       obj.time = key;
  //       obj.rxes = pouchesForSelectedDate.rxes;
  //       obj.totalTablets = pouchesForSelectedDate.rxes.length;
  //       pouchList.push(obj.pouchId);
  //       pillCardArray.push(obj);
  //     }
  //     var res = await fetch(
  //       `${dbstore.endpoint}/patients/${dbstore.pillboxDetails.patId}/pouchstatus/pouch`,
  //       {
  //         method: "post",
  //         credentials: "include",
  //         headers: { "Content-Type": "application/json" },
  //         cache: "no-cache",
  //         body: JSON.stringify({ pouches: pouchList }),
  //       }
  //     );
  //     let pouchArr = await res.json();
  //     pillCardArray.map((item, i) => {
  //       for (var j = 0; j < pouchArr.length; j++) {
  //         if (pouchArr[j].pouchid === item.pouchId) {
  //           item.status = pouchArr[j].status;
  //           break;
  //         }
  //       }
  //     });
  //     var o = {
  //       pillCard: pillCardArray,
  //       selectedPouch:
  //         pillCardArray[prescriptionsActionStore.comp2.selectedPouchIndex] ||
  //         {},
  //       pillsList:
  //         pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
  //           ?.rxes || [],
  //       selectedTime:
  //         pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
  //           ?.time,
  //       showSelect: prescriptionsActionStore.comp2?.showSelect,
  //     };
  //     Object.assign(this.comp2, o);
  //     prescriptionsActionStore.setComp2({
  //       date: date,
  //       selectedDayPillBox: selectedDayPillBox,
  //       pillCard: pillCardArray,
  //       selectedPouch:
  //         pillCardArray[prescriptionsActionStore.comp2.selectedPouchIndex] ||
  //         {},
  //       pillsList:
  //         pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
  //           ?.rxes || [],
  //       selectedTime:
  //         pillCardArray?.[prescriptionsActionStore.comp2.selectedPouchIndex]
  //           ?.time,
  //       showSelect: prescriptionsActionStore.comp2?.showSelect,
  //     });
  //   }
  //   dbstore.loading = false;
  // }

  *getRxSafeImageURLs(obj) {
    console.log("getRxSafeImageURLs-------------", obj);
    this.loadingPillPouchImages = true;
    var raw = obj;
    var res = yield fetch(`${dbstore.endpoint}/common/rxSafeUrl`, {
      method: "post",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
      // cache: 'no-cache'
    });
    var status = res.status;
    res = yield res.json();
    this.selectedPillPouchImages = res;
    this.loadingPillPouchImages = false;

    return status;
  }

  *resetPrescriptionStore() {
    yield (this.comp1 = []);
    yield (this.comp2 = {
      pillCard: [],
      pillsList: [],
      selectedTime: null,
      pouchStatus: {},
    });
    yield (this.pcNotifications = {
      arr: [],
    });
    yield (this.comp3 = {});
    yield (this.selectedPillPouchImages = {});
    yield (this.loadingPillPouchImages = true);
  }

  setShowDoseAdherenceModal(value) {
    this.showDoseAdherenceModal = value;
  }
}

const prescriptionsStore = new Store();
export { prescriptionsStore };
