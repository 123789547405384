import { makeAutoObservable } from "mobx";
import { dbstore } from "../dbStore";
import calendarStore from "../pages/calendarStore";
import HelpStore from "../pages/help";
import { homeStore } from "../pages/homeStore";
import { loginStore } from "../pages/loginStore";
import { prescriptionsStore } from "../pages/prescriptionsStore";
import { profileStore } from "../pages/profileStore";
import { reportStore } from "../pages/reportStore";
import { rxDetailsStore } from "../pages/rxDetailsStore";
import { rxListStore } from "../pages/rxListStore";
import { vitalsStore } from "../pages/vitalsStore";

export default class ResetActionStore {
  constructor() {
    makeAutoObservable(this, {});
  }

  *resetStoreState() {
    yield dbstore.resetDbStore();
    yield calendarStore.resetCalendarStore();
    yield HelpStore.resetHelpStore();
    yield homeStore.resetHomeStore();
    yield loginStore.resetLoginStore();
    yield prescriptionsStore.resetPrescriptionStore();
    yield profileStore.resetProfileStore();
    yield reportStore.resetReportStore();
    yield rxDetailsStore.resetRxDetailsStore();
    yield rxListStore.resetRxListStore();
    yield vitalsStore.resetVitlasStore();
  }
}

const resetActionStore = new ResetActionStore();
export { resetActionStore };
