import { Col, Row, Layout } from "antd";
import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Card, Button, Table } from "react-bootstrap";
import { Colours } from "../Colours";

const MedicationProfile = observer(({ data, onClick = () => {} }) => {
  const tableTitles = useRef([
    "Prescriber Id",
    "Prescriber Name",
    "Specialization",
    "Address",
    "Last Updated",
    "Compliance Ratio",
    "Action",
  ]);
  const [maxRows, setMaxRows] = useState(5);

  return (
    <Card
      className={"mt-5"}
      style={{
        width: "100%",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <Card.Body className="pb-0">
        <Card.Title
          style={{
            fontFamily: "SourceSansPro-Regular",
            fontSize: 18,
            color: Colours.secondaryText,
          }}
        >
          Active Medication Profile
        </Card.Title>
        <Table
          responsive
          className="mb-0"
          style={{
            fontFamily: "SourceSansPro-Regular",
            fontSize: 13,
            color: Colours.secondaryText,
          }}
        >
          <thead
            style={{
              border: "none",
              backgroundColor: "#F5F6FA",
              color: "#A3A6B4",
            }}
          >
            <tr style={{ textTransform: "uppercase" }}>
              {/* {Object.keys(store.doctors[0]).map((title, index) => (
            <th key={index}>{title}</th>
          ))} */}
              {tableTitles.current.map((title, index) => (
                <th key={`th-${index}`}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(0, maxRows).map((doctor, ind) => {
              return (
                <tr
                  key={`${doctor.name}-tr-${ind}`}
                  onClick={() => {
                    // console.log({ DoctorName: doctor.name });
                    //currentPrescription.current = { ...doctor };
                    //setShowPrescription(true);
                    onClick(doctor._id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {/* {Object.keys(doctor).map((key, ind) => {
                return <td key={ind}>{doctor[key] || "N/A"}</td>;
              })} */}
                  <td key={`${doctor.name}-tr-${1}`}>{doctor._id || "N/A"}</td>
                  <td key={`${doctor.name}-tr-${2}`}>{doctor.name || "N/A"}</td>
                  <td key={`${doctor.name}-tr-${3}`}>
                    {doctor.specialization || "N/A"}
                  </td>
                  <td key={`${doctor.name}-tr-${4}`}>
                    {Object.values(doctor.address || {}).join(", ") || "N/A"}
                  </td>
                  <td key={`${doctor.name}-tr-${5}`}>
                    {doctor.lastUpdated || "N/A"}
                  </td>
                  <td key={`${doctor.name}-tr-${6}`}>
                    {doctor.complianceRatio || "N/A"}
                  </td>
                  <td key={`${doctor.name}-tr-${7}`}>
                    {doctor.action || "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
      {maxRows <= data.length ? (
        <Button
          className="col-6 col-md-4"
          variant="link"
          size="sm"
          style={{
            fontSize: 13,
            boxShadow: "none",
            alignSelf: "center",
          }}
          onClick={() => {
            setMaxRows(maxRows + 5);
          }}
        >
          Show More
        </Button>
      ) : (
        <Button
          className="col-6 col-md-4"
          variant="link"
          size="sm"
          style={{
            fontSize: 13,
            boxShadow: "none",
            alignSelf: "center",
          }}
          onClick={() => {
            setMaxRows(5);
          }}
        >
          Show Less
        </Button>
      )}
    </Card>
  );
});
export default MedicationProfile;
