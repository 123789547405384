import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import MedicationProfile from "../MedicationProfile";
import PillsByDate from "../PillsByDate";
import SelectSlider from "../SelectSlider";
import TrackRefillCard from "../TrackRefillCard";
import { Card } from "react-bootstrap";
import { prescriptionsStore } from "../../dataLayer/stores/pages/prescriptionsStore";
import { prescriptionsActionStore } from "../../dataLayer/stores/actions/prescriptionsActionStore";
import { observer } from "mobx-react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import DoseAdherenceModal from "../DoseAdherenceModal";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
  justifyContent: "center",
  display: "flex",
};

const Prescriptions = observer(({ props }) => {
  let history = useHistory();
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div style={box}>
            <MedicationProfile
              data={prescriptionsStore.comp1Data}
              onClick={(docid) => {
                prescriptionsActionStore.setDocid(docid);
                history.push(`/rxlist`);
              }}
            />
          </div>
        </Col>
        <Col span={10}>
          <div style={box}>
            <Card
              className={"mt-4 align-items-center pb-3 pt-3"}
              style={{
                width: "100%",
                marginBottom: "10px",
                borderRadius: 30,
                boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <SelectSlider
                onChange={(date) => {
                  prescriptionsActionStore.setComp2({ date: date });
                }}
              />
              <PillsByDate
                onClick={(time) => {
                  console.log(time);
                  prescriptionsActionStore.setComp2({
                    showSelect: true,
                    selectedTime: time,
                  });
                }}
                data={prescriptionsStore.comp2Data.pillCard}
              />
              <DoseAdherenceModal
                data={{
                  pills: prescriptionsStore.comp2Data.pillsList,
                  time: prescriptionsStore.comp2Data.selectedTime,
                }}
                visible={prescriptionsStore.comp2Data.showSelect}
                onCancel={() => {
                  prescriptionsActionStore.setComp2({ showSelect: false });
                }}
                onChange={prescriptionsActionStore.setPrescriptionStatus}
                onSubmit={prescriptionsActionStore.updatePrescription}
              />
            </Card>
          </div>
        </Col>
        <Col span={14}>
          <div style={box}>
            <TrackRefillCard />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Prescriptions;
