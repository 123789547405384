import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FaTablets } from "react-icons/fa";
import NextDoseTime from "./NextDoseTime";
import { inject, observer } from "mobx-react";
import { homeStore } from "../dataLayer/stores/pages/homeStore";
import { useTimer } from "react-timer-hook";
import { dbstore } from "../dataLayer/stores/dbStore";

// Returns a number with preceeding 'zero'
function formatNumber(number) {
  return `0${number}`.slice(-2);
}

const DoseCard = observer((props) => {
  useEffect(() => {
    if (homeStore.nextDoseInfoLoaded) {
      homeStore.setTimeRemainingInSecs();
    }
  }, [homeStore.nextDoseInfoLoaded]);

  useEffect(() => {
    homeStore.nextDoseTime();
  }, [, dbstore.currentPillBox]);

  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "30px",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#272D5C",
      }}
    >
      <Card.Body
        className={"row m-0 pl-4 pr-4"}
        style={{
          fontFamily: "Nunito-Regular",
          fontSize: "24px",
          color: "white",
          flex: "row",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "250px"
        }}
      >
        <FaTablets
          size={50}
          className="col-12 col-md-4 col-lg-3 col-xl-2 justify-content-center"
        />
        {homeStore.nextDoseDateTime ? (
          <>
            <span className="col text-center justify-content-center" >
              NEXT DOSE IN
            </span>
            <MyTimer expiryTimestamp={homeStore.nextDoseDateTime} />
          </>
        ) : (
          <span className="col text-center justify-content-center">
            NO UPCOMING DOSE
          </span>
        )}
      </Card.Body>
    </Card>
  );
});

export default DoseCard;

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.log("on expire fired");
      // restartTimer();
    },
  });

  function restartTimer() {
    let time = new Date();
    time.setSeconds(time.getSeconds() + 12); // 2 minutes timer
    // setRemainingTime(time);
    console.log("resetting timer", time);
    restart(time);
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "100px" }}>
        <span>{formatNumber(hours)}</span>:<span>{formatNumber(minutes)}</span>:
        <span>{formatNumber(seconds)}</span>
      </div>
    </div>
  );
}
