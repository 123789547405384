import { Col, Row, Dropdown, Avatar, Modal } from "antd";
import { useState } from "react";
import { observer } from "mobx-react";
import { DownOutlined } from "@ant-design/icons";
import ProfilePic from "../ProfilePic";
import { profileStore } from "../../dataLayer/stores/pages/profileStore";
import ProfileMenu from "../ProfileMenu";
import SwitchProfile from "../screens/profile/SwitchProfile";

const TopNav = observer(({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleProfileMenuClick(menuKey) {
    if (menuKey === "switchProfile") {
      setIsModalOpen(true);
    }
  }

  return (
    <Row style={{ padding: 20, backgroundColor: "#fff" }}>
      <Col span={20} />
      <Col span={4}>
        <Row>
          <Col>
            <Dropdown
              overlay={<ProfileMenu handleMenuClick={handleProfileMenuClick} />}
              trigger={["click"]}
            >
              {/* <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                
              </a> */}

              <div
                style={{
                  // marginTop: 9.5,
                  cursor: "pointer",
                  color: "#aaa",
                  marginLeft: 13,
                }}
              >
                <span style={{ color: "#333", marginRight: 15 }}>
                  {profileStore.comp1Data.name}
                </span>
                <DownOutlined />
                <Avatar
                  shape="circle"
                  style={{ marginLeft: 15 }}
                  size={45}
                  icon={<ProfilePic avatar={profileStore.comp1Data.avatar} />}
                />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Col>
      <Modal
        title="Select Profile"
        footer={false}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      >
        <SwitchProfile
          onSelectProfile={() => {
            setIsModalOpen(false);
          }}
        />
      </Modal>
    </Row>
  );
});
export default TopNav;
