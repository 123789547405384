import { makeAutoObservable, action } from "mobx";

export default class Store {
  comp1 = { selectedRxno: null };

  constructor() {
    makeAutoObservable(this, {
      setComp1: action,
    });
  }

  setComp1(obj) {
    Object.assign(this.comp1, obj);
    // console.log(this.comp1)
  }
}

const rxListActionStore = new Store();
export { rxListActionStore };
