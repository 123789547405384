import React, { useState, useEffect } from "react";
import { prescriptionsActionStore } from "../../../dataLayer/stores/actions/prescriptionsActionStore";
import { prescriptionsStore } from "../../../dataLayer/stores/pages/prescriptionsStore";
import PillsByDate from "../../PillsByDate";
import SelectSlider from "../../SelectSlider";
import { observer } from "mobx-react";
import moment from "moment";
import { Card, Col, Row } from "antd";
import UpdatePillsStatus from "./UpdatePillsStatus";
import { parsePillTime } from "../../../utils/Common.fns";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
  justifyContent: "center",
  display: "flex",
};

const PillCompliance = observer(({ data }) => {
  const [datesArray, setDatesArray] = useState([]);
  const [initialStartDate, setInitialStartDate] = useState(
    moment().utc(true).startOf("day").format()
  );
  const [showUpdatePillStatusModal, setShowUpdatePillStatusModal] =
    useState(false);

  function getDates(startDate = initialStartDate) {
    let fromDate = moment(startDate).subtract(3, "days");
    let toDate = moment(startDate).add(3, "days");
    let tempDates = [];

    while (moment(fromDate).isSameOrBefore(toDate)) {
      tempDates.push(moment(fromDate));
      fromDate.add(1, "day");
    }
    return tempDates;
  }

  useEffect(() => {
    let date = moment().utc(true).startOf("day").format();
    setInitialStartDate(date);
    setDatesArray([]);
    let dates = getDates(date);
    setDatesArray(dates);
    prescriptionsStore.setSelectedDayPillBox(date);
  }, []);

  function setNextOrPreviosDates(type) {
    let dates = [],
      defaultSelectedDate = initialStartDate;
    if (type == "next") {
      defaultSelectedDate = moment(defaultSelectedDate).add(7, "days");
      dates = getDates(defaultSelectedDate);
    } else if (type == "previous") {
      defaultSelectedDate = moment(defaultSelectedDate).subtract(7, "days");
      dates = getDates(defaultSelectedDate);
    }
    setInitialStartDate(defaultSelectedDate);
    setDatesArray(dates);

    let date = moment(defaultSelectedDate).format();
    prescriptionsStore.setSelectedDayPillBox(date);
  }

  return (
    <div style={box}>
      <Card
        style={{
          borderRadius: "20px",
          width: "100%",
        }}
        bodyStyle={{
          minHeight: "350px",
        }}
      >
        <Row>
          <Col span={24}>
            <SelectSlider
              sliderElements={datesArray}
              showNavigators={{
                right: true,
                left: true,
              }}
              onRightNavigatorClick={() => setNextOrPreviosDates("next")}
              onLeftNavigatorClick={() => setNextOrPreviosDates("previous")}
              data={{
                selected: 3,
                defaultSelected: 3,
                onclick: (v) => {
                  prescriptionsStore.setSelectedDayPillBox(v);
                },
              }}
              type="date"
              onChange={(date) => {
                prescriptionsActionStore.setComp2({ date: date });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={1} />
          <Col span={22}>
            <PillsByDate
              data={{
                arr: data.pillCard,
                onclick: (pouchIndex) => {
                  setShowUpdatePillStatusModal(true);
                  prescriptionsActionStore.setComp2({
                    selectedPouchIndex: pouchIndex,
                  });
                },
              }}
            />
          </Col>
          <Col span={1} />
        </Row>
        {showUpdatePillStatusModal && (
          <UpdatePillsStatus
            visible={showUpdatePillStatusModal}
            selectedTime={
              data.selectedTime ? parsePillTime(data.selectedTime) : null
            }
            selectedDate={prescriptionsActionStore.comp2.date}
            rxarr={data.pillsList}
            selectedPouch={
              data.pillCard[prescriptionsActionStore.comp2.selectedPouchIndex]
            }
            active={
              moment(prescriptionsActionStore.comp2.date)
                .startOf("D")
                .format("x") <= moment().format("x")
            }
            onCancel={() => setShowUpdatePillStatusModal(false)}
            onSelect={prescriptionsActionStore.setPrescriptionStatus}
            onYes={() => {
              setShowUpdatePillStatusModal(false);
              prescriptionsActionStore.updatePrescription(
                "taken",
                data.pillCard[prescriptionsActionStore.comp2.selectedPouchIndex]
              );
            }}
            onNo={() => {
              setShowUpdatePillStatusModal(false);
              prescriptionsActionStore.updatePrescription(
                "missed",
                data.pillCard[prescriptionsActionStore.comp2.selectedPouchIndex]
              );
            }}
          />
        )}
      </Card>
    </div>
  );
});

export default PillCompliance;
