import { Col, Row, Space, Button, Typography } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { leadingZero } from "../utils/Common.fns";
import moment from "moment";
import { dbstore } from "../dataLayer/stores/dbStore";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SelectSlider = observer(
  ({
    data,
    sliderElements = [],
    showNavigators = { right: true, left: true },
    onLeftNavigatorClick = () => {},
    onRightNavigatorClick = () => {},
    type = null,
  }) => {
    const [selectedItem, setselectedItem] = useState(3);

    // useFocusEffect is a React Navigation Lifecycle event
    useEffect(() => {
      // Do something when the screen is focused
      setselectedItem(data.defaultSelected);
      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [, sliderElements]);

    function renderDatesDisplayed(date, i) {
      const dateStyleObject = {
        selectedDateCard:
          i === selectedItem
            ? {
                border: "1px solid #e0e4eb",
                backgroundColor: "#e0e4eb",
              }
            : {},
        selectedDate:
          i === selectedItem
            ? {}
            : {
                border: "2px solid #e0e4eb",
              },
        date:
          i === selectedItem
            ? {
                fontSize: 20,
                color: "#140f26",
                padding: "5px",
                borderRadius: "50%",
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: moment
                  .utc(date)
                  .isSame(moment().utc(true).startOf("day").format())
                  ? "#272D5C"
                  : "#e0e4eb",
              }
            : {
                fontSize: 11,
                color: "#131315",
                padding: "5px",
                borderRadius: "50%",
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: moment
                  .utc(date)
                  .isSame(moment().utc(true).startOf("day").format())
                  ? "#272D5C"
                  : "#e0e4eb",
              },
      };

      return (
        <Button
          type="link"
          size="large"
          block
          style={{
            height: "80px",
          }}
          onClick={() => {
            setselectedItem(i);
            data.onclick(date);
          }}
        >
          <Space
            key={i}
            direction="vertical"
            style={dateStyleObject.selectedDateCard}
          >
            <Text>{moment.utc(date).format("dd")}</Text>
            <Text
              style={{
                ...dateStyleObject.date,
                //greying out the text if the date is not between active pill box start and end date
                color: moment
                  .utc(date)
                  .isBetween(
                    moment
                      .utc(dbstore.currentPillBox.administrationStartDateTime)
                      .startOf("day"),
                    moment
                      .utc(dbstore.currentPillBox.administrationEndDateTime)
                      .endOf("day"),
                    undefined,
                    []
                  )
                  ? dateStyleObject.date.color
                  : "#C1C1C1",
                fontWeight:
                  !(i === selectedItem) &&
                  moment
                    .utc(date)
                    .isSame(moment().utc(true).startOf("day").format())
                    ? "800"
                    : "500",
              }}
            >
              {type != "date"
                ? date
                : leadingZero(new Date(date).getUTCDate(), 2)}
            </Text>
          </Space>
        </Button>
      );
    }

    return (
      <Row>
        <Col span={24}>
          <Space direction="vertical">
            <Row>
              <Col span={24}>
                <Text>
                  {moment
                    .utc(sliderElements[selectedItem])
                    .format("MMMM DD, YYYY")}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Space>
                  <LeftOutlined
                    style={{
                      fontSize: "30px",
                    }}
                    onClick={() => {
                      onLeftNavigatorClick();
                    }}
                  />
                  {sliderElements.map((date, i) =>
                    renderDatesDisplayed(date, i)
                  )}
                  <RightOutlined
                    style={{
                      fontSize: "30px",
                    }}
                    onClick={() => {
                      onRightNavigatorClick();
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
    );
  }
);
export default SelectSlider;
