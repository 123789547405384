import { makeAutoObservable, action } from "mobx";
import { dbstore } from "../dbStore";

export default class Store {
  comp1 = {};

  constructor() {
    makeAutoObservable(this, {
      setComp1: action,
    });
  }

  setComp1(obj) {
    Object.assign(this.comp1, obj);
  }

  *setProfilePic(file) {
    var res = yield dbstore.newProfilePic(file);
    console.log(res);
    return res;
  }
}

const profileActionStore = new Store();
export { profileActionStore };
