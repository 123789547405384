import React, { useState, useRef } from 'react';
import fastingOriginal from "../../../assets/images/restaurant-icon@2x.png";
import preMealOriginal from "../../../assets/images/hot-soup-bowl-icon@2x.png";
import postMealOriginal from "../../../assets/images/meal-icon@2x.png";
import beforeSleepOriginal from "../../../assets/images/moon-icon@2x.png";
import generalOriginal from "../../../assets/images/standing-up-man-icon@2x.png";
import fastingAlt from "../../../assets/images/restaurant-icon-selected@2x.png";
import preMealAlt from "../../../assets/images/hot-soup-bowl-icon-selected@2x.png";
import postMealAlt from "../../../assets/images/meal-icon-selected@2x.png";
import beforeSleepAlt from "../../../assets/images/moon-icon-selected@2x.png";
import generalAlt from "../../../assets/images/standing-up-man-icon-selected@2x.png";
import { observer } from 'mobx-react';
import { Image } from 'react-bootstrap';


const BloodGlucoseType = observer(({
    color,
    onChange = () => { },
    defaultValue = "",
  }) => {

    const [selectedOption, setSelectedOption] = useState(defaultValue);
    const availableOptions = useRef({
      original: {
        "fasting": fastingOriginal,
        "pre-meal": preMealOriginal,
        "post-meal": postMealOriginal,
        "before-sleep": beforeSleepOriginal,
        "general": generalOriginal,
      },
      alt: {
        "fasting": fastingAlt,
        "pre-meal": preMealAlt,
        "post-meal": postMealAlt,
        "before-sleep": beforeSleepAlt,
        "general": generalAlt,
      },
    });
    return (
      <div className=" d-flex justify-content-around" style={{cursor: "pointer"}} >
        {Object.keys(availableOptions.current.original).map((key, ind) => {
          let isSelected = selectedOption === key;
          return (
            <div
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="mx-auto"
                onClick={() => {
                  console.log({ key });
                  setSelectedOption(key);
                  onChange(key);
                }}
                style={{
                  display: "flex",
                  backgroundColor: isSelected ? color : "#E4E4E4",
                  height: 60,
                  width: 60,
                  borderRadius: 30,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={
                    isSelected
                      ? availableOptions.current.alt[key]
                      : availableOptions.current.original[key]
                  }
                  style={{
                    height: "50%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div
                style={{
                  fontFamily: "Nunito-SemiBold",
                  fontSize: 12,
                  color: isSelected ? color : "#ACACAC",
                  marginVertical: 5,
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
              >
                {key.split("-").join(" ")}
              </div>
            </div>
          );
        })}
      </div>
    );
  });

export default BloodGlucoseType
