import { Col, Row, Typography } from "antd";
import { Card } from "react-bootstrap";
import ProfileChip from "../components/ProfileChip";
import { observer } from "mobx-react";
import { dbstore } from "../dataLayer/stores/dbStore";

const { Title } = Typography;

const ProfileContactDetails = observer(({ data, onSettingsChange }) => {
  console.log(data);
  if (!data.settings) {
    data.settings = {
      repeatReminders: true,
      eodReminders: false,
      followUp: "Daily",
    };
  }
  if (!data.options) {
    data.options = ["op1", "op2", "op3"];
  }

  return (
    <Card
      // className={"col-12 col-lg-4 mr-lg-4"}
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <Card.Body>
        <Row>
          <Col>
            <Title level={3}>Contact</Title>
          </Col>
        </Row>
        <Row gutter={(8, 8)} align="middle">
          <Col span={16}>
            {Object.keys(dbstore.contacts).map((contact) => (
              <ProfileChip
                name={dbstore.contacts[contact].name}
                role={dbstore.contacts[contact].role}
                avatar={data?.profile?.avatar || null}
                mobileNumber={dbstore.contacts[contact].phone}
                allowEdit={false}
                showMobile={true}
                entity="contact"
              />
            ))}
            {/* <ProfileChip
              name={"Regional Manager"}
              avatar={data.profile.avatar || null}
              mobileNumber={"+1 (509) 841-0000"}
              allowEdit={false}
              showMobile={true}
              entity="contact"
            />
            <ProfileChip
              name={"CEO"}
              avatar={data.profile.avatar || null}
              mobileNumber={"+1 (509) 588-0000"}
              allowEdit={false}
              showMobile={true}
              entity="contact"
            /> */}
          </Col>
          <Col span={8}></Col>
          <Col span={10}></Col>
          <Col span={14}></Col>
        </Row>
      </Card.Body>
    </Card>
  );
});
export default ProfileContactDetails;
