import { Col, Row } from "antd";
import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import DoseAdherenceModal from "../../DoseAdherenceModal";
import PillsByDate from "../../PillsByDate";
import SelectSlider from "../../SelectSlider";
import { Card } from "react-bootstrap";
import ActiveMedicationProfile from "./ActiveMedicationProfile";
import { prescriptionsStore } from "../../../dataLayer/stores/pages/prescriptionsStore";
import { prescriptionsActionStore } from "../../../dataLayer/stores/actions/prescriptionsActionStore";
import { observer } from "mobx-react";
import { dbstore } from "../../../dataLayer/stores/dbStore";
import PillCompliance from "./PillCompliance";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
  justifyContent: "center",
  display: "flex",
};

const Pills = observer(() => {
  let history = useHistory();

  useEffect(() => {
    dbstore.getPatientActiveRxes();
    dbstore.getPcArr();
    dbstore.getRxesArray();
  }, []);

  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <div style={box}>
          <ActiveMedicationProfile
            data={prescriptionsStore.comp1Data}
            onClick={(docid) => {
              prescriptionsActionStore.setDocid(docid);
              history.push(`/rxlist`);
            }}
          />
        </div>
      </Col>
      <Col span={14}>
        <PillCompliance
            data={prescriptionsStore.comp2Data}
        />
      </Col>
    </Row>
  );
});

export default Pills;
