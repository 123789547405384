import { observer } from "mobx-react";
import moment from "moment";
import { Card, Col, Row, Space, Typography } from "antd";

const { Text } = Typography;

const PillAdherenceStatusCard = observer(
  ({ compliance, className, onClick, store, data }) => {

    function getPillCardStatus(data) {
      console.log("date-------", data);
      const { date, time, status } = data;
      let now = moment();
      let pouchDateTime = moment(`${date} ${time}`);
      if (status === "taken") {
        return {
          statusColor: "#1fb988",
          statusText: "Taken",
        };
      } else if (status === "missed") {
        return {
          statusColor: "#ff4e24",
          statusText: "Missed",
        };
      } else if (status === null) {
        if (!pouchDateTime.isAfter(now)) {
          return {
            statusColor: "#fab802",
            statusText: "Unanswered",
          };
        } else {
          return {
            statusColor: "#272D5C",
            statusText: "Future Dose",
          };
        }
      }
    }

    function getPillAdministrationTime(date, time) {
      let pillAdministeredDateTime = date + " " + ("0" + time).slice(-5);
      return moment(pillAdministeredDateTime).format("hh:mm A");
    }

    return (
      <div
        className={`${className} ml-2`}
        style={{
          ...{
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "20px",
            width: 80,
            height: 80,
            boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
          },
          backgroundColor: getPillCardStatus(data).statusColor,
        }}
        onClick={() => data.onclick()}
        type="button"
      >
        <div
          className={"col m-0 p-0"}
          style={{
            height: "100%",
            width: "100%",
            fontFamily: "Nunito-Light",
            fontSize: "12px",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Text style={{ color: "white" }}>
            {getPillAdministrationTime(data.date, data.time)}
          </Text>
          <Text style={{ color: "white" }}>
            {data.totalTablets}
            {data.totalTablets > 1 ? " Pills" : " Pill"}
          </Text>
          <Text style={{ color: "white", fontSize: "10px" }}>
            {getPillCardStatus(data).statusText}
          </Text>
        </div>
      </div>
    );
  }
);

export default PillAdherenceStatusCard;
