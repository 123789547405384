import React from "react";

const BloodPressureDeviceIcon = (props) => {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 670 373"
      width="100%" 
      height="100%" 
    >
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M388.000000,0.999999 
   C482.289368,1.000000 576.578735,1.000000 670.934082,1.000000 
   C670.934082,125.231407 670.934082,249.462860 670.934082,374.000000 
   C577.978760,374.000000 484.957245,374.000000 391.334106,373.575989 
   C391.234680,372.772491 391.714111,372.097931 392.242310,372.057281 
   C402.101257,371.298523 411.966461,370.621246 421.829346,369.912872 
   C425.909912,369.619812 430.002838,369.422821 434.064545,368.958099 
   C445.105682,367.694885 456.158905,366.490662 467.155426,364.903259 
   C472.910553,364.072510 478.808716,363.145050 484.193237,361.086609 
   C492.432343,357.936829 495.017334,350.861084 495.010010,342.437469 
   C494.952576,276.183319 494.960175,209.929047 494.955322,143.674820 
   C494.952576,106.052986 495.065613,68.430695 494.895630,30.809649 
   C494.854736,21.762215 489.993958,14.421277 480.228088,12.941508 
   C472.970184,11.841751 465.725891,10.635445 458.448029,9.688028 
   C445.989594,8.066223 433.531067,6.394885 421.032043,5.151496 
   C410.864777,4.140068 400.634583,3.769683 390.444275,2.963804 
   C389.583130,2.895700 388.812622,1.681664 388.000000,0.999999 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M281.000000,374.000000 
   C187.710190,374.000000 94.420387,374.000000 1.065289,374.000000 
   C1.065289,249.768204 1.065289,125.536362 1.065289,1.000000 
   C94.687881,1.000000 188.376083,1.000000 282.635345,1.426817 
   C281.363770,2.264553 279.538116,2.906593 277.675354,3.043348 
   C271.930481,3.465111 266.147461,3.460041 260.422943,4.046942 
   C248.876694,5.230713 237.355835,6.667336 225.830902,8.052779 
   C214.978302,9.357400 204.085205,10.430721 193.303940,12.183888 
   C186.679962,13.261027 180.577225,16.007408 178.866211,23.554396 
   C177.940933,27.635698 177.079575,31.849499 177.075562,36.004383 
   C176.977585,137.291107 176.998337,238.577927 177.003036,339.864746 
   C177.003143,342.363098 176.958908,344.868042 177.115982,347.358490 
   C177.471573,352.996796 184.502274,361.287567 189.404739,361.948151 
   C202.016464,363.647461 214.604141,365.566223 227.248917,366.969482 
   C244.026901,368.831421 260.853577,370.252228 277.645935,371.992157 
   C278.820709,372.113861 279.884552,373.305756 281.000000,374.000000 
z"
      />
      <path
        fill="#64798A"
        opacity="1.000000"
        stroke="none"
        d="
M281.468658,374.000000 
   C279.884552,373.305756 278.820709,372.113861 277.645935,371.992157 
   C260.853577,370.252228 244.026901,368.831421 227.248917,366.969482 
   C214.604141,365.566223 202.016464,363.647461 189.404739,361.948151 
   C184.502274,361.287567 177.471573,352.996796 177.115982,347.358490 
   C176.958908,344.868042 177.003143,342.363098 177.003036,339.864746 
   C176.998337,238.577927 176.977585,137.291107 177.075562,36.004383 
   C177.079575,31.849499 177.940933,27.635698 178.866211,23.554396 
   C180.577225,16.007408 186.679962,13.261027 193.303940,12.183888 
   C204.085205,10.430721 214.978302,9.357400 225.830902,8.052779 
   C237.355835,6.667336 248.876694,5.230713 260.422943,4.046942 
   C266.147461,3.460041 271.930481,3.465111 277.675354,3.043348 
   C279.538116,2.906593 281.363770,2.264553 283.103210,1.426817 
   C317.687561,1.000000 352.375122,1.000000 387.531342,0.999999 
   C388.812622,1.681664 389.583130,2.895700 390.444275,2.963804 
   C400.634583,3.769683 410.864777,4.140068 421.032043,5.151496 
   C433.531067,6.394885 445.989594,8.066223 458.448029,9.688028 
   C465.725891,10.635445 472.970184,11.841751 480.228088,12.941508 
   C489.993958,14.421277 494.854736,21.762215 494.895630,30.809649 
   C495.065613,68.430695 494.952576,106.052986 494.955322,143.674820 
   C494.960175,209.929047 494.952576,276.183319 495.010010,342.437469 
   C495.017334,350.861084 492.432343,357.936829 484.193237,361.086609 
   C478.808716,363.145050 472.910553,364.072510 467.155426,364.903259 
   C456.158905,366.490662 445.105682,367.694885 434.064545,368.958099 
   C430.002838,369.422821 425.909912,369.619812 421.829346,369.912872 
   C411.966461,370.621246 402.101257,371.298523 392.242310,372.057281 
   C391.714111,372.097931 391.234680,372.772491 390.866272,373.575989 
   C354.645782,374.000000 318.291534,374.000000 281.468658,374.000000 
M373.670044,342.983093 
   C376.463318,341.009064 377.533264,338.352356 376.960297,334.065460 
   C376.972382,323.751068 376.989655,313.436646 376.993805,303.122253 
   C376.995331,299.325867 375.047333,297.073853 371.293335,297.060455 
   C348.002655,296.977417 324.711151,296.952209 301.420837,297.075653 
   C297.540771,297.096191 294.895325,298.970642 294.965424,303.534729 
   C295.131439,314.343994 295.110229,325.156128 295.042786,336.735901 
   C296.379791,338.878021 297.716827,341.020172 299.789642,343.511505 
   C300.935852,343.671173 302.081848,343.968628 303.228271,343.970093 
   C325.014923,343.998260 346.801605,344.007385 368.588165,343.956390 
   C370.041382,343.952972 371.493439,343.438843 373.670044,342.983093 
M459.102600,32.024048 
   C407.009399,32.027233 354.916168,32.051598 302.822968,32.025501 
   C272.535889,32.010330 242.248886,31.914202 211.738586,31.773039 
   C211.511551,31.700850 211.282364,31.710018 210.364578,31.974957 
   C208.334473,34.350155 206.304352,36.725353 204.142624,39.306530 
   C204.010284,39.515419 203.962219,39.746670 204.082474,40.934311 
   C204.090027,76.532524 204.114609,112.130737 204.100525,147.728943 
   C204.085052,186.819183 204.033981,225.909424 203.995316,265.244965 
   C204.017960,265.487854 204.111023,265.706177 204.703857,266.423767 
   C206.823807,268.544800 208.943771,270.665802 211.501297,272.933838 
   C211.501297,272.933838 211.955429,272.851227 212.892380,272.965881 
   C262.655853,272.981415 312.419312,273.020508 362.182770,273.004150 
   C394.800812,272.993408 427.418884,272.902527 460.485840,272.895325 
   C460.485840,272.895325 460.918549,272.766541 461.601379,272.690094 
   C463.642853,270.426514 465.684326,268.162933 467.857422,265.693390 
   C467.989746,265.484528 468.037811,265.253296 467.917542,264.065704 
   C467.910004,191.427460 467.903870,118.789215 467.888306,46.150967 
   C467.887817,43.820312 467.790649,41.489677 467.743713,38.846458 
   C467.710205,38.526993 467.561005,38.266182 466.963013,37.523426 
   C464.959595,35.621593 462.956177,33.719761 460.719696,31.762554 
   C460.483429,31.719738 460.254822,31.757162 459.102600,32.024048 
M286.001556,315.505371 
   C285.991241,311.344879 286.014893,307.183929 285.960602,303.024017 
   C285.911041,299.222107 283.977356,297.061951 280.128845,297.055969 
   C256.996490,297.019989 233.863831,296.992096 210.731903,297.098602 
   C207.371414,297.114044 204.183426,298.496094 204.133041,302.467987 
   C203.993851,313.444763 203.878815,324.437958 204.339172,335.400085 
   C204.607285,341.784607 206.433334,342.999786 212.870132,342.947357 
   C234.645309,342.770050 256.420502,342.572021 278.194489,342.287598 
   C285.266785,342.195190 285.979370,341.309998 285.998108,333.977783 
   C286.013000,328.153015 286.001404,322.328156 286.001556,315.505371 
M385.997620,332.369141 
   C386.036011,334.363953 385.462494,336.667389 386.272400,338.273010 
   C387.218903,340.149261 389.280273,342.603638 391.018433,342.738464 
   C397.772644,343.262390 404.590546,342.972107 411.384735,342.972870 
   C426.729401,342.974609 442.074097,342.987122 457.418732,342.959259 
   C465.233856,342.945068 467.728394,341.083160 467.902344,334.228027 
   C468.159637,324.088196 468.030304,313.936584 467.945496,303.790985 
   C467.910980,299.666962 465.649445,297.101959 461.498840,297.084656 
   C438.212463,296.987579 414.925354,297.001282 391.638763,297.069855 
   C387.962219,297.080658 385.885864,298.840912 385.954193,302.958679 
   C386.111542,312.437195 385.999939,321.920197 385.997620,332.369141 
z"
      />
      <path
        fill="#EBF0F3"
        opacity="1.000000"
        stroke="none"
        d="
M467.738525,39.159035 
   C467.790649,41.489677 467.887817,43.820312 467.888306,46.150967 
   C467.903870,118.789215 467.910004,191.427460 467.843140,264.743042 
   C467.768768,265.420349 467.725800,265.899353 467.725800,265.899353 
   C465.684326,268.162933 463.642853,270.426514 461.147705,272.703552 
   C460.464691,272.683350 460.245667,272.726837 460.036926,272.847534 
   C427.418884,272.902527 394.800812,272.993408 362.182770,273.004150 
   C312.419312,273.020508 262.655853,272.981415 212.315887,272.864258 
   C211.516342,272.689270 211.291107,272.697327 211.063721,272.786835 
   C208.943771,270.665802 206.823807,268.544800 204.467773,265.922729 
   C204.231705,265.421661 203.998444,264.999664 203.998444,264.999664 
   C204.033981,225.909424 204.085052,186.819183 204.100525,147.728943 
   C204.114609,112.130737 204.090027,76.532524 204.156860,40.256950 
   C204.231247,39.579594 204.274246,39.100552 204.274246,39.100548 
   C206.304352,36.725353 208.334473,34.350155 210.933136,31.941374 
   C211.501678,31.907791 211.961853,31.854506 211.961853,31.854504 
   C242.248886,31.914202 272.535889,32.010330 302.822968,32.025501 
   C354.916168,32.051598 407.009399,32.027233 459.800934,31.983221 
   C460.499268,31.942394 460.952759,31.817930 460.952759,31.817930 
   C462.956177,33.719761 464.959595,35.621593 467.183594,38.090736 
   C467.515625,38.825047 467.627075,38.992039 467.738525,39.159035 
M271.119232,198.426315 
   C270.965607,205.641769 270.835144,212.857834 270.648163,220.072433 
   C270.560028,223.472229 272.748138,223.139999 275.874329,222.961075 
   C278.039032,222.981293 280.205658,222.950958 282.367981,223.033493 
   C285.655823,223.159012 286.430878,221.481094 285.573730,218.059967 
   C282.269836,218.059967 278.857391,218.059967 275.032562,218.059967 
   C275.032562,212.379822 275.243195,207.232269 274.892273,202.123291 
   C274.798340,200.755905 272.990082,199.506302 271.119232,198.426315 
M221.784225,221.955978 
   C227.396805,222.155060 223.508759,217.332077 225.407791,215.344147 
   C227.448212,214.961136 229.694016,214.510925 231.950348,214.121719 
   C237.334778,213.192932 239.345825,210.875732 238.914978,206.041855 
   C238.433365,200.638443 235.585052,197.911331 230.407150,197.961349 
   C227.256012,197.991806 224.105576,198.094040 220.470154,198.133331 
   C220.124557,198.312363 220.008972,198.587875 219.973526,199.891632 
   C220.023483,206.939041 220.073441,213.986435 220.120087,221.513428 
   C220.120087,221.513428 220.469559,221.841888 220.469559,221.841888 
   C220.469559,221.841888 220.949142,221.840347 221.784225,221.955978 
M453.796112,259.476288 
   C453.862701,258.482513 453.987488,257.488708 453.987610,256.494934 
   C453.996765,187.381058 453.997681,118.267181 453.982605,49.153305 
   C453.982330,47.882000 453.781128,46.610741 453.660767,45.186852 
   C403.102692,45.186852 352.860260,45.186852 302.607727,45.186852 
   C302.607727,48.153225 302.607697,50.941509 302.607697,53.729794 
   C302.607727,120.512123 302.596405,187.294464 302.665283,254.076721 
   C302.667236,255.995926 303.520508,257.914246 304.909332,259.961090 
   C328.848328,259.973022 352.787323,260.011169 376.726288,259.989716 
   C402.158569,259.966919 427.590820,259.887573 453.796112,259.476288 
M223.126312,150.485092 
   C223.126312,154.237701 223.126312,157.990311 223.126312,161.948151 
   C231.186935,162.079834 238.937531,162.698227 243.540741,155.738922 
   C246.304001,151.561340 246.428589,146.025085 243.148987,141.670227 
   C237.964966,134.786575 230.496185,137.211548 223.126328,137.220947 
   C223.126328,141.597443 223.126328,145.548645 223.126312,150.485092 
M280.592346,80.190063 
   C280.741150,79.212196 281.106018,77.402550 281.007477,77.376953 
   C277.730530,76.525780 274.290619,74.966324 271.128754,75.393181 
   C268.127594,75.798347 265.094177,77.748901 264.884399,82.027512 
   C264.678741,86.222511 267.490021,87.297432 270.354248,88.545174 
   C272.749176,89.588493 275.207336,90.486740 277.735138,91.487907 
   C277.735138,92.832932 277.735138,94.244965 277.735138,96.575294 
   C274.385620,95.840736 271.464905,95.085663 268.499298,94.611076 
   C267.441193,94.441757 266.274414,94.951942 265.157227,95.151970 
   C265.606110,96.250801 265.913879,98.207306 266.526947,98.308159 
   C270.284851,98.926399 274.239380,99.832176 277.864716,99.178871 
   C279.824127,98.825790 282.211639,95.750877 282.626404,93.566017 
   C282.947876,91.872925 280.914459,89.117760 279.188293,87.813721 
   C277.232086,86.335938 274.292969,86.227409 272.094482,84.974030 
   C270.964600,84.329857 270.516205,82.490356 269.759857,81.191032 
   C271.102905,80.482300 272.446594,79.197006 273.788849,79.198532 
   C275.856171,79.200874 277.922485,80.085602 280.592346,80.190063 
M262.024475,218.617294 
   C266.575165,212.338348 264.338654,205.291672 264.821503,198.057281 
   C262.922302,198.207657 261.575867,198.314255 260.006165,198.438522 
   C260.006165,203.011841 259.755341,207.171783 260.074402,211.287567 
   C260.415680,215.690323 258.343842,218.043289 254.348770,218.314194 
   C250.130447,218.600220 248.279221,215.699783 248.085068,211.816864 
   C247.902725,208.170410 248.217560,204.488937 247.861420,200.867340 
   C247.750443,199.738831 246.166306,198.755203 245.256561,197.705246 
   C244.839325,198.020645 244.422073,198.336044 244.004822,198.651443 
   C244.004822,202.451920 243.889709,206.257156 244.046616,210.051147 
   C244.145874,212.451385 244.280960,214.961380 245.046997,217.200836 
   C246.933533,222.716019 256.662018,224.077148 262.024475,218.617294 
M227.172363,95.859215 
   C225.770081,95.120285 224.367798,94.381355 222.965515,93.642418 
   C222.529800,93.964828 222.094086,94.287239 221.658371,94.609650 
   C222.255219,95.947205 222.652161,98.283264 223.483490,98.449707 
   C227.107040,99.175201 231.009125,100.016678 234.501907,99.304581 
   C236.494064,98.898430 238.799789,95.769806 239.259705,93.518776 
   C239.599365,91.856255 237.549255,89.204674 235.888077,87.783943 
   C234.413422,86.522728 231.785156,86.695526 230.078735,85.595337 
   C228.480545,84.564941 227.423721,82.694908 226.128662,81.194382 
   C227.994125,80.551521 229.821762,79.626724 231.738968,79.369461 
   C232.926559,79.210106 234.231476,80.265991 235.520096,80.386040 
   C236.253082,80.454338 237.477570,79.906715 237.714005,79.324890 
   C237.946976,78.751587 237.379593,77.236122 236.874588,77.082329 
   C234.086823,76.233383 231.099976,74.867378 228.395355,75.238388 
   C225.570831,75.625862 222.380295,76.893181 221.970886,80.995888 
   C221.553299,85.180305 223.096283,87.531044 227.103149,88.612106 
   C228.845367,89.082161 230.404388,90.428909 232.144272,90.668640 
   C235.747421,91.165085 235.203735,93.195801 234.498886,95.972000 
   C232.285507,95.972000 230.155869,95.972000 227.172363,95.859215 
M264.760284,156.913544 
   C266.602448,155.134842 275.290588,154.172302 277.021973,155.627502 
   C277.482666,156.014725 277.915161,156.696136 277.961121,157.274216 
   C278.286560,161.369614 280.714294,161.379837 283.683960,160.627487 
   C280.872772,153.962250 278.142303,147.512100 275.432556,141.053238 
   C274.134399,137.958969 272.256042,136.023392 268.591187,137.418808 
   C265.259003,145.019394 261.938660,152.593033 258.618286,160.166687 
   C259.096222,160.581436 259.574188,160.996170 260.052124,161.410904 
   C261.483856,160.146683 262.915558,158.882462 264.760284,156.913544 
M253.971054,91.984901 
   C256.840179,86.699196 259.709320,81.413483 262.834808,75.655457 
   C254.932068,75.254501 256.562927,83.671173 252.099777,85.745758 
   C247.657410,83.442009 248.784683,75.095421 241.272049,75.689445 
   C244.353516,83.239044 251.424835,88.742355 250.223022,97.713211 
   C250.126892,98.430779 251.955017,99.406143 253.963440,101.246094 
   C253.963440,97.411987 253.963440,95.128136 253.971054,91.984901 
M254.633560,154.302063 
   C254.624100,149.487305 254.719086,144.668137 254.515930,139.861557 
   C254.475922,138.914948 253.285980,138.016922 252.626389,137.096481 
   C252.051849,138.061798 251.005386,139.014877 250.980011,139.994431 
   C250.825134,145.967484 250.826767,151.948425 250.975693,157.921768 
   C251.000793,158.928772 251.995560,159.911621 252.542328,160.905624 
   C253.228195,160.011566 254.137955,159.205002 254.531860,158.196884 
   C254.870972,157.329071 254.621857,156.231430 254.633560,154.302063 
z"
      />
      <path
        fill="#42C0A2"
        opacity="1.000000"
        stroke="none"
        d="
M286.001587,316.004364 
   C286.001404,322.328156 286.013000,328.153015 285.998108,333.977783 
   C285.979370,341.309998 285.266785,342.195190 278.194489,342.287598 
   C256.420502,342.572021 234.645309,342.770050 212.870132,342.947357 
   C206.433334,342.999786 204.607285,341.784607 204.339172,335.400085 
   C203.878815,324.437958 203.993851,313.444763 204.133041,302.467987 
   C204.183426,298.496094 207.371414,297.114044 210.731903,297.098602 
   C233.863831,296.992096 256.996490,297.019989 280.128845,297.055969 
   C283.977356,297.061951 285.911041,299.222107 285.960602,303.024017 
   C286.014893,307.183929 285.991241,311.344879 286.001587,316.004364 
z"
      />
      <path
        fill="#26A1D9"
        opacity="1.000000"
        stroke="none"
        d="
M385.997559,331.885254 
   C385.999939,321.920197 386.111542,312.437195 385.954193,302.958679 
   C385.885864,298.840912 387.962219,297.080658 391.638763,297.069855 
   C414.925354,297.001282 438.212463,296.987579 461.498840,297.084656 
   C465.649445,297.101959 467.910980,299.666962 467.945496,303.790985 
   C468.030304,313.936584 468.159637,324.088196 467.902344,334.228027 
   C467.728394,341.083160 465.233856,342.945068 457.418732,342.959259 
   C442.074097,342.987122 426.729401,342.974609 411.384735,342.972870 
   C404.590546,342.972107 397.772644,343.262390 391.018433,342.738464 
   C389.280273,342.603638 387.218903,340.149261 386.272400,338.273010 
   C385.462494,336.667389 386.036011,334.363953 385.997559,331.885254 
z"
      />
      <path
        fill="#FDD562"
        opacity="1.000000"
        stroke="none"
        d="
M295.164673,335.967102 
   C295.110229,325.156128 295.131439,314.343994 294.965424,303.534729 
   C294.895325,298.970642 297.540771,297.096191 301.420837,297.075653 
   C324.711151,296.952209 348.002655,296.977417 371.293335,297.060455 
   C375.047333,297.073853 376.995331,299.325867 376.993805,303.122253 
   C376.989655,313.436646 376.972382,323.751068 376.577393,334.672668 
   C373.103119,336.186279 370.021820,337.834747 366.918701,337.876953 
   C348.661316,338.125519 330.396729,338.156952 312.139435,337.906677 
   C306.473358,337.829010 300.822510,336.644745 295.164673,335.967102 
z"
      />
      <path
        fill="#F8C457"
        opacity="1.000000"
        stroke="none"
        d="
M295.103729,336.351501 
   C300.822510,336.644745 306.473358,337.829010 312.139435,337.906677 
   C330.396729,338.156952 348.661316,338.125519 366.918701,337.876953 
   C370.021820,337.834747 373.103119,336.186279 376.514435,335.137421 
   C377.533264,338.352356 376.463318,341.009064 372.846313,343.010803 
   C347.699646,343.079773 323.376740,343.121033 299.053833,343.162292 
   C297.716827,341.020172 296.379791,338.878021 295.103729,336.351501 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M299.421753,343.336914 
   C323.376740,343.121033 347.699646,343.079773 372.484283,343.100403 
   C371.493439,343.438843 370.041382,343.952972 368.588165,343.956390 
   C346.801605,344.007385 325.014923,343.998260 303.228271,343.970093 
   C302.081848,343.968628 300.935852,343.671173 299.421753,343.336914 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M211.850220,31.813772 
   C211.961853,31.854506 211.501678,31.907791 211.276352,31.854166 
   C211.282364,31.710018 211.511551,31.700850 211.850220,31.813772 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M460.836243,31.790241 
   C460.952759,31.817930 460.499268,31.942394 460.266571,31.908611 
   C460.254822,31.757162 460.483429,31.719738 460.836243,31.790241 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M467.741150,39.002747 
   C467.627075,38.992039 467.515625,38.825047 467.350159,38.361038 
   C467.561005,38.266182 467.710205,38.526993 467.741150,39.002747 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M204.208435,39.203541 
   C204.274246,39.100552 204.231247,39.579594 204.114853,39.789940 
   C203.962219,39.746670 204.010284,39.515419 204.208435,39.203541 
z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M460.261383,272.871429 
   C460.245667,272.726837 460.464691,272.683350 460.806305,272.741791 
   C460.918549,272.766541 460.485840,272.895325 460.261383,272.871429 
z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M211.282501,272.860352 
   C211.291107,272.697327 211.516342,272.689270 211.847412,272.806946 
   C211.955429,272.851227 211.501297,272.933838 211.282501,272.860352 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M467.791626,265.796387 
   C467.725800,265.899353 467.768768,265.420349 467.885162,265.210022 
   C468.037811,265.253296 467.989746,265.484528 467.791626,265.796387 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M203.996887,265.122314 
   C203.998444,264.999664 204.231705,265.421661 204.253082,265.660828 
   C204.111023,265.706177 204.017960,265.487854 203.996887,265.122314 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M453.023071,259.832947 
   C427.590820,259.887573 402.158569,259.966919 376.726288,259.989716 
   C352.787323,260.011169 328.848328,259.973022 304.447937,259.439087 
   C303.991150,190.451523 303.993805,121.985939 304.003235,53.520351 
   C304.004272,46.022896 304.022369,46.004612 311.491730,46.003582 
   C355.969421,45.997459 400.447083,45.998791 444.924774,46.001980 
   C452.985962,46.002556 452.996826,46.011887 452.998047,53.936359 
   C453.008789,122.568558 453.015289,191.200745 453.023071,259.832947 
M335.506439,227.224533 
   C335.671509,224.600922 336.166412,221.947388 335.931793,219.360016 
   C335.467896,214.244339 337.679901,212.524490 342.560455,212.944611 
   C346.002594,213.240906 349.490479,213.006042 353.338562,213.774841 
   C353.502838,214.008072 353.667084,214.241318 353.620209,215.288452 
   C353.756012,219.496002 353.891815,223.703568 354.195435,228.618713 
   C357.416077,231.342987 358.750153,229.526703 358.869263,226.375916 
   C359.037872,221.917999 358.767548,217.443497 358.708374,212.573761 
   C358.563385,212.283737 358.335907,212.186386 357.400543,211.988083 
   C355.674164,210.687363 353.989014,208.346161 352.213837,208.275772 
   C346.243500,208.039047 340.241119,208.610855 334.706482,207.881973 
   C334.805817,202.892731 335.030701,197.900391 334.908325,192.916611 
   C334.880798,191.795944 333.640594,190.705048 332.958649,189.600449 
   C331.975067,190.622665 330.231842,191.583252 330.143036,192.678055 
   C329.807404,196.814789 329.741058,201.022430 330.161102,205.143661 
   C330.300110,206.507309 332.210327,207.690430 333.230957,209.582962 
   C333.843170,210.411041 334.455383,211.239120 334.333496,212.023376 
   C331.696777,211.200027 329.870361,211.444412 329.974609,214.915283 
   C330.089081,218.725540 329.883911,222.547852 330.077118,226.351761 
   C330.134674,227.484802 331.064301,228.573532 331.594391,229.682556 
   C332.784668,229.109344 333.974915,228.536148 335.506439,227.224533 
M393.715607,109.751465 
   C394.477753,108.067688 395.697021,106.437286 395.901245,104.688370 
   C396.261322,101.603981 395.799103,98.430717 396.052704,95.324989 
   C396.386353,91.238091 394.711731,89.994591 391.145813,90.340416 
   C392.758209,88.513229 395.411224,86.866211 395.765289,84.821167 
   C396.491150,80.628487 396.153809,76.210922 395.864288,71.912201 
   C395.792755,70.849831 394.227692,69.888054 393.349182,68.880028 
   C392.568115,69.946053 391.184845,70.972130 391.111023,72.085075 
   C390.803802,76.715744 390.891083,81.372589 389.918579,86.011673 
   C385.980621,86.004532 382.036469,85.856392 378.106506,86.030159 
   C374.221893,86.201920 372.910919,84.450447 372.940002,80.726486 
   C372.967834,77.163895 372.435577,73.596924 371.966370,69.476471 
   C371.930023,69.284943 371.893707,69.093414 372.822235,68.959274 
   C377.444061,68.973267 382.083313,69.221756 386.678619,68.879570 
   C388.386902,68.752373 389.992188,67.241913 391.644440,66.362244 
   C389.923584,65.574341 388.254944,64.292732 386.470703,64.104073 
   C383.042511,63.741589 379.527344,64.241020 376.087738,63.936806 
   C371.847839,63.561806 370.963196,65.417267 371.165649,69.056549 
   C368.248291,68.424652 367.179138,69.896584 367.211945,72.566605 
   C367.263092,76.727654 367.340698,80.888390 367.321533,85.485481 
   C367.468048,85.761497 367.688751,85.951118 368.224213,86.691025 
   C369.483093,88.154419 370.741943,89.617813 371.307648,91.006264 
   C368.114075,89.930489 367.212585,91.687546 367.179688,94.405167 
   C367.139465,97.726067 366.827393,101.095886 367.289124,104.351471 
   C367.576080,106.374992 369.079102,108.226051 370.370636,110.475021 
   C370.607758,110.673073 370.852692,110.860947 371.335724,111.794777 
   C371.989960,113.929756 372.875793,115.385254 375.675537,115.082153 
   C378.960144,114.726578 382.334106,114.764740 385.629883,115.065186 
   C388.942932,115.367218 391.040558,114.350792 392.270538,110.745010 
   C392.514984,110.510468 392.759460,110.275925 393.715607,109.751465 
M333.395660,171.965500 
   C334.696808,172.976410 335.931183,174.735535 337.311584,174.858780 
   C341.558258,175.238037 345.914795,175.382202 350.116089,174.802307 
   C351.881470,174.558640 353.364532,172.269348 355.490967,170.389450 
   C360.836731,166.884659 360.522278,153.388733 354.684052,149.249115 
   C352.805847,148.498901 350.963470,147.235260 349.042236,147.101089 
   C344.478516,146.782349 339.877411,146.998489 335.498871,146.998489 
   C333.900299,140.549286 336.808624,134.169510 333.150269,128.373581 
   C330.166534,131.548965 328.737762,143.730316 330.687012,146.781723 
   C331.012634,147.291504 331.688965,147.577255 332.210449,148.497208 
   C332.511444,148.660126 332.812439,148.823044 332.912262,149.714279 
   C327.526123,155.738388 330.106171,162.531647 331.385376,169.449539 
   C331.640411,169.610413 331.887970,169.782059 332.548065,169.959686 
   C332.548065,169.959686 332.964813,169.907486 333.032288,170.614655 
   C333.049713,170.850800 333.067108,171.086945 333.395660,171.965500 
M371.330444,169.422379 
   C372.065765,162.941467 373.128510,156.430161 370.333618,149.633377 
   C365.532013,153.763763 367.616882,158.395279 367.198029,162.507858 
   C366.829010,166.130600 366.595093,169.878387 371.484009,170.988983 
   C372.157379,172.316376 372.762238,174.746597 373.515137,174.793365 
   C378.655334,175.112778 383.838623,175.112976 388.979004,174.774765 
   C390.162354,174.696915 391.232849,172.903778 392.355316,171.899979 
   C392.205170,171.397934 392.055023,170.895874 391.904877,170.393829 
   C385.213745,170.393829 378.522644,170.393829 371.330444,169.422379 
M335.506958,106.224190 
   C335.673706,103.252060 336.055084,100.272568 335.953522,97.309631 
   C335.823334,93.510071 337.266296,91.904747 341.179993,91.934746 
   C345.113861,91.964912 349.052368,91.395042 353.579559,90.671928 
   C359.809448,87.316978 359.242950,81.509987 358.959534,75.796547 
   C358.910065,74.798950 359.274475,73.635811 358.876038,72.832848 
   C358.195282,71.461044 357.062103,70.313766 356.118958,69.072197 
   C355.253448,70.362389 353.728149,71.609909 353.641418,72.950478 
   C353.335419,77.681190 353.522400,82.443779 353.522400,86.995438 
   C348.105774,86.995438 342.788727,86.762878 337.515167,87.139252 
   C335.963226,87.250008 334.534332,89.085083 332.297668,90.307205 
   C331.530884,92.810577 330.254395,95.284081 330.105530,97.823669 
   C329.813324,102.809448 328.949860,108.128799 333.905609,111.840393 
   C334.731628,112.459038 335.040833,113.767708 335.913818,115.344467 
   C341.649994,112.889252 349.270111,118.682251 355.458160,110.476921 
   C352.611938,110.250008 351.029358,110.036308 349.444183,110.014671 
   C345.446564,109.960098 341.434570,110.161484 337.460083,109.852097 
   C336.625397,109.787125 335.927032,107.971542 335.506958,106.224190 
M367.249176,192.862961 
   C367.302307,197.257462 367.355469,201.651978 367.325317,206.473938 
   C367.480896,206.737137 367.704803,206.913696 368.247131,207.658218 
   C371.316620,211.854614 375.093567,214.035370 380.609131,213.102814 
   C383.917328,212.543503 387.397339,213.000092 391.003052,213.000092 
   C391.003052,217.927078 390.823639,222.078995 391.109161,226.198685 
   C391.198181,227.482941 392.544250,228.680084 393.314850,229.917114 
   C394.209259,228.625412 395.727386,227.400986 395.875397,226.028778 
   C396.246979,222.583954 395.775543,219.053955 396.060211,215.592468 
   C396.365753,211.876968 394.520294,211.170731 391.761261,211.018539 
   C390.832977,209.615799 389.552246,208.183121 388.200836,208.113007 
   C382.957031,207.840988 377.690735,208.002289 371.997314,208.002289 
   C371.997314,202.507126 372.116821,197.562775 371.952362,192.627869 
   C371.854126,189.680084 370.838013,187.878479 367.598480,191.162872 
   C367.293549,191.361557 367.230225,191.619522 367.249176,192.862961 
M373.626740,235.741440 
   C378.282532,235.778381 382.963959,236.101852 387.579620,235.689529 
   C389.099213,235.553802 390.448730,233.514587 391.876007,232.345734 
   C390.465027,231.550339 389.099335,230.185699 387.634338,230.069183 
   C383.668152,229.753769 379.659454,229.939224 375.668213,229.980438 
   C370.819427,230.030487 370.372925,230.944870 373.626740,235.741440 
M338.951111,235.862640 
   C344.203033,234.880875 350.535126,238.581345 354.555878,232.262711 
   C350.816162,228.757782 336.748657,228.954742 333.128418,232.854294 
   C334.824402,233.883987 336.451019,234.871567 338.951111,235.862640 
M352.473114,64.141449 
   C347.358276,64.095169 342.237579,63.905128 337.133118,64.113487 
   C335.994843,64.159950 334.913666,65.606598 333.806213,66.408577 
   C334.815826,67.268448 335.787109,68.811646 336.841675,68.870865 
   C341.614044,69.138802 346.426605,69.220749 351.189941,68.881500 
   C353.338379,68.728500 356.100250,67.736084 352.473114,64.141449 
M320.888489,191.026245 
   C317.737152,189.023682 316.022705,189.749069 316.004395,193.620789 
   C315.987915,197.108139 315.753174,200.620575 316.102142,204.072662 
   C316.279419,205.826279 317.530640,207.471329 318.296387,209.165466 
   C322.313202,203.348923 320.416809,197.520340 320.888489,191.026245 
M317.855377,87.593079 
   C318.897766,86.778481 320.795166,86.010201 320.842224,85.141670 
   C321.124847,79.924911 320.982513,74.685135 320.982513,67.842171 
   C318.579193,69.436951 316.237000,70.277321 316.241821,71.103966 
   C316.272919,76.420654 316.817902,81.734352 317.855377,87.593079 
M390.999939,164.196289 
   C391.000366,164.694153 390.822845,165.289780 391.033051,165.672333 
   C391.795441,167.059525 392.687805,168.375259 393.531128,169.717957 
   C394.353729,168.350586 395.704956,167.050049 395.888000,165.601837 
   C396.259674,162.661591 396.183167,159.626419 395.939545,156.659256 
   C395.758423,154.453613 395.023224,152.293457 394.326233,149.181885 
   C389.153839,154.007248 391.748901,158.956802 390.999939,164.196289 
M316.050476,93.002678 
   C317.001038,98.580383 313.594635,104.971573 319.390015,109.789772 
   C321.910645,103.684120 320.610413,97.417229 321.050659,90.492165 
   C318.907043,91.226067 317.592072,91.676262 316.050476,93.002678 
M371.885437,146.491425 
   C371.493591,140.456451 373.568542,134.120163 370.030334,127.747513 
   C365.293640,134.040161 367.547699,140.407578 367.619324,146.459686 
   C367.624359,146.884308 369.525543,150.097855 371.885437,146.491425 
M393.467438,148.345734 
   C397.072021,145.094025 397.148590,132.706329 392.758270,128.220886 
   C392.056793,130.616089 390.987671,132.461502 391.101074,134.231171 
   C391.395081,138.820419 392.209564,143.376328 393.467438,148.345734 
M353.592743,132.976425 
   C353.587799,136.939438 353.406708,140.915375 353.695740,144.856842 
   C353.772614,145.905533 355.291138,146.848526 356.147766,147.840073 
   C357.078705,146.902863 358.739685,146.019043 358.812836,145.019104 
   C359.113037,140.913467 359.113495,136.758896 358.809631,132.653015 
   C358.721771,131.465698 357.249451,130.380814 356.411835,129.248978 
   C355.489471,130.197418 354.567139,131.145859 353.592743,132.976425 
M315.995667,222.345963 
   C315.999237,223.679153 315.658356,225.141632 316.097443,226.311310 
   C316.595856,227.639038 317.792236,228.704742 318.685211,229.884354 
   C319.457275,228.653198 320.795288,227.466019 320.894989,226.182632 
   C321.191040,222.372742 321.168854,218.512680 320.892639,214.699646 
   C320.812195,213.589142 319.462128,212.570602 318.693970,211.509903 
   C317.796509,212.641388 316.382263,213.665024 316.118500,214.928787 
   C315.685089,217.005188 315.997650,219.237320 315.995667,222.345963 
M372.751923,128.697784 
   C379.230408,128.336121 385.947113,130.056870 392.146606,126.658318 
   C385.543213,124.374176 378.882874,124.817398 372.233826,125.510765 
   C372.087524,125.526016 372.129028,127.342201 372.751923,128.697784 
M342.597290,124.965515 
   C339.571655,125.222588 336.546021,125.479660 332.498077,125.823586 
   C337.189331,129.712128 350.814514,129.707428 354.787903,125.483604 
   C351.038605,125.310905 347.289337,125.138206 342.597290,124.965515 
M343.528046,189.406754 
   C347.049500,188.754028 351.368622,191.110062 353.980347,186.005554 
   C347.819244,185.220490 341.698730,185.178757 335.581665,185.303543 
   C335.116211,185.313019 334.191650,186.645035 334.329224,187.112564 
   C334.569305,187.928589 335.395508,189.053314 336.107910,189.158844 
   C338.230865,189.473312 340.417786,189.355667 343.528046,189.406754 
M391.437958,187.560471 
   C390.963898,186.755783 390.513306,185.268387 390.012177,185.251175 
   C384.525574,185.062622 379.029602,185.039749 373.540741,185.167648 
   C372.896820,185.182678 371.655945,186.554443 371.780182,186.978043 
   C372.043365,187.875427 373.041443,189.244019 373.709656,189.228058 
   C379.450073,189.090897 385.184174,188.687866 391.437958,187.560471 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M453.409607,259.654602 
   C453.015289,191.200745 453.008789,122.568558 452.998047,53.936359 
   C452.996826,46.011887 452.985962,46.002556 444.924774,46.001980 
   C400.447083,45.998791 355.969421,45.997459 311.491730,46.003582 
   C304.022369,46.004612 304.004272,46.022896 304.003235,53.520351 
   C303.993805,121.985939 303.991150,190.451523 303.981659,259.375061 
   C303.520508,257.914246 302.667236,255.995926 302.665283,254.076721 
   C302.596405,187.294464 302.607727,120.512123 302.607697,53.729794 
   C302.607697,50.941509 302.607727,48.153225 302.607727,45.186852 
   C352.860260,45.186852 403.102692,45.186852 453.660767,45.186852 
   C453.781128,46.610741 453.982330,47.882000 453.982605,49.153305 
   C453.997681,118.267181 453.996765,187.381058 453.987610,256.494934 
   C453.987488,257.488708 453.862701,258.482513 453.409607,259.654602 
z"
      />
      <g>
        <text
          dz="M260.88"
          transform="translate(330 100)"
          style={{
            fill: "black",
            fontSize: "48px",
            fontFamily: "Nunito-SemiBold, Nunito",
            fontWeight: "600",
          }}
        >
          <tspan>{props.value.systolic}</tspan>
        </text>
        <text
          dz="M260.88"
          transform="translate(330 170)"
          style={{
            fill: "black",
            fontSize: "48px",
            fontFamily: "Nunito-SemiBold, Nunito",
            fontWeight: "600",
          }}
        >
          <tspan>{props.value.diastolic}</tspan>
        </text>
        <text
          dz="M260.88"
          transform="translate(330 230)"
          style={{
            fill: "black",
            fontSize: "48px",
            fontFamily: "Nunito-SemiBold, Nunito",
            fontWeight: "600",
          }}
        >
          <tspan>{props.value.pulse}</tspan>
        </text>
      </g>
      <path
        fill="#667B8C"
        opacity="1.000000"
        stroke="none"
        d="
M223.126312,149.992462 
   C223.126328,145.548645 223.126328,141.597443 223.126328,137.220947 
   C230.496185,137.211548 237.964966,134.786575 243.148987,141.670227 
   C246.428589,146.025085 246.304001,151.561340 243.540741,155.738922 
   C238.937531,162.698227 231.186935,162.079834 223.126312,161.948151 
   C223.126312,157.990311 223.126312,154.237701 223.126312,149.992462 
M227.500229,140.883682 
   C227.378067,141.241974 227.255905,141.600266 226.989075,142.883728 
   C227.037292,147.262009 227.085510,151.640305 227.332993,156.808487 
   C228.924362,156.922897 230.515747,157.037292 232.883575,157.185989 
   C236.738342,156.501419 240.406601,155.527740 240.978516,150.765198 
   C241.552368,145.986755 240.387939,142.235825 234.499130,141.119202 
   C234.499130,141.119202 234.050888,141.178299 233.212189,140.981735 
   C231.505875,140.939224 229.799561,140.896713 227.500229,140.883682 
z"
      />
      <path
        fill="#607587"
        opacity="1.000000"
        stroke="none"
        d="
M280.290771,80.393028 
   C277.922485,80.085602 275.856171,79.200874 273.788849,79.198532 
   C272.446594,79.197006 271.102905,80.482300 269.759857,81.191025 
   C270.516205,82.490356 270.964600,84.329857 272.094482,84.974030 
   C274.292969,86.227409 277.232086,86.335938 279.188293,87.813721 
   C280.914459,89.117760 282.947876,91.872925 282.626404,93.566017 
   C282.211639,95.750877 279.824127,98.825790 277.864716,99.178871 
   C274.239380,99.832176 270.284851,98.926399 266.526947,98.308159 
   C265.913879,98.207306 265.606110,96.250801 265.157227,95.151962 
   C266.274414,94.951942 267.441193,94.441757 268.499298,94.611076 
   C271.464905,95.085663 274.385620,95.840736 277.735138,96.575294 
   C277.735138,94.244965 277.735138,92.832932 277.735138,91.487907 
   C275.207336,90.486740 272.749176,89.588493 270.354248,88.545174 
   C267.490021,87.297432 264.678741,86.222511 264.884399,82.027512 
   C265.094177,77.748901 268.127594,75.798347 271.128754,75.393181 
   C274.290619,74.966324 277.730530,76.525780 281.007477,77.376953 
   C281.106018,77.402550 280.741150,79.212196 280.290771,80.393028 
z"
      />
      <path
        fill="#64798A"
        opacity="1.000000"
        stroke="none"
        d="
M261.940918,219.005005 
   C256.662018,224.077148 246.933533,222.716019 245.046997,217.200836 
   C244.280960,214.961380 244.145874,212.451385 244.046616,210.051147 
   C243.889709,206.257156 244.004822,202.451920 244.004822,198.651443 
   C244.422073,198.336044 244.839325,198.020645 245.256561,197.705246 
   C246.166306,198.755203 247.750443,199.738831 247.861420,200.867340 
   C248.217560,204.488937 247.902725,208.170410 248.085068,211.816864 
   C248.279221,215.699783 250.130447,218.600220 254.348770,218.314194 
   C258.343842,218.043289 260.415680,215.690323 260.074402,211.287567 
   C259.755341,207.171783 260.006165,203.011841 260.006165,198.438522 
   C261.575867,198.314255 262.922302,198.207657 264.821503,198.057281 
   C264.338654,205.291672 266.575165,212.338348 261.940918,219.005005 
z"
      />
      <path
        fill="#5F7486"
        opacity="1.000000"
        stroke="none"
        d="
M227.599289,95.915604 
   C230.155869,95.972000 232.285507,95.972000 234.498886,95.972000 
   C235.203735,93.195801 235.747421,91.165085 232.144272,90.668640 
   C230.404388,90.428909 228.845367,89.082161 227.103149,88.612106 
   C223.096283,87.531044 221.553299,85.180305 221.970886,80.995888 
   C222.380295,76.893181 225.570831,75.625862 228.395355,75.238388 
   C231.099976,74.867378 234.086823,76.233383 236.874588,77.082329 
   C237.379593,77.236122 237.946976,78.751587 237.714005,79.324890 
   C237.477570,79.906715 236.253082,80.454338 235.520096,80.386040 
   C234.231476,80.265991 232.926559,79.210106 231.738968,79.369461 
   C229.821762,79.626724 227.994125,80.551521 226.128662,81.194382 
   C227.423721,82.694908 228.480545,84.564941 230.078735,85.595337 
   C231.785156,86.695526 234.413422,86.522728 235.888077,87.783943 
   C237.549255,89.204674 239.599365,91.856255 239.259705,93.518776 
   C238.799789,95.769806 236.494064,98.898430 234.501907,99.304581 
   C231.009125,100.016678 227.107040,99.175201 223.483490,98.449707 
   C222.652161,98.283264 222.255219,95.947205 221.658371,94.609650 
   C222.094086,94.287239 222.529800,93.964828 222.965515,93.642418 
   C224.367798,94.381355 225.770081,95.120285 227.599289,95.915604 
z"
      />
      <path
        fill="#62788A"
        opacity="1.000000"
        stroke="none"
        d="
M264.553772,157.265900 
   C262.915558,158.882462 261.483856,160.146683 260.052124,161.410904 
   C259.574188,160.996170 259.096222,160.581436 258.618286,160.166687 
   C261.938660,152.593033 265.259003,145.019394 268.591187,137.418808 
   C272.256042,136.023392 274.134399,137.958969 275.432556,141.053238 
   C278.142303,147.512100 280.872772,153.962250 283.683960,160.627487 
   C280.714294,161.379837 278.286560,161.369614 277.961121,157.274216 
   C277.915161,156.696136 277.482666,156.014725 277.021973,155.627502 
   C275.290588,154.172302 266.602448,155.134842 264.553772,157.265900 
M271.213989,153.031342 
   C272.676147,152.638275 274.138336,152.245209 275.585419,151.856186 
   C273.940735,148.321320 272.520294,145.268356 270.766754,141.499527 
   C269.275360,145.743347 268.089233,149.118454 266.713928,153.031876 
   C268.442474,153.031876 269.425171,153.031876 271.213989,153.031342 
z"
      />
      <path
        fill="#65798A"
        opacity="1.000000"
        stroke="none"
        d="
M220.123398,221.033844 
   C220.073441,213.986435 220.023483,206.939041 220.198593,199.305542 
   C220.617432,198.550293 220.794495,198.365173 220.954819,198.164047 
   C224.105576,198.094040 227.256012,197.991806 230.407150,197.961349 
   C235.585052,197.911331 238.433365,200.638443 238.914978,206.041855 
   C239.345825,210.875732 237.334778,213.192932 231.950348,214.121719 
   C229.694016,214.510925 227.448212,214.961136 225.407791,215.344147 
   C223.508759,217.332077 227.396805,222.155060 221.245178,221.748016 
   C220.527191,221.353714 220.332932,221.184982 220.123398,221.033844 
M225.410751,211.625320 
   C226.718262,211.672440 228.361786,212.242569 229.265839,211.661713 
   C231.381058,210.302734 234.290039,208.676270 234.794083,206.641876 
   C235.697159,202.997040 232.249939,201.812943 229.256866,201.183929 
   C227.912064,200.901306 226.458527,201.136078 224.927521,201.136078 
   C224.927521,204.838760 224.927521,207.939606 225.410751,211.625320 
z"
      />
      <path
        fill="#607587"
        opacity="1.000000"
        stroke="none"
        d="
M253.967255,92.414597 
   C253.963440,95.128136 253.963440,97.411987 253.963440,101.246094 
   C251.955017,99.406143 250.126892,98.430779 250.223022,97.713211 
   C251.424835,88.742355 244.353516,83.239044 241.272049,75.689445 
   C248.784683,75.095421 247.657410,83.442009 252.099777,85.745758 
   C256.562927,83.671173 254.932068,75.254501 262.834808,75.655457 
   C259.709320,81.413483 256.840179,86.699196 253.967255,92.414597 
z"
      />
      <path
        fill="#637889"
        opacity="1.000000"
        stroke="none"
        d="
M271.968842,198.202621 
   C272.990082,199.506302 274.798340,200.755905 274.892273,202.123291 
   C275.243195,207.232269 275.032562,212.379822 275.032562,218.059967 
   C278.857391,218.059967 282.269836,218.059967 285.573730,218.059967 
   C286.430878,221.481094 285.655823,223.159012 282.367981,223.033493 
   C280.205658,222.950958 278.039032,222.981293 275.262085,222.583710 
   C273.766815,220.013260 272.265045,217.859833 272.121399,215.619308 
   C271.750458,209.834274 271.979462,204.010757 271.968842,198.202621 
z"
      />
      <path
        fill="#708A9D"
        opacity="1.000000"
        stroke="none"
        d="
M254.633194,154.768738 
   C254.621857,156.231430 254.870972,157.329071 254.531860,158.196884 
   C254.137955,159.205002 253.228195,160.011566 252.542328,160.905624 
   C251.995560,159.911621 251.000793,158.928772 250.975693,157.921768 
   C250.826767,151.948425 250.825134,145.967484 250.980011,139.994431 
   C251.005386,139.014877 252.051849,138.061798 252.626373,137.096481 
   C253.285980,138.016922 254.475922,138.914948 254.515930,139.861557 
   C254.719086,144.668137 254.624100,149.487305 254.633194,154.768738 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M271.544037,198.314468 
   C271.979462,204.010757 271.750458,209.834274 272.121399,215.619308 
   C272.265045,217.859833 273.766815,220.013260 274.810669,222.518661 
   C272.748138,223.139999 270.560028,223.472229 270.648163,220.072433 
   C270.835144,212.857834 270.965607,205.641769 271.544037,198.314468 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M220.121735,221.273636 
   C220.332932,221.184982 220.527191,221.353714 220.827637,221.690186 
   C220.949142,221.840347 220.469559,221.841888 220.469559,221.841888 
   C220.469559,221.841888 220.120087,221.513428 220.121735,221.273636 
z"
      />
      <path
        fill="#4E667B"
        opacity="1.000000"
        stroke="none"
        d="
M220.712494,198.148682 
   C220.794495,198.365173 220.617432,198.550293 220.273529,198.839661 
   C220.008972,198.587875 220.124557,198.312363 220.712494,198.148682 
z"
      />

      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M227.233368,156.413544 
   C228.583679,156.262863 230.033646,156.507141 231.795349,156.951569 
   C230.515747,157.037292 228.924362,156.922897 227.233368,156.413544 
z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M227.796738,140.868942 
   C227.883469,141.103256 227.677536,141.355469 227.304596,141.784668 
   C227.255905,141.600266 227.378067,141.241974 227.796738,140.868942 
z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="
M234.714294,141.188538 
   C234.929443,141.257874 234.593353,141.327362 234.593353,141.327362 
   C234.593353,141.327362 234.262207,141.271637 234.156555,141.224976 
   C234.050888,141.178299 234.499130,141.119202 234.714294,141.188538 
z"
      />
      <path
        fill="#F5F8F9"
        opacity="1.000000"
        stroke="none"
        d="
M270.810913,153.031616 
   C269.425171,153.031876 268.442474,153.031876 266.713928,153.031876 
   C268.089233,149.118454 269.275360,145.743347 270.766754,141.499527 
   C272.520294,145.268356 273.940735,148.321320 275.585419,151.856186 
   C274.138336,152.245209 272.676147,152.638275 270.810913,153.031616 
z"
      />
      <path
        fill="#E9EFF3"
        opacity="1.000000"
        stroke="none"
        d="
M225.169128,211.332886 
   C224.927521,207.939606 224.927521,204.838760 224.927521,201.136078 
   C226.458527,201.136078 227.912064,200.901306 229.256866,201.183929 
   C232.249939,201.812943 235.697159,202.997040 234.794083,206.641876 
   C234.290039,208.676270 231.381058,210.302734 229.265839,211.661713 
   C228.361786,212.242569 226.718262,211.672440 225.169128,211.332886 
z"
      />

    </svg>
  );
};

export default BloodPressureDeviceIcon;
