import { Col, Row } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { vitalsActionStore } from "../../../dataLayer/stores/actions/vitalsActionStore";
import { profileStore } from "../../../dataLayer/stores/pages/profileStore";
import VitalInfoCard from "../../VitalInfoCard";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};

const vitalsArray = [
  {
    name: "bloodPressure",
    displayName: "Blood Pressure",
  },
  {
    name: "bloodGlucose",
    displayName: "Blood Glucose",
  },
  {
    name: "temperature",
    displayName: "Temperature",
  },
  {
    name: "weight",
    displayName: "Weight",
  },
  {
    name: "oxygenLevels",
    displayName: "Oxygen Levels",
  },
];

function MonitorVitals() {
  let history = useHistory();

  return (
    <Row>
      <Col span={22}>
        <div style={box}>
          <Row gutter={[32, 32]}>
            {vitalsArray.map((vital) => (
              <VitalInfoCard
                vitalKey={vital.name}
                onClick={(key) => {
                  vitalsActionStore.setVital(key);
                }}
                data={profileStore.comp3Data}
              />
            ))}
          </Row>
        </div>
      </Col>
    </Row>
  );
}

export default MonitorVitals;
