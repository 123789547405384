import { Row } from "antd";
import React from "react";
import Pills from "../screens/pills/Pills";

function PillsRoute() {
  return (
    <Row gutter={[32, 32]}>
      <Pills />
    </Row>
  );
}

export default PillsRoute;
