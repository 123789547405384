import { Col, Row, Layout } from "antd";
import { useHistory } from "react-router-dom";
import ComplianceCard from "../ComplianceCard";
import DoseCard from "../DoseCard";
import VitalsButton from "../VitalsButton";
import { homeStore } from "../../dataLayer/stores/pages/homeStore";
import { observer } from "mobx-react";
import ContactTechnicianCard from "../screens/home/ContactTechnicianCard";
import ChatWithPharmacyCard from "../screens/home/ChatWithPharmacyCard";
// import ChatIcon from "../reusable/icons/ChatIcon";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Home = observer(({ props }) => {
  let history = useHistory();
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row>
            <p
              className="m-2"
              style={{
                fontFamily: "Nunito-SemiBold",
                fontSize: "28px",
                color: "#272D5C",
              }}
            >
              Overview
            </p>
          </Row>
          <Row>
            <Col span={8}>
              <div style={box}>
                <ComplianceCard data={homeStore.comp1Data} />
              </div>
            </Col>
            <Col span={16}>
              <Row>
                <Col span={24}>
                  <div style={box}>
                    <VitalsButton
                      onClick={() => {
                        history.push(`/vitals`);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div style={box}>
                    <ChatWithPharmacyCard
                      onClick={() => {
                        history.push(`/messages`);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <div style={box}>
            <DoseCard data={homeStore.comp3Data} />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <ContactTechnicianCard />
            {/* <RefillCard data={homeStore.comp4Data} /> */}
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Home;
