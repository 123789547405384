import { inject, observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import VitalIconBp from "../assets/images/vitals-icon-bp@2x.png";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import { vitalsActionStore } from "../dataLayer/stores/actions/vitalsActionStore";
const VitalInfoCard = observer(({ data, vitalKey, onClick }) => {
  const history = useHistory();

  var metaData = {
    vitalMetadata: {
      bloodPressure: {
        name: "Blood Pressure",
        units: "cmHg",
      },
      bloodGlucose: {
        name: "Blood Glucose",
        units: "mgdl",
      },
      temperature: {
        name: "Temperature",
        units: "F",
      },
      weight: {
        name: "Weight",
        units: "lbs",
      },
      oxygenLevels: {
        name: "Oxygen Levels",
        units: "mmHg",
      },
    },
  };

  const styles = {
    vitalText: {
      fontFamily: "Nunito-SemiBold",
      fontSize: 21,
      color: "white",
    },
    vitalValue: {
      fontFamily: "Nunito-SemiBold",
      fontSize: 36,
      color: "white",
    },
    bloodPressure: {
      backgroundImage: "linear-gradient(to top right, #FE8B6E , #FF6773)",
    },
    bloodGlucose: {
      backgroundImage: "linear-gradient(to top right, #9F64A8 , #6F66D1)",
    },
    temperature: {
      backgroundImage: "linear-gradient(to top right, #61C2D2 , #0B82BA)",
    },
    weight: {
      backgroundImage: "linear-gradient(to top right, #9692E9 , #574FFA)",
    },
    oxygenLevels: {
      backgroundImage: "linear-gradient(to top right, #FFB75E , #ED8F03)",
    },
  };

  const getHelpfulTip = useCallback(() => {
    switch (vitalKey) {
      case "bloodPressure":
        return "120|80 mmHg is Ideal Blood Pressure";
      case "bloodGlucose":
        return "80-130 mgdl is Ideal Blood Glucose";
      case "temperature":
        return "97F is Ideal Body Temperature";
      case "weight":
        return "Ideal body weight";
      case "oxygenLevels":
        return "75-100 mmHg is Ideal Oxygen Level";
      default:
        return "";
    }
  }, [vitalKey]);

  return (
    <Col span={7} offset={0.5}>
      <Card
        draggable={true}
        className="px-2"
        style={{
          ...{
            borderRadius: 30,
            boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
            border: "none",
            cursor: "pointer",
            height: "100%",
          },
          ...styles[vitalKey],
        }}
        // onClick={() => onClick(vitalKey)}
      >
        <Card.Body>
          <div className={" mx-0 mb-1 mb-lg-3 justify-content-between"}>
            <Image height={50} src={VitalIconBp} />
            <Button
              className={"px-3"}
              style={{
                height: 30,
                borderRadius: 20,
                fontFamily: "Nunito-Regular",
                fontSize: 14,
                float: "right",
              }}
              onClick={() => {
                vitalsActionStore.setVital(vitalKey);
                history.push("/vitals/record");
              }}
            >
              Record
            </Button>
          </div>
          <div className="mb-2" style={styles.vitalText}>
            {metaData.vitalMetadata[vitalKey].name}
          </div>
          <div className="mb-2" style={styles.vitalValue}>
            {vitalKey === "bloodPressure"
              ? `${data?.vitalsAverage?.bloodPressure?.systolic || 0}|${
                  data?.vitalsAverage?.bloodPressure?.diastolic || 0
                }`
              : data?.vitalsAverage?.[vitalKey]?.value || 0}
          </div>
          <div className="mb-2" style={styles.vitalText}>
            {metaData.vitalMetadata[vitalKey].units}
          </div>
          <div className="mt-4" style={styles.vitalText}>
            {getHelpfulTip()}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
});

export default VitalInfoCard;
