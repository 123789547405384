import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Layout,
  Modal,
  Divider,
  Button,
  Checkbox,
  Image,
  Typography,
  Space,
  Skeleton,
} from "antd";
import { observer } from "mobx-react";
import _ from "lodash";
import moment from "moment";
import { prescriptionsStore } from "../../../dataLayer/stores/pages/prescriptionsStore";

const { Title } = Typography;

const UpdatePillsStatus = observer((props) => {
  const active = moment(
    `${props.selectedPouch.date} ${props.selectedPouch.time}`
  ).isBefore(moment());

  const [noDisabled, setnoDisabled] = useState(false);

  const updateNoStatus = () => {
    setnoDisabled(
      _.filter(props.rxarr, { selected: true }).length < props.rxarr.length
    );
  };

  useEffect(() => {
    updateNoStatus();
    getSignedUrl(props.selectedPouch.imagePath, props.selectedPouch.pouchId);
  }, []);

  function getSignedUrl(imagePath, pouchGuid) {
    prescriptionsStore.getRxSafeImageURLs({
      imagePath,
      pouchGuid,
    });
  }

  function getPillAdministrationTime(date, time) {
    let pillAdministeredDateTime = date + " " + ("0" + time).slice(-5);
    return moment(pillAdministeredDateTime).utc(true).format("hh:mm A");
  }

  function getDoseStatusTitle() {
    let pouchAdministrationTime = getPillAdministrationTime(
      props.selectedPouch.date,
      props.selectedPouch.time
    );
    return active
      ? `Did you take your 
        ${pouchAdministrationTime} 
        Dose?`
      : `Your future dose at 
        ${pouchAdministrationTime} 
        on ${moment.utc(props.selectedDate).format("MMM DD, YYYY")}`;
  }

  return (
    <Modal
      title={getDoseStatusTitle()}
      centered={true}
      mask={true}
      visible={props.visible}
      onCancel={() => props.onCancel()}
      width={800}
      footer={null}
    >
      {prescriptionsStore.loadingPillPouchImages ? (
        <Skeleton active={prescriptionsStore.loadingPillPouchImages} />
      ) : (
        <Space direction="vertical">
          <Row>
            <Col span={24}>
              <Image.PreviewGroup>
                <Row justify="center" align="middle">
                  <Col span={12}>
                    <Image
                      width={"90%"}
                      src={
                        prescriptionsStore.selectedPillPouchImages
                          .pouchTextImage
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Image
                      width={"95%"}
                      src={
                        prescriptionsStore.selectedPillPouchImages
                          .pouchFrontColorImage
                      }
                    />
                  </Col>
                </Row>
              </Image.PreviewGroup>
            </Col>
          </Row>
          {active && (
            <Row justify="center" align="middle">
              <Col span={12} offset={6}>
                <Space>
                  <Button
                    size="large"
                    style={{
                      width: 100,
                      color: "#fff",
                      backgroundColor: active ? "#23d098" : "grey",
                      borderColor: active ? "#23d098" : "grey",
                      borderRadius: "20px",
                    }}
                    onClick={props.onYes}
                  >
                    Yes
                  </Button>
                  <Button
                    size="large"
                    style={{
                      width: 100,
                      color: "#fff",
                      backgroundColor:
                        noDisabled || !active ? "#fd532f" : "grey",
                      borderColor: noDisabled || !active ? "#fd532f" : "grey",
                      borderRadius: "20px",
                    }}
                    onClick={props.onNo}
                  >
                    No
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
        </Space>
      )}
    </Modal>
  );
});

export default UpdatePillsStatus;
