import React from "react";

const WeightDeviceIcon = (props) => {
  return (
    <svg
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 512 512"
      enable-background="new 0 0 512 512"
    >
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M1.000000,196.000000 
	C1.000000,130.666672 1.000000,65.833336 1.000000,1.000000 
	C171.666672,1.000000 342.333344,1.000000 513.000000,1.000000 
	C513.000000,171.666672 513.000000,342.333344 513.000000,513.000000 
	C342.333344,513.000000 171.666672,513.000000 1.000000,513.000000 
	C1.000000,407.500000 1.000000,302.000000 1.000000,196.000000 
M396.500000,24.999989 
	C291.692749,24.999969 186.885513,24.999470 82.078278,25.004749 
	C80.585579,25.004824 79.035873,24.827868 77.608894,25.154022 
	C61.310959,28.879148 47.344486,36.425076 37.306217,50.211811 
	C29.821285,60.491753 24.993391,71.736305 24.994455,84.715233 
	C25.003935,200.352936 24.999880,315.990631 25.000839,431.628326 
	C25.000853,433.126251 24.740088,434.686249 25.063246,436.112579 
	C28.832569,452.749268 36.537189,467.062317 50.707470,477.089996 
	C61.005695,484.377533 72.304695,489.052460 85.321655,489.043945 
	C200.792679,488.968384 316.263763,489.000214 431.734833,488.997681 
	C433.230865,488.997620 434.786652,489.226898 436.213776,488.903870 
	C452.674896,485.178314 466.760925,477.539307 476.851654,463.609436 
	C484.294098,453.335358 489.016449,442.076172 489.013763,429.116211 
	C488.990173,315.478027 488.940826,201.839752 489.090607,88.201752 
	C489.105774,76.683273 486.688477,66.062843 480.702881,56.348881 
	C468.519379,36.576435 451.163422,25.331343 427.492218,25.021774 
	C417.496429,24.891052 407.497498,24.999989 396.500000,24.999989 
z"
      />
      <path
        fill="#FDC5CA"
        opacity="1.000000"
        stroke="none"
        d="
M397.000000,24.999989 
	C407.497498,24.999989 417.496429,24.891052 427.492218,25.021774 
	C451.163422,25.331343 468.519379,36.576435 480.702881,56.348881 
	C486.688477,66.062843 489.105774,76.683273 489.090607,88.201752 
	C488.940826,201.839752 488.990173,315.478027 489.013763,429.116211 
	C489.016449,442.076172 484.294098,453.335358 476.851654,463.609436 
	C466.760925,477.539307 452.674896,485.178314 436.213776,488.903870 
	C434.786652,489.226898 433.230865,488.997620 431.734833,488.997681 
	C316.263763,489.000214 200.792679,488.968384 85.321655,489.043945 
	C72.304695,489.052460 61.005695,484.377533 50.707470,477.089996 
	C36.537189,467.062317 28.832569,452.749268 25.063246,436.112579 
	C24.740088,434.686249 25.000853,433.126251 25.000839,431.628326 
	C24.999880,315.990631 25.003935,200.352936 24.994455,84.715233 
	C24.993391,71.736305 29.821285,60.491753 37.306217,50.211811 
	C47.344486,36.425076 61.310959,28.879148 77.608894,25.154022 
	C79.035873,24.827868 80.585579,25.004824 82.078278,25.004749 
	C186.885513,24.999470 291.692749,24.999969 397.000000,24.999989 
M408.979706,363.970795 
	C408.967560,329.679504 408.955414,295.388214 409.364655,260.566345 
	C431.740234,246.390533 428.590546,216.608978 409.278961,205.445633 
	C397.861053,198.845337 384.748474,199.485962 372.830963,208.105591 
	C363.873566,214.584213 359.112061,228.439163 361.746460,239.825226 
	C363.891022,249.093994 369.281067,255.926804 377.019348,262.015839 
	C377.033936,296.311188 377.048492,330.606567 376.642487,365.434326 
	C354.494873,379.125854 356.905518,409.525787 377.134918,420.727112 
	C389.756256,427.715668 401.725861,426.082245 413.143646,417.897125 
	C422.049408,411.512817 426.869904,397.421326 424.249115,386.164795 
	C422.093750,376.907410 416.711304,370.065643 408.979706,363.970795 
M136.979721,363.970795 
	C136.967621,329.679535 136.955536,295.388306 137.364685,260.566376 
	C159.741211,246.389618 156.589478,216.608200 137.278961,205.445648 
	C125.860863,198.845352 112.748489,199.485962 100.830956,208.105621 
	C91.873474,214.584351 87.112137,228.438889 89.746490,239.825226 
	C91.890938,249.094116 97.281158,255.926743 105.019379,262.015839 
	C105.033943,296.311188 105.048508,330.606567 104.642517,365.434296 
	C82.495155,379.125641 84.905083,409.525421 105.134850,420.727112 
	C117.756119,427.715729 129.725830,426.082367 141.143600,417.897095 
	C150.049576,411.512512 154.870041,397.421356 152.249146,386.164795 
	C150.093750,376.907440 144.711304,370.065643 136.979721,363.970795 
M356.234283,182.940765 
	C370.015045,177.135559 376.904449,166.668076 376.975098,151.802673 
	C377.072479,131.305725 377.040314,110.807854 376.987946,90.310577 
	C376.937805,70.671669 363.281799,57.023251 343.621094,57.011993 
	C285.795044,56.978886 227.968979,56.974468 170.142960,57.014915 
	C150.805359,57.028442 137.070221,70.749596 137.014191,90.042404 
	C136.954193,110.706291 136.950729,131.370636 137.015305,152.034500 
	C137.075470,171.284500 150.848831,184.971802 170.229218,184.985214 
	C228.055237,185.025253 285.881348,185.035004 343.707184,184.921402 
	C347.632690,184.913696 351.556000,183.785553 356.234283,182.940765 
M233.010101,446.241577 
	C232.774521,449.478241 235.115860,448.988770 237.049347,448.990784 
	C250.524765,449.005005 264.000214,449.009094 277.475555,448.974335 
	C278.568970,448.971527 279.661591,448.654785 280.657928,448.499054 
	C280.657928,443.254333 280.657928,438.344940 280.657928,433.423859 
	C264.631927,433.423859 248.951874,433.423859 233.001770,433.423859 
	C233.001770,437.660980 233.001770,441.476135 233.010101,446.241577 
z"
      />
      <path
        fill="#68737F"
        opacity="1.000000"
        stroke="none"
        d="
M355.857300,183.059631 
	C351.556000,183.785553 347.632690,184.913696 343.707184,184.921402 
	C285.881348,185.035004 228.055237,185.025253 170.229218,184.985214 
	C150.848831,184.971802 137.075470,171.284500 137.015305,152.034500 
	C136.950729,131.370636 136.954193,110.706291 137.014191,90.042404 
	C137.070221,70.749596 150.805359,57.028442 170.142960,57.014915 
	C227.968979,56.974468 285.795044,56.978886 343.621094,57.011993 
	C363.281799,57.023251 376.937805,70.671669 376.987946,90.310577 
	C377.040314,110.807854 377.072479,131.305725 376.975098,151.802673 
	C376.904449,166.668076 370.015045,177.135559 355.857300,183.059631 
M345.000000,123.500000 
	C344.999786,113.669395 344.829407,103.834404 345.082581,94.010330 
	C345.184845,90.041313 343.911224,88.915749 339.977783,88.923798 
	C284.659912,89.037018 229.341675,89.036926 174.023804,88.923973 
	C170.093063,88.915947 168.875351,90.042648 168.917664,94.007477 
	C169.107895,111.833870 169.142746,129.665375 168.899719,147.490494 
	C168.839386,151.916550 170.120911,153.138779 174.522736,153.098038 
	C197.347809,152.886719 220.175919,153.000031 243.002960,152.999969 
	C275.327393,152.999878 307.652100,152.938919 339.975922,153.077988 
	C343.907684,153.094894 345.222107,151.966125 345.081085,147.990631 
	C344.803741,140.169296 344.999817,132.331177 345.000000,123.500000 
z"
      />
      <path
        fill="#D4E4F0"
        opacity="1.000000"
        stroke="none"
        d="
M105.057533,261.090393 
	C97.281158,255.926743 91.890938,249.094116 89.746490,239.825226 
	C87.112137,228.438889 91.873474,214.584351 100.830956,208.105621 
	C112.748489,199.485962 125.860863,198.845352 137.278961,205.445648 
	C156.589478,216.608200 159.741211,246.389618 136.794434,260.866699 
	C125.821892,266.592285 115.434731,265.869568 105.057533,261.090393 
z"
      />
      <path
        fill="#D4E4F0"
        opacity="1.000000"
        stroke="none"
        d="
M377.057556,261.090393 
	C369.281067,255.926804 363.891022,249.093994 361.746460,239.825226 
	C359.112061,228.439163 363.873566,214.584213 372.830963,208.105591 
	C384.748474,199.485962 397.861053,198.845337 409.278961,205.445633 
	C428.590546,216.608978 431.740234,246.390533 408.794373,260.866669 
	C397.821838,266.592346 387.434723,265.869537 377.057556,261.090393 
z"
      />
      <path
        fill="#D4E4F0"
        opacity="1.000000"
        stroke="none"
        d="
M136.943909,364.893646 
	C144.711304,370.065643 150.093750,376.907440 152.249146,386.164795 
	C154.870041,397.421356 150.049576,411.512512 141.143600,417.897095 
	C129.725830,426.082367 117.756119,427.715729 105.134850,420.727112 
	C84.905083,409.525421 82.495155,379.125641 105.211166,365.129120 
	C116.180061,359.387146 126.566444,360.145294 136.943909,364.893646 
z"
      />
      <path
        fill="#D4E4F0"
        opacity="1.000000"
        stroke="none"
        d="
M408.943878,364.893646 
	C416.711304,370.065643 422.093750,376.907410 424.249115,386.164795 
	C426.869904,397.421326 422.049408,411.512817 413.143646,417.897125 
	C401.725861,426.082245 389.756256,427.715668 377.134918,420.727112 
	C356.905518,409.525787 354.494873,379.125854 377.211182,365.129150 
	C388.180054,359.387115 398.566437,360.145294 408.943878,364.893646 
z"
      />
      <path
        fill="#68737F"
        opacity="1.000000"
        stroke="none"
        d="
M136.961823,364.432220 
	C126.566444,360.145294 116.180061,359.387146 105.421448,364.862915 
	C105.048508,330.606567 105.033943,296.311188 105.038452,261.553101 
	C115.434731,265.869568 125.821892,266.592285 136.583801,261.132019 
	C136.955536,295.388306 136.967621,329.679535 136.961823,364.432220 
z"
      />
      <path
        fill="#68737F"
        opacity="1.000000"
        stroke="none"
        d="
M408.961792,364.432220 
	C398.566437,360.145294 388.180054,359.387115 377.421448,364.862915 
	C377.048492,330.606567 377.033936,296.311188 377.038452,261.553101 
	C387.434723,265.869537 397.821838,266.592346 408.583679,261.131958 
	C408.955414,295.388214 408.967560,329.679504 408.961792,364.432220 
z"
      />
      <path
        fill="#529DDF"
        opacity="1.000000"
        stroke="none"
        d="
M233.005936,445.766418 
	C233.001770,441.476135 233.001770,437.660980 233.001770,433.423859 
	C248.951874,433.423859 264.631927,433.423859 280.657928,433.423859 
	C280.657928,438.344940 280.657928,443.254333 280.657928,448.499054 
	C279.661591,448.654785 278.568970,448.971527 277.475555,448.974335 
	C264.000214,449.009094 250.524765,449.005005 237.049347,448.990784 
	C235.115860,448.988770 232.774521,449.478241 233.005936,445.766418 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M345.000000,124.000000 
	C344.999817,132.331177 344.803741,140.169296 345.081085,147.990631 
	C345.222107,151.966125 343.907684,153.094894 339.975922,153.077988 
	C307.652100,152.938919 275.327393,152.999878 243.002960,152.999969 
	C220.175919,153.000031 197.347809,152.886719 174.522736,153.098038 
	C170.120911,153.138779 168.839386,151.916550 168.899719,147.490494 
	C169.142746,129.665375 169.107895,111.833870 168.917664,94.007477 
	C168.875351,90.042648 170.093063,88.915947 174.023804,88.923973 
	C229.341675,89.036926 284.659912,89.037018 339.977783,88.923798 
	C343.911224,88.915749 345.184845,90.041313 345.082581,94.010330 
	C344.829407,103.834404 344.999786,113.669395 345.000000,124.000000 
M278.214691,113.014389 
	C273.956421,113.014389 269.698151,113.014389 265.349915,113.014389 
	C265.349915,118.679161 265.349915,123.576416 265.349915,128.687912 
	C270.599213,128.687912 275.497070,128.687912 280.988403,128.687912 
	C280.988403,124.277428 281.107819,120.156044 280.904053,116.050705 
	C280.853424,115.030365 279.757660,114.061905 278.214691,113.014389 
M312.999573,124.333679 
	C312.999573,120.727882 312.999573,117.122078 312.999573,113.306625 
	C307.410522,113.306625 302.513458,113.306625 297.444275,113.306625 
	C297.444275,118.635323 297.444275,123.651711 297.444275,128.989929 
	C301.864105,128.989929 305.848938,128.877899 309.823914,129.027512 
	C312.691528,129.135452 313.174744,127.567085 312.999573,124.333679 
z"
      />
      <g>
        <text
          dz="M260.88"
          transform="translate(210 145)"
          style={{
            fill: "black",
            fontSize: "64px",
            fontFamily: "Nunito-SemiBold, Nunito",
            fontWeight: "600",
          }}
        >
          <tspan>{props.value}</tspan>
        </text>
      </g>
    </svg>
  );
};

export default WeightDeviceIcon;
