import { Avatar, Col, Image, Row } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import ProfilePic from "../../ProfilePic";
import phoneIcon from '../../../assets/images/new/call-with-chat.png';
import { useHistory } from "react-router-dom";

const ContactTechnicianCard = () => {
  let history = useHistory();

  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "30px",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#A01027",
      }}
      onClick={() => history.push('/profile')}
    >
      <Card.Body
        className={"row m-0 pl-4 pr-4"}
        style={{
          fontFamily: "Nunito-Regular",
          fontSize: "24px",
          color: "white",
          flex: "row",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "250px",
          cursor: 'pointer'
        }}
      >
        <Row>
          <Col span={24}>
            <Row gutter={[8, 8]} justify="center">
              <Col span={24}>Contact Us</Col>
            </Row>
            <Row gutter={[8, 8]} justify="center">
              <Col span={12}>
                <Image 
                  src={phoneIcon}
                  width={50}
                  height={50}
                  preview={false}
                />
              </Col>
              <Col span={12}>
                <Avatar shape="circle" size={150} icon={<ProfilePic />} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ContactTechnicianCard;
