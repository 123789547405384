import { Col, Row } from "antd";
import { observer } from "mobx-react";
import DoseConfirmationCard from "../DoseConfirmationCard";
import MonthlyReportCard from "../MonthlyReportCard";
import ReportStats from "../ReportStats";
import { reportStore } from "../../dataLayer/stores/pages/reportStore";
import { homeStore } from "../../dataLayer/stores/pages/homeStore";
import { homeActionStore } from "../../dataLayer/stores/actions/homeActionStore";
import DoseAdherenceModal from "../DoseAdherenceModal";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Reports = observer(({ props }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row>
            <p
              className="m-2"
              style={{
                fontFamily: "Nunito-SemiBold",
                fontSize: "28px",
                color: "#272D5C",
              }}
            >
              Overview
            </p>
          </Row>
          <Row>
            <div style={box}>
              <ReportStats data={reportStore.comp1Data} />
            </div>
          </Row>
        </Col>
        <Col span={12}>
          <div style={box}>
            <MonthlyReportCard />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <DoseConfirmationCard
              time={homeStore.comp6Data.time}
              onYes={() => {
                homeActionStore.setComp1({ visible: true });
              }}
              onNo={() => {
                homeActionStore.setComp1({ visible: true });
              }}
            />
            <DoseAdherenceModal
              visible={homeStore.comp6Data.visible}
              data={{
                pills: homeStore.comp6Data.pillsList,
              }}
              onCancel={() => {
                homeActionStore.setComp1({ visible: false });
              }}
              onSubmit={homeActionStore.updatePrescription}
              onChange={homeActionStore.setPrescriptionStatus}
            />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Reports;
