import { makeAutoObservable, action } from "mobx";

import moment from "moment";
import { dbstore } from "../dbStore";
import { homeStore } from "../pages/homeStore";
import _ from "lodash";

export default class Store {
  comp1 = {
    visible: false,
  };
  comp2 = { period: 30 };

  constructor() {
    makeAutoObservable(this, {
      setComp1: action,
      setComp2: action,
    });
  }

  setComp1(obj) {
    Object.assign(this.comp6, obj);
  }

  setComp2(obj) {
    Object.assign(this.comp2, obj);
  }

  setPrescriptionStatus(rxid, selected) {
    var i = _.findIndex(homeStore.comp6Data.pillsList, { rxid: rxid });
    homeStore.comp6.pillsList[i]["selected"] = selected;
  }

  *updatePrescription(type) {
    dbstore.checkSession();

    var arr = [];
    dbstore.loading = true;

    homeStore.comp6Data.pillsList.forEach((pc) => {
      var obj = {
        date: pc.date,
        time: pc.time,
        rxid: pc.rxid,
        status:
          type == "yes"
            ? pc.selected
              ? "taken"
              : "missed"
            : pc.selected
            ? "missed"
            : "taken",
      };
      arr.push(obj);
    });
    var body = { data: arr };
    var res = yield fetch(`${dbstore.endpoint}/patients/pillcompliance/bulk`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    res = yield res.json();
    var pcfrom = moment().subtract(3, "days").format("x") + "";
    var pcto = moment().add(3, "days").format("x") + "";
    yield dbstore.getPcArr(pcfrom, pcto);
    dbstore.loading = false;
  }
}

const homeActionStore = new Store();
export { homeActionStore };
