import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import SwitchProfile from "./SwitchProfile";

const { Title } = Typography;

const SelectProfile = () => {
  return (
    <Row
      align={"top"}
      style={{ height: "100vh", padding: 20, backgroundColor: "white" }}
    >
      <Col span={24}>
        <Divider orientation="left">
          <Title level={3}>Select Profile</Title>
        </Divider>
        <SwitchProfile onSelectProfile={() => console.log("select profile")} />
      </Col>
    </Row>
  );
};

export default SelectProfile;
