import React from "react";
import { dbstore } from "../../../dataLayer/stores/dbStore";
import ProfilesList from "./ProfilesList";
import { observer } from "mobx-react";
import { Col } from "antd";
import { useHistory } from "react-router-dom";

const SwitchProfile = observer((props) => {
  const history = useHistory();

  return (
    <Col span={24}>
      <ProfilesList
        data={dbstore.profilesList}
        onSelectProfile={(id) => {
          props.onSelectProfile();
          dbstore.setSelectedProfile(id);
          history.push(`/home`);
        }}
      />
    </Col>
  );
});

export default SwitchProfile;
