import { makeAutoObservable } from "mobx";
import { dbstore } from "../dbStore";
import { reportsActionStore } from "../actions/reportsActionStore";

export default class Store {
  comp1 = {};
  comp2 = {};
  comp3 = {};
  comp4 = {};
  comp5 = {};
  comp6 = {};
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    // console.log("comp1Data")
    var period = "Daily";
    var val = 60;
    var datalist = [
      {
        missed: 0,
        missedPercent: 0,
        taken: 2,
        takenPercent: 0.2222222222222222,
        total: 9,
        unanswered: 7,
        unansweredPercent: 0.7777777777777778,
      },
    ];
    if (dbstore.reports) {
      datalist = [];
      period = reportsActionStore.comp1.period;
      // console.log(dbstore.reports.last6Avg)
      switch (period) {
        case "Daily":
          datalist = dbstore?.reports?.last6Avg.days;
          val = dbstore?.reports?.pillcomplianceAvg?.day?.takenPercent * 100;
          break;
        case "Weekly":
          datalist = dbstore?.reports?.last6Avg.weeks;
          val = dbstore?.reports?.pillcomplianceAvg?.week?.takenPercent * 100;
          break;
        case "Monthly":
          datalist = dbstore?.reports?.last6Avg.months;
          val = dbstore?.reports?.pillcomplianceAvg?.month?.takenPercent * 100;
          break;
        case "Yearly":
          datalist = dbstore?.reports?.last6Avg.years;
          val = dbstore?.reports?.pillcomplianceAvg?.year?.takenPercent * 100;
          break;
        default:
          datalist = dbstore?.reports?.last6Avg.days;
          val = dbstore?.reports?.pillcomplianceAvg?.day?.takenPercent * 100;
      }
    }
    //  console.log(datalist)

    Object.assign(this.comp1, {
      graphData: datalist,
      timePeriod: period,
      percentage: val,
    });
    return this.comp1;
  }
  get comp2Data() {
    return this.comp2;
  }
  get comp3Data() {
    this.comp3 = "10:00";
    return this.comp3;
  }

  *resetReportStore() {
    yield (this.reports = {
      day: {},
      week: {},
      month: {},
      year: {},
    });
  }
}

const reportStore = new Store();
export { reportStore };
