import React from "react";

const OximeterDeviceIcon = (props) => {
  return (
    <svg x="0px" y="0px" width="100%" viewBox="0 0 512 512">
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M513.000000,485.000000 
	C513.000000,494.328796 513.000000,503.657593 513.000000,512.989746 
	C342.420837,512.993164 171.841675,512.993164 1.000000,512.993164 
	C1.000000,393.312561 1.000000,273.624939 1.330023,153.159088 
	C2.081840,152.874313 2.768310,153.311295 2.887331,153.869308 
	C7.991768,177.800629 19.513159,197.809158 38.222042,213.847473 
	C57.253853,230.162598 79.355377,238.380341 104.031380,239.885010 
	C114.648766,240.532425 125.344826,239.889587 136.468811,239.903275 
	C182.191513,239.982300 227.450562,240.020630 272.709473,239.955688 
	C277.452240,239.948883 282.289764,239.816681 286.917816,238.903625 
	C298.984131,236.523132 310.718994,226.881775 312.789551,214.579895 
	C314.524048,204.274750 314.348083,193.648041 315.480713,183.089661 
	C329.624237,182.955643 343.305054,182.895691 357.450104,182.910828 
	C372.904022,183.007462 387.893707,183.028976 402.774353,183.422974 
	C401.839447,183.866989 400.983185,183.821533 400.192139,184.027054 
	C375.204071,190.519577 358.654541,216.609680 362.812286,241.213394 
	C365.899475,259.481812 375.059601,272.815399 391.560394,280.657501 
	C397.989746,283.713074 405.340088,284.830872 411.465271,286.598755 
	C389.568726,293.853638 375.380157,309.578552 374.476837,333.052246 
	C373.312103,363.319275 389.952271,381.843475 420.174652,388.326172 
	C405.820343,392.217712 394.924683,399.664490 387.674530,411.845642 
	C378.723969,426.883667 377.334503,442.799957 384.103027,458.940125 
	C391.426483,476.403564 404.901062,487.456421 423.539093,489.667999 
	C437.497620,491.324310 451.822906,489.889587 466.449341,489.905304 
	C476.071136,489.986298 485.230255,489.885071 494.385254,490.036163 
	C501.017975,490.145599 507.715057,490.816681 512.304321,484.483704 
	C512.536194,484.655792 512.768127,484.827911 513.000000,485.000000 
z"
      />
      <path
        fill="#FFDFCF"
        opacity="1.000000"
        stroke="none"
        d="
M402.883423,183.050507 
	C387.893707,183.028976 372.904022,183.007462 357.278442,182.634033 
	C351.018616,173.356415 344.533264,164.846634 339.974457,155.406525 
	C333.296051,141.577148 331.142426,126.541969 334.053772,111.331970 
	C336.065887,100.820107 339.311951,90.544441 342.469604,80.089134 
	C395.174194,80.010078 447.415741,79.996117 499.657227,80.039505 
	C501.389862,80.040947 503.302399,80.261139 504.815582,81.013756 
	C507.665375,82.431213 510.284790,84.311905 513.000000,86.000015 
	C513.000000,218.687561 513.000000,351.375122 513.000000,484.531342 
	C512.768127,484.827911 512.536194,484.655792 512.304321,484.483704 
	C507.715057,490.816681 501.017975,490.145599 494.385254,490.036163 
	C485.230255,489.885071 476.071136,489.986298 466.154449,489.677612 
	C444.903656,486.551941 423.882233,468.872223 422.385010,443.566193 
	C420.665314,414.499695 435.626373,396.182343 463.567474,387.821533 
	C450.482727,386.333557 439.449707,381.100311 430.506226,371.923126 
	C421.452148,362.632294 416.027466,351.113495 416.243805,338.331146 
	C416.728485,309.692932 431.926941,292.145905 459.746307,285.335205 
	C430.368225,286.986572 410.303833,266.505066 405.458221,245.897720 
	C402.735596,234.319122 403.660004,222.994797 408.919922,212.428864 
	C416.388641,197.426147 428.582825,187.787125 444.761841,183.050507 
	C430.802368,183.050507 416.842896,183.050507 402.883423,183.050507 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M513.000000,85.531357 
	C510.284790,84.311905 507.665375,82.431213 504.815582,81.013756 
	C503.302399,80.261139 501.389862,80.040947 499.657227,80.039505 
	C447.415741,79.996117 395.174194,80.010078 342.006287,80.012039 
	C332.394989,79.951859 323.710144,79.894684 315.020325,79.379120 
	C314.676910,70.663055 314.238281,62.408009 314.022064,54.147137 
	C313.635590,39.382099 298.151306,24.020189 283.430878,24.007366 
	C254.771362,23.982397 226.111786,23.978178 197.452271,24.007271 
	C176.627792,24.028412 155.803391,24.116920 134.517319,24.097988 
	C120.171021,24.343424 106.232483,24.010481 92.414482,25.145220 
	C72.473480,26.782785 54.900990,35.340195 39.814262,47.959728 
	C20.572550,64.054771 7.585741,84.435455 2.906342,109.544777 
	C2.797648,110.128021 1.658502,110.519218 1.000000,111.000000 
	C1.000000,74.356018 1.000000,37.712044 1.000003,1.062738 
	C171.666672,1.057412 342.333344,1.057412 513.000000,1.057412 
	C513.000000,29.097445 513.000000,57.080063 513.000000,85.531357 
z"
      />
      <path
        fill="#01C0FA"
        opacity="1.000000"
        stroke="none"
        d="
M1.000000,111.468658 
	C1.658502,110.519218 2.797648,110.128021 2.906342,109.544777 
	C7.585741,84.435455 20.572550,64.054771 39.814262,47.959728 
	C54.900990,35.340195 72.473480,26.782785 92.414482,25.145220 
	C106.232483,24.010481 120.171021,24.343424 134.234116,24.330832 
	C126.748390,26.697811 118.798393,28.033873 111.471184,30.940096 
	C84.441765,41.660892 64.085968,59.771091 51.729988,86.478493 
	C42.063442,107.372688 39.611969,129.089752 43.735939,151.601913 
	C47.128563,170.121796 54.953018,186.486389 67.201111,200.941238 
	C83.284073,219.921951 103.400879,231.735519 127.558960,237.048691 
	C130.439148,237.682144 133.192825,238.890869 136.005142,239.832932 
	C125.344826,239.889587 114.648766,240.532425 104.031380,239.885010 
	C79.355377,238.380341 57.253853,230.162598 38.222042,213.847473 
	C19.513159,197.809158 7.991768,177.800629 2.887331,153.869308 
	C2.768310,153.311295 2.081840,152.874313 1.330023,152.690430 
	C1.000000,139.312439 1.000000,125.624878 1.000000,111.468658 
z"
      />
      <path
        fill="#01D0FB"
        opacity="1.000000"
        stroke="none"
        d="
M136.468811,239.903275 
	C133.192825,238.890869 130.439148,237.682144 127.558960,237.048691 
	C103.400879,231.735519 83.284073,219.921951 67.201111,200.941238 
	C54.953018,186.486389 47.128563,170.121796 43.735939,151.601913 
	C39.611969,129.089752 42.063442,107.372688 51.729988,86.478493 
	C64.085968,59.771091 84.441765,41.660892 111.471184,30.940096 
	C118.798393,28.033873 126.748390,26.697811 134.695740,24.408234 
	C155.803391,24.116920 176.627792,24.028412 197.452271,24.007271 
	C226.111786,23.978178 254.771362,23.982397 283.430878,24.007366 
	C298.151306,24.020189 313.635590,39.382099 314.022064,54.147137 
	C314.238281,62.408009 314.676910,70.663055 314.654572,79.627747 
	C314.204559,80.822479 314.037506,81.310143 314.037231,81.797897 
	C314.018768,114.562485 314.004608,147.327103 314.050049,180.091614 
	C314.051453,181.116089 314.681213,182.139694 315.018066,183.163712 
	C314.348083,193.648041 314.524048,204.274750 312.789551,214.579895 
	C310.718994,226.881775 298.984131,236.523132 286.917816,238.903625 
	C282.289764,239.816681 277.452240,239.948883 272.709473,239.955688 
	C227.450562,240.020630 182.191513,239.982300 136.468811,239.903275 
M245.209259,60.009491 
	C220.429504,59.673351 195.650208,59.296059 170.869781,59.020531 
	C160.377533,58.903870 149.880997,58.878983 139.389999,59.042381 
	C131.929840,59.158573 124.459595,59.452568 117.022003,60.029858 
	C99.177788,61.414898 84.015762,78.007889 84.003593,95.958214 
	C83.987549,119.610321 83.988091,143.262436 84.003166,166.914551 
	C84.015709,186.587112 101.280525,203.949997 121.032310,203.981323 
	C160.341354,204.043655 199.651276,204.110199 238.958801,203.857986 
	C243.791138,203.826981 248.896027,202.540405 253.385727,200.665176 
	C269.971680,193.737717 276.616272,180.042648 276.911774,162.991486 
	C277.275421,142.010696 277.137695,121.017632 276.958588,100.032074 
	C276.787476,79.981384 265.312805,64.472733 245.209259,60.009491 
z"
      />
      <path
        fill="#FFCEBF"
        opacity="1.000000"
        stroke="none"
        d="
M402.774353,183.422974 
	C416.842896,183.050507 430.802368,183.050507 444.761841,183.050507 
	C428.582825,187.787125 416.388641,197.426147 408.919922,212.428864 
	C403.660004,222.994797 402.735596,234.319122 405.458221,245.897720 
	C410.303833,266.505066 430.368225,286.986572 459.746307,285.335205 
	C431.926941,292.145905 416.728485,309.692932 416.243805,338.331146 
	C416.027466,351.113495 421.452148,362.632294 430.506226,371.923126 
	C439.449707,381.100311 450.482727,386.333557 463.567474,387.821533 
	C435.626373,396.182343 420.665314,414.499695 422.385010,443.566193 
	C423.882233,468.872223 444.903656,486.551941 465.690002,489.603455 
	C451.822906,489.889587 437.497620,491.324310 423.539093,489.667999 
	C404.901062,487.456421 391.426483,476.403564 384.103027,458.940125 
	C377.334503,442.799957 378.723969,426.883667 387.674530,411.845642 
	C394.924683,399.664490 405.820343,392.217712 420.174652,388.326172 
	C389.952271,381.843475 373.312103,363.319275 374.476837,333.052246 
	C375.380157,309.578552 389.568726,293.853638 411.465271,286.598755 
	C405.340088,284.830872 397.989746,283.713074 391.560394,280.657501 
	C375.059601,272.815399 365.899475,259.481812 362.812286,241.213394 
	C358.654541,216.609680 375.204071,190.519577 400.192139,184.027054 
	C400.983185,183.821533 401.839447,183.866989 402.774353,183.422974 
z"
      />
      <path
        fill="#FFCEBF"
        opacity="1.000000"
        stroke="none"
        d="
M315.480713,183.089661 
	C314.681213,182.139694 314.051453,181.116089 314.050049,180.091614 
	C314.004608,147.327103 314.018768,114.562485 314.037231,81.797897 
	C314.037506,81.310143 314.204559,80.822479 314.659546,80.086143 
	C323.710144,79.894684 332.394989,79.951859 341.543213,80.086121 
	C339.311951,90.544441 336.065887,100.820107 334.053772,111.331970 
	C331.142426,126.541969 333.296051,141.577148 339.974457,155.406525 
	C344.533264,164.846634 351.018616,173.356415 356.814240,182.558929 
	C343.305054,182.895691 329.624237,182.955643 315.480713,183.089661 
z"
      />
      <path
        fill="#03C1FA"
        opacity="1.000000"
        stroke="none"
        d="
M245.640533,60.027954 
	C265.312805,64.472733 276.787476,79.981384 276.958588,100.032074 
	C277.137695,121.017632 277.275421,142.010696 276.911774,162.991486 
	C276.616272,180.042648 269.971680,193.737717 253.385727,200.665176 
	C248.896027,202.540405 243.791138,203.826981 238.958801,203.857986 
	C199.651276,204.110199 160.341354,204.043655 121.032310,203.981323 
	C101.280525,203.949997 84.015709,186.587112 84.003166,166.914551 
	C83.988091,143.262436 83.987549,119.610321 84.003593,95.958214 
	C84.015762,78.007889 99.177788,61.414898 117.022003,60.029858 
	C124.459595,59.452568 131.929840,59.158573 139.389999,59.042381 
	C149.880997,58.878983 160.377533,58.903870 170.869781,59.020531 
	C195.650208,59.296059 220.429504,59.673351 245.640533,60.027954 
M124.914864,188.246155 
	C162.866547,188.278107 200.818680,188.424042 238.769684,188.279007 
	C251.733871,188.229462 260.975891,178.643127 261.043701,165.670517 
	C261.159454,143.531967 261.110504,121.392342 261.073181,99.253265 
	C261.047668,84.127739 251.941254,74.980614 236.866089,74.939285 
	C219.055023,74.890457 201.243637,74.897949 183.432510,74.935120 
	C163.628403,74.976440 143.824432,75.078697 123.161026,74.984993 
	C109.506721,75.023888 99.435593,83.949455 99.256180,97.121033 
	C98.943275,120.092575 98.937645,143.074493 99.248940,166.046021 
	C99.435593,179.820236 110.214653,189.038086 124.914864,188.246155 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M124.020401,75.154427 
	C143.824432,75.078697 163.628403,74.976440 183.432510,74.935120 
	C201.243637,74.897949 219.055023,74.890457 236.866089,74.939285 
	C251.941254,74.980614 261.047668,84.127739 261.073181,99.253265 
	C261.110504,121.392342 261.159454,143.531967 261.043701,165.670517 
	C260.975891,178.643127 251.733871,188.229462 238.769684,188.279007 
	C200.818680,188.424042 162.866547,188.278107 124.415253,187.801819 
	C121.436310,182.214813 117.095680,177.169800 116.819771,171.911484 
	C115.921341,154.789200 116.507591,137.588943 116.535309,120.418724 
	C116.535522,120.282837 116.713333,120.147247 116.901031,120.390091 
	C121.233490,139.138977 133.471100,146.907623 153.519150,146.323135 
	C147.265930,153.205780 142.245697,158.921371 137.008713,164.430939 
	C133.307281,168.325027 132.834091,173.078171 136.210663,176.287186 
	C139.355209,179.275681 143.857330,179.099228 147.871841,175.514175 
	C162.900330,162.093430 172.371613,145.521454 176.124344,125.660149 
	C177.712784,117.253273 177.799500,109.130226 173.506958,101.405296 
	C167.332779,90.294106 155.274216,84.341667 142.345596,86.056709 
	C129.632843,87.743111 119.999596,97.526474 116.574982,111.651382 
	C116.067039,101.492104 114.292480,91.493057 119.824112,82.362892 
	C121.264587,79.985336 122.623840,77.558578 124.020401,75.154427 
M204.970520,176.883240 
	C219.124939,180.790451 232.252686,176.415131 239.793152,165.176193 
	C243.511414,159.634186 245.305038,153.503998 245.005829,146.783417 
	C244.598633,137.636978 240.300751,130.386642 233.895706,124.372894 
	C241.395935,110.823021 240.107498,99.118767 230.400879,90.693657 
	C221.818390,83.244270 209.255753,82.924210 200.012115,89.919441 
	C189.565704,97.824890 188.045883,109.237549 195.423981,124.372726 
	C175.492325,143.112762 185.141281,169.789215 204.970520,176.883240 
z"
      />
      <path
        fill="#C5EEFE"
        opacity="1.000000"
        stroke="none"
        d="
M123.590714,75.069710 
	C122.623840,77.558578 121.264587,79.985336 119.824112,82.362892 
	C114.292480,91.493057 116.067039,101.492104 116.620514,112.094925 
	C116.871391,115.254105 116.839798,117.632805 116.808212,120.011520 
	C116.713333,120.147247 116.535522,120.282837 116.535309,120.418724 
	C116.507591,137.588943 115.921341,154.789200 116.819771,171.911484 
	C117.095680,177.169800 121.436310,182.214813 123.952827,187.711761 
	C110.214653,189.038086 99.435593,179.820236 99.248940,166.046021 
	C98.937645,143.074493 98.943275,120.092575 99.256180,97.121033 
	C99.435593,83.949455 109.506721,75.023888 123.590714,75.069710 
z"
      />
      <g>
        <text
          dz="M260.88"
          transform="translate(140 160)"
          style={{
            fill: "black",
            fontSize: "64px",
            fontFamily: "Nunito-SemiBold, Nunito",
            fontWeight: "600",
          }}
        >
          <tspan>{props.value}</tspan>
        </text>
      </g>
    </svg>
  );
};

export default OximeterDeviceIcon;
