import { makeAutoObservable } from "mobx";
import { dbstore } from "../dbStore";
import { profileActionStore } from "../actions/profileActionStore";
import {
  bloodGlucoseObj,
  bloodPressureObj,
  oxygenLevelsObj,
  temperatureObj,
  weightObj,
} from "../../../utils/Common.variables";

export default class Store {
  comp1 = { name: "name", age: "age", gender: "gender", email: "email" };
  comp2 = {};
  comp3 = {};
  comp4 = {};
  comp5 = {};
  comp6 = {};
  constructor() {
    this.profilActioneStore = profileActionStore;
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (dbstore.patientDetails) {
      var name = dbstore.patientDetails.name;
      var age = dbstore.patientDetails.age;
      var gender = dbstore.patientDetails.gender;
      var email = dbstore.patientDetails.email;
      var phone = dbstore.patientDetails.phone;
      var address = dbstore.patientDetails.address.City;
      Object.assign(this.comp1, {
        name: name,
        age: age,
        gender: gender,
        email: email,
        phone: phone,
        address: address,
        avatar: dbstore.patientDetails.avatar,
        signature: dbstore.patientDetails.signature,
      });
    }
    // console.log(this.comp1);
    return this.comp1;
  }
  get comp2Data() {
    var obj = {
      profile: {},
      options: [dbstore?.pharmacyDetails?.name],
      settings: dbstore?.patientDetails?.settings,
    };
    Object.assign(this.comp2, obj);
    return this.comp2;
  }
  get comp3Data() {
    var data = {};
    if (dbstore.reports) {
      let vitalsAverageData = {};
      dbstore.reports.vitalsAvg.map((vital) => {
        if (vital.key === bloodPressureObj.name) {
          vitalsAverageData.bloodPressure = {
            name: bloodPressureObj.displayName,
            systolic: Math.floor(vital?.systolic || 0),
            diastolic: Math.floor(vital?.diastolic || 0),
            pulse: Math.floor(vital?.pulse || 0),
            units: bloodPressureObj.units,
          };
        } else if (vital.key === bloodGlucoseObj.name) {
          vitalsAverageData.bloodGlucose = {
            name: bloodGlucoseObj.displayName,
            value: Math.floor(vital?.value || 0),
            units: bloodGlucoseObj.units,
          };
        } else if (vital.key === temperatureObj.name) {
          vitalsAverageData.temperature = {
            name: temperatureObj.displayName,
            value: Math.floor(vital?.value || 0),
            units: temperatureObj.units,
          };
        } else if (vital.key === weightObj.name) {
          vitalsAverageData.weight = {
            name: weightObj.displayName,
            value: Math.floor(vital?.value || 0),
            units: weightObj.units,
          };
        } else if (vital.key === oxygenLevelsObj.name) {
          vitalsAverageData.oxygenLevels = {
            name: oxygenLevelsObj.displayName,
            value: Math.floor(vital?.value || 0),
            units: oxygenLevelsObj.units,
          };
        }
      });
      data = {
        vitalsAverage: vitalsAverageData,
      };
      // data = {
      //   vitalsAverage: {
      //     bloodPressure: {
      //       name: "Blood Pressure",
      //       systolic: Math.floor(dbstore.reports.vitalsAvg[3]?.systolic || 0),
      //       diastolic: Math.floor(dbstore.reports.vitalsAvg[3]?.diastolic || 0),
      //       units: "cmHg",
      //     },
      //     bloodGlucose: {
      //       name: "Blood Glucose",
      //       value: Math.floor(dbstore.reports.vitalsAvg[2]?.value || 0),
      //       units: "mgdl",
      //     },
      //     temperature: {
      //       name: "Temperature",
      //       value: Math.floor(dbstore.reports.vitalsAvg[1]?.value || 0),
      //       units: "F",
      //     },
      //     weight: {
      //       name: "Weight",
      //       value: Math.floor(dbstore.reports.vitalsAvg[0]?.value || 0),
      //       units: "lbs",
      //     },
      //     oxygenLevels: {
      //       name: "Oxygen Levels",
      //       value: Math.floor(dbstore.reports.vitalsAvg[4]?.value || 0),
      //       units: "mmHg",
      //     },
      //   },
      // };
    }

    Object.assign(this.comp3, data);
    return this.comp3;
  }
  get comp4Data() {
    return this.comp4;
  }
  get comp5Data() {
    return this.comp5;
  }
  get comp6Data() {
    return this.comp6;
  }

  *resetProfileStore() {
    yield (this.comp1 = {
      name: "name",
      age: "age",
      gender: "gender",
      email: "email",
    });
    yield (this.comp2 = {});
    yield (this.comp3 = {});
    yield (this.comp4 = {});
    yield (this.comp5 = {});
    yield (this.comp6 = {});
  }
}

const profileStore = new Store();
export { profileStore };
