import { makeAutoObservable } from "mobx";
import moment from "moment";
import Cookies from "js-cookie";
import Client from "twilio-chat";
import { message } from "antd";
import { resetActionStore } from "./actions/resetActionStore";
import { cookieAttributes } from "../../utils/Common.variables";

const fetch = require("node-fetch");
//const endpoint = "https://api.doctorspharmacy.us";
const endpoint = process.env.REACT_APP_SERVER_URL;

export default class dbStore {
  endpoint = null;
  initialLoad = false;
  loading = true;
  login = false;
  userid = null;
  // userid = "6110142577f4b00b95355291";
  decodedUserId = null; //"60dcc4dcfef5466308c7b3dd"// null;
  patientDetails = null;
  pharmacyDetails = null;
  rxarr = null;
  pillcompliancePercentage = 0;
  pcarr = null;
  pillboxDetails = {};
  pcarrDaily = null;
  reports = null;
  pillcomplianceAvg = null;
  doctorDetails = null;
  vitalChart = {};
  vitalRecords = [];
  contacts = {};
  chatToken = null;
  chatClient = null;
  chatChannels = [];
  selectedCompliance = null;
  activeRxes = [];
  loadingRxes = false;
  currentPillBox = {};
  unreadArr = [];
  profilesList = [];
  showProfiles = false;

  constructor() {
    this.endpoint = endpoint;
    makeAutoObservable(this);
  }

  setSelectedCompliance = (compliance) => {
    this.selectedCompliance = compliance;
  };

  *getData() {
    yield (this.loading = true);
    yield this.checkSession();
    console.log("getting data");
    yield this.refreshStore();
    console.log(this.login);
    if (!this.login) {
      return;
    }

    yield this.getPatientData();
    yield this.getPatientsList(this.patientDetails.phone);
    yield this.getPharmacyDetails();
    yield this.getRxesArray();
    yield this.getPillComplianceDaily();
    yield this.getPatientReports();
    yield this.getDoctorDetails();
    yield this.getUnreadData();
    yield this.getContactDetails();

    yield (this.loading = false);
  }

  *getContactDetails(refresh = false) {
    this.loading = true;
    var path = "/patients/contacts";
    var options = {
      method: "get",
      cache: "no-cache",
      credentials: "include",
    };
    if (refresh) {
      options["cache"] = "no-cache";
    }
    var res = yield fetch(
      `${endpoint}${path}?docid=${this.decodedUserId}`,
      options
    );
    res = yield res.json();
    this.contacts = res;
    this.loading = false;
  }

  *getPharmacyDetails() {
    if (this.patientDetails?.phid) {
      let res = yield fetch(
        `${endpoint}/patients/pharmacies?_id=${this.patientDetails.phid}`,
        {
          method: "get",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
        }
      );
      res = yield res.json();
      console.log(res);
      this.pharmacyDetails = res[0];
      res = yield fetch(`${endpoint}/patients/rx?patid=${this.userid}`, {
        method: "get",
        credentials: "include",
        cache: "no-cache",
        // headers: { "Content-Type": "application/json" },
      });
      res = yield res.json();
      this.rxarr = res;
      console.log(res);
      var pcfrom = moment().subtract(3, "days").format("x") + "";
      var pcto = moment().add(3, "days").format("x") + "";
      yield this.getPcArr(pcfrom, pcto);
    }
  }

  *getRxesArray() {
    let res = yield fetch(`${endpoint}/patients/rx?patid=${this.userid}`, {
      method: "get",
      credentials: "include",
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
    });
    res = yield res.json();
    this.rxarr = res;
  }

  *getPillComplianceDaily() {
    let res = yield fetch(
      `${endpoint}/patients/pillcompliance/daily?patid=${this.userid}`,
      {
        method: "get",
        credentials: "include",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" },
      }
    );
    res = yield res.json();
    this.pcarrDaily = res;
  }

  *getPatientReports() {
    let res = yield fetch(`${endpoint}/patients/reports?patid=${this.userid}`, {
      method: "get",
      credentials: "include",
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
    });
    res = yield res.json();
    this.reports = res[0];
  }

  *approveComplince(type) {
    this.checkSession();

    if (!type) {
      type = this.selectedCompliance;
    }
    this.loading = true;

    var raw = {
      type: type,
    };
    console.log(raw);
    var res = yield fetch(`${endpoint}/patients/approve/compliance`, {
      method: "post",

      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(raw),
    });

    var status = res.status;
    res = yield res.json();
    if (status == 200) {
      message.success("Compliance Approved");
      this.patientDetails.compliance[type].signed = true;
      // navigationRef.current.navigate("Home")
    }
    this.loading = false;
    return status;
  }

  *getPcArr(pcfrom, pcto) {
    pcfrom = pcfrom || moment().subtract(3, "days").format("x") + "";
    pcto = pcto || moment().add(3, "days").format("x") + "";

    let client_date = moment().utc(true).format();
    let pillbox = yield fetch(
      `${endpoint}/patients/${this.userid}/date/${client_date}/activepillbox`,
      {
        method: "get",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        cache: "no-cache",
      }
    );
    pillbox = yield pillbox.json();
    let pillCompliance = yield fetch(
      `${endpoint}/patients/${this.userid}/date/${client_date}/activepillbox/compliance`,
      {
        method: "get",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          client_date: client_date,
        },
        cache: "no-cache",
      }
    );
    pillCompliance = yield pillCompliance.json();
    yield (this.pillcompliancePercentage = pillCompliance
      ? pillCompliance.compliancePercentage
      : 0);
    this.currentPillBox = pillbox;
    let obj = {};
    obj.schedule = pillbox.schedule;
    obj.pouches = pillbox.pouches;
    obj.rxSafeJobDataImportPath = pillbox.rxSafeJobDataImportPath;
    obj.docId = pillbox.pioneerRxDoctorId;
    obj.phId = pillbox.pioneerRxPharmacyId;
    obj.patId = pillbox.pioneerRxPatientId;
    obj.boxId = pillbox.rxSafeJobId;
    this.pillboxDetails = obj;
  }

  *getDoctorDetails(arr) {
    this.checkSession();

    var res = yield fetch(
      `${endpoint}/patients/doctors?userid=${this.userid}`,
      {
        method: "get",
        credentials: "include",
        cache: "no-cache",
        // headers: { "Content-Type": "application/json" },
      }
    );
    res = yield res.json();
    // console.log(JSON.stringify(res));
    this.doctorDetails = res;
  }

  *getPatientData() {
    this.checkSession();

    let res = yield fetch(`${endpoint}/patients/details?_id=${this.userid}`, {
      method: "get",
      cache: "no-cache",
      credentials: "include",
      // headers: { "Content-Type": "application/json" },
    });
    res = yield res.json();
    console.log(res);
    this.patientDetails = res[0];
  }

  *getVitalRecords(type, limit, page, refresh = false) {
    this.checkSession();

    var options = {
      method: "get",
      credentials: "include",
      cache: "no-cache",
      // headers: { "Content-Type": "application/json" },
    };
    if (refresh) {
      options.cache = "no-cache";
    }
    var res = yield fetch(
      `${endpoint}/patients/vitalrecords?patid=${this.userid}&type=${type}&limit=${limit}&page=${page}`,
      options
    );
    res = yield res.json();
    // console.log(JSON.stringify(res));
    console.log(res);

    this.vitalRecords = res.length > 0 ? res : [];
    console.log(this.vitalRecords);
  }

  *newVital(obj) {
    this.checkSession();

    this.loading = true;
    obj.date = moment().format("x");
    var raw = {
      condition: { _id: this.userid },
      data: { $push: { vitals: obj } },
    };
    var res = yield fetch(`${endpoint}/patients`, {
      method: "put",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
      cache: "no-cache",
    });
    var status = res.status;
    res = yield res.json();
    console.log(res);
    yield this.getVitalRecords(obj.type, 20, 0, true);
    this.loading = false;
    return status;
  }

  *newMeeting(obj) {
    this.checkSession();

    this.loading = true;
    var raw = obj;
    obj["patid"] = this.userid;
    obj["phid"] = this.patientDetails.phid;
    var res = yield fetch(`${endpoint}/patients/meetings`, {
      method: "post",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
      cache: "no-cache",
    });
    var status = res.status;
    res = yield res.json();
    // console.log(JSON.stringify(res));

    this.loading = false;
    return status;
  }

  *newPT(obj) {
    this.checkSession();

    this.loading = true;
    var raw = obj;
    obj["patid"] = this.userid;
    obj["phid"] = this.patientDetails.phid;
    var file = obj["file"];
    delete obj["file"];
    var res = yield fetch(`${endpoint}/patients/prescriptionTransfers`, {
      method: "post",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
    });
    var status = res.status;
    res = yield res.json();
    console.log(res);
    // console.log(JSON.stringify(res));
    if (status == 200) {
      var s = yield this.uploadFile(res.url, file);
      console.log(s);
      status = s;
    }
    this.loading = false;
    return status;
  }

  *uploadFile(url, file) {
    this.checkSession();

    this.loading = true;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/jpeg");
    var res = yield fetch(url, {
      method: "PUT",
      body: file,
      headers: myHeaders,
      redirect: "follow",
    });

    var status = res.status;
    this.loading = false;

    return status;
  }

  *newProfilePic(file) {
    this.checkSession();

    this.loading = true;
    var raw = { userid: this.userid };
    var res = yield fetch(`${endpoint}/patients/profilepic`, {
      method: "post",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
    });
    var status = res.status;
    res = yield res.json();
    console.log(res);
    if (status == 200) {
      var url = res.url;

      var s = yield this.uploadFile(url, file);

      console.log(s);
      status = s;
    }
    this.loading = false;
    return status;
  }

  *updateSettings(obj) {
    this.checkSession();

    this.loading = true;

    var raw = {
      condition: { _id: this.userid },
      data: { settings: obj },
    };
    console.log(raw);
    var res = yield fetch(`${endpoint}/patients`, {
      method: "put",

      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(raw),
    });

    var status = res.status;
    res = yield res.json();
    console.log(res);
    yield this.getPatientData();
    this.loading = false;
    return status;
  }

  *loginFunc(phone, otp) {
    console.log("login ", phone, otp);

    var raw = {
      phone: phone,
      otp: otp,
    };
    var res = yield fetch(`${endpoint}/auth/patients/login`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(raw),
    });

    var status = res.status;
    res = yield res.json();
    console.log(res, status);
    if (status == 200) {
      let getPatientsListResponse = yield this.getPatientsList(res.phone);
      if (getPatientsListResponse.status == 200) {
        if (getPatientsListResponse?.res?.length > 1) {
          yield (this.showProfiles = true);
          yield Cookies.set("showProfiles", true, cookieAttributes);
        } else {
          yield (this.login = true);
          yield (this.userid = getPatientsListResponse?.res[0]?._id);
          yield this.getData();
        }
      }
    }
    return status;
  }

  *requestOTP(phone) {
    console.log("login ", phone);

    var raw = {
      phone: phone,
    };
    var res = yield fetch(`${endpoint}/auth/patients/otp`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(raw),
    });

    var status = res.status;
    res = yield res.json();
    console.log(res, status);
    if (status == 200) {
      this.loading = false;
    }
    return status;
  }

  async logoutFunc() {
    this.loading = true;
    var res = await fetch(`${endpoint}/auth/patients/logout`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    var status = res.status;
    res = await res.json();
    Cookies.remove("showProfiles", cookieAttributes);
    this.loading = false;
    return status;
  }

  *refreshStore() {
    // this.login = Cookies.get("plogin") == "true";
    if (this.login) {
      let cookiesUserId = yield Cookies.get("selectedUserId");
      let userId = this.userid || cookiesUserId;
      yield (this.decodedUserId = userId);
      yield (this.userid = userId);
    } else if (!this.showProfiles) {
      yield (this.login = false);
      yield (this.showProfiles = false);
    }
    yield (this.initialLoad = true);
  }

  *updatePrescription(arr) {
    this.checkSession();

    this.loading = true;
    var body = { data: arr };
    var res = yield fetch(`${this.endpoint}/patients/pillcompliance/bulk`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(body),
    });
    res = yield res.json();
    yield this.getPcArr();
    this.loading = false;
  }

  *getChatToken(refresh = false) {
    this.checkSession();

    this.loading = true;
    var path = "/chat/token";
    var options = {
      method: "get",

      credentials: "include",
      // headers: { "Content-Type": "application/json" },
    };
    if (refresh) {
      options["cache"] = "no-cache";
    }
    var res = yield fetch(`${endpoint}${path}`, options);
    res = yield res.json();
    console.log(res);
    this.chatToken = res.jwt;
    console.log(this.chatToken);
    this.chatClient = yield Client.create(this.chatToken);
    this.loading = false;
  }

  *getChannels(refresh = false) {
    this.checkSession();

    this.loading = true;
    var path = "/chat/channel";
    var options = {
      method: "get",

      credentials: "include",
      // headers: { "Content-Type": "application/json" },
    };
    if (refresh) {
      options["cache"] = "no-cache";
    }
    var res = yield fetch(`${endpoint}${path}`, options);
    res = yield res.json();
    console.log(res);
    this.chatChannels = res;
    yield this.joinChannel();
    yield this.loadChannelMessages();
    console.log(this.chatChannels);
    this.loading = false;
  }

  configureChannelEvents(channel, ind) {
    channel.on("messageAdded", (message) => {
      this.chatChannels[ind].messages.items.push(message);
    });
    channel.on("memberJoined", (member) => {
      console.log("member joined");
      // store.domainStore.addMessage({body: `${member.identity} has joined the channel.`});
    });
    channel.on("memberLeft", (member) => {
      // store.domainStore.addMessage({body: `${member.identity} has left the channel.`});
    });
  }

  *joinChannel(arr) {
    if (!arr) {
      arr = this.chatChannels;
    }

    for (var i = 0; i < arr.length; i++) {
      let channel = yield this.chatClient.getChannelBySid(arr[i]._id);
      channel =
        channel.channelState.status !== "joined"
          ? yield channel.join()
          : channel;
      this.chatChannels[i].channel = channel;
      this.configureChannelEvents(channel, i);
    }
  }

  *loadChannelMessages() {
    for (let i = 0; i < this.chatChannels.length; i++) {
      var m = yield this.chatChannels[i].channel.getMessages();
      this.chatChannels[i].messages = m;
    }
  }

  *createChannel(obj) {
    this.checkSession();

    this.loading = true;
    var path = "/chat/channel";
    var raw = {
      ...obj,
    };
    var res = yield fetch(`${endpoint}${path}`, {
      method: "post",
      // cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
    });
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    this.loading = false;
    return status;
  }

  *getPatientActiveRxes() {
    this.loadingRxes = true;
    let userId = this.userid || this.decodedUserId;
    let date = moment().utc(true).startOf("day").format();

    let res = yield fetch(
      `${endpoint}/patients/${userId}/date/${date}/activemedication`,
      {
        method: "get",
        credentials: "include",
        cache: "no-cache",
      }
    );
    res = yield res.json();
    this.activeRxes = res;
    this.loadingRxes = false;
  }

  *getUnreadData() {
    var res = yield fetch(
      `${endpoint}/chat/notifications?userId=${this.decodedUserId}`,
      {
        method: "get",
        cache: "no-cache",
        credentials: "include",
      }
    );
    res = yield res.json();
    console.log("unread data", res);
    this.unreadArr = res.unread;
    return res.unread;
  }

  *getPatientsList(phone = this.patientDetails.phone) {
    let date = moment().utc(true).format("YYYY-MM-DDTHH:mm:ss");
    var res = yield fetch(`${endpoint}/patients/list/phone/${phone}/${date}`, {
      method: "get",
      credentials: "include",
      cache: "no-cache",
    });

    var status = res.status;
    res = yield res.json();
    this.profilesList = res;
    return { res, status };
  }

  *setSelectedProfile(userId) {
    yield Cookies.set("selectedUserId", userId, cookieAttributes);
    yield Cookies.set("showProfiles", false, cookieAttributes);
    yield this.loadSelectedProfile(userId);
  }

  *loadSelectedProfile(id) {
    let cookies = yield Cookies.get("selectedUserId");
    let userId = id || cookies?.value;
    yield (this.userid = userId);
    yield resetActionStore.resetStoreState();
    yield (this.login = true);
    yield this.getData();
    message.success(`Selected Profile is ${this.patientDetails.name}`);
  }

  resetStore() {
    // this.loading = true;
    this.login = false;
    this.userid = null;
    this.decodedUserId = null;
    this.patientDetails = null;
    this.pharmacyDetails = null;
    this.rxarr = null;
    this.pcarr = null;
    this.pcarrDaily = null;
    this.reports = null;
    this.pillcomplianceAvg = null;
    this.doctorDetails = null;
    this.vitalChart = {};
    this.vitalRecords = [];
    this.chatToken = null;
    this.chatClient = null;
    this.chatChannels = [];
    this.selectedCompliance = null;
  }

  *checkSession() {
    let loginCookie = Cookies.get("plogin");
    let showProfilesCookie = Cookies.get("showProfiles");
    let shouldLogout = false;
    if (loginCookie === "false") {
      shouldLogout = true;
    } else if (loginCookie === "true" && showProfilesCookie === "true") {
      shouldLogout = true;
    }

    if (shouldLogout) {
      yield this.logoutFunc();
      this.resetStore();
    } else {
      yield (this.login = loginCookie === "true");
    }
  }

  *resetDbStore() {
    yield (this.patientDetails = null);
    yield (this.pharmacyDetails = null);
    yield (this.rxarr = null);
    yield (this.activeRxes = []);
    yield (this.pcarr = null);
    yield (this.pillboxDetails = {});
    yield (this.pcarrDaily = null);
    yield (this.reports = null);
    yield (this.pillcomplianceAvg = null);
    yield (this.doctorDetails = null);
    yield (this.vitalChart = {});
    yield (this.vitalRecords = []);
    yield (this.availableTechnicians = []);
    yield (this.contacts = {});
    yield (this.unreadArr = []);
    yield (this.otherNotifications = []);
    yield (this.meetNotif = []);
    yield (this.complianceNotif = []);
    yield (this.pillcompliancePercentage = 0);
    yield (this.pouchStatus = []);
    yield (this.loadingRxes = false);
    yield (this.currentPillBox = {});
    yield (this.showProfiles = false);
    yield (this.profilesList = []);
    yield (this.selectedUser = {});
    yield (this.shoudlForceUpdate = false);
    yield (this.UpdateApplicationInfo = {});
  }
}

const dbstore = new dbStore();
export { dbstore };
