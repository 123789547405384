import { Avatar, List, Typography } from "antd";
import React from "react";
import { observer } from "mobx-react";
import avatarImage from "../../../assets/images/avatar.png";
import "./profile.css";

const { Title } = Typography;

const ProfilesList = observer((props) => {
  function renderAgeAndGenderString(age, gender) {
    return age && gender
      ? `${age}, ${gender}`
      : !age
      ? gender
      : !gender
      ? age
      : "";
  }

  return (
    <List
      bordered={false}
      itemLayout="horizontal"
      dataSource={props?.data}
      renderItem={(item) => (
        <List.Item
          className="profile-list-item"
          style={{
            cursor: "pointer",
          }}
          onClick={() => props.onSelectProfile(item._id)}
        >
          <List.Item.Meta
            avatar={<Avatar src={avatarImage} size={60} />}
            title={<Title level={5}>{item.name}</Title>}
            description={renderAgeAndGenderString(item.age, item.gender)}
          />
        </List.Item>
      )}
    />
  );
});

export default ProfilesList;
