import {
  Button,
  Card,
  Col,
  Image,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from "antd";
import React from "react";
import { vitalsActionStore } from "../../../dataLayer/stores/actions/vitalsActionStore";
import { observer } from "mobx-react";
import { useState, useEffect } from "react";
import { vitalsStore } from "../../../dataLayer/stores/pages/vitalsStore";
import {
  capitalizeFirstLetter,
  getObjectKeys,
} from "../../../utils/Common.fns";
import {
  bloodGlucoseObj,
  bloodPressureObj,
  oxygenLevelsObj,
  weightObj,
  temperatureObj,
  vitalValueTypes,
} from "../../../utils/Common.variables";
import BloodGlucoseType from "./BloodGlucoseType";
import BloodPressureDeviceIcon from "../../reusable/icons/BloodPressureDeviceIcon";
import BloodGlucoseDeviceIcon from "../../reusable/icons/BloodGlucoseDeviceIcon";
import TemperatureDeviceIcon from "../../reusable/icons/TemperatureDeviceIcon";
import WeightDeviceIcon from "../../reusable/icons/WeightDeviceIcon";
import OximeterDeviceIcon from "../../reusable/icons/OximeterDeviceIcon";

const { Text } = Typography;
const colors = {
  bloodPressure: "#FF6773",
  bloodGlucose: "#6F66D1",
  temperature: "#0B82BA",
  weight: "#574FFA",
  oxygenLevels: "#ED8F03",
};

const VitalInput = observer(
  ({ type = bloodPressureObj.name, onSubmit = () => {} }) => {
    const [vitalObj, setvitalObj] = useState({
      type: type,
    });

    const [bloodGlucoseValueType, setBloodGlucoseValueType] =
      useState("Post-meal");

    useEffect(() => {
      setDefaultVitals();
    }, []);

    function setDefaultVitals() {
      console.log("setDefaultVitals");
      let vitalType = vitalsActionStore.selectedVital;
      if (vitalType !== bloodPressureObj.name) {
        if (vitalType === bloodGlucoseObj.name) {
          let tempObject = {
            type: vitalType,
            value: "105",
            valueType: bloodGlucoseValueType,
          };
          setvitalObj(tempObject);
          vitalsStore.setVitalReadings(tempObject);
        } else {
          let tempObject = {
            type: vitalType,
            value: "97",
          };
          setvitalObj(tempObject);
          vitalsStore.setVitalReadings(tempObject);
        }
      } else {
        let tempObject = {
          type: vitalType,
          systolic: "120",
          diastolic: "80",
          pulse: "72",
        };
        setvitalObj(tempObject);
        vitalsStore.setVitalReadings(tempObject);
      }
    }

    function handleBloodGlucoseType(value) {
      setBloodGlucoseValueType(value);
      setvitalObj({
        ...vitalObj,
        valueType: value,
      });
      vitalsStore.setVitalReadings({
        ...vitalObj,
        valueType: value,
      });
    }

    function renderVitalReadings() {
      if (type === bloodPressureObj.name) {
        return (
          <>
            <Text style={{ fontSize: 26 }}>{vitalObj?.systolic}</Text>
            <Text style={{ fontSize: 22 }}>{vitalObj?.diastolic}</Text>
            <Text style={{ fontSize: 20 }}>{vitalObj?.pulse}</Text>
          </>
        );
      } else {
        return (
          <>
            <Text style={{ fontSize: 36 }}>{vitalObj?.value}</Text>
          </>
        );
      }
    }

    function renderVitalInput(valueType) {
      return (
        <Col span={24}>
          <Text>{capitalizeFirstLetter(vitalsActionStore.selectedVital)}</Text>
          <InputNumber
            min={0}
            style={{ width: "100%", borderRadius: 10 }}
            size="large"
            controls={true}
            value={vitalObj[valueType]}
            onChange={(value) => {
              let temp = { ...vitalObj };
              temp[valueType] = `${value}`;
              setvitalObj(temp);
              vitalsStore.setVitalReadings(temp);
            }}
          />
        </Col>
      );
    }

    function renderInputByVitalType() {
      if (vitalsActionStore.selectedVital === bloodPressureObj.name) {
        return (
          <>
            {renderVitalInput("systolic")}
            {renderVitalInput("diastolic")}
            {renderVitalInput("pulse")}
          </>
        );
      } else {
        return renderVitalInput("value");
      }
    }

    function renderVitalTypeImage() {
      let selectedVital = vitalsActionStore.selectedVital;
      if (selectedVital === bloodPressureObj.name) {
        return (
          <BloodPressureDeviceIcon
            value={{
              systolic: vitalObj.systolic,
              diastolic: vitalObj.diastolic,
              pulse: vitalObj.pulse,
            }}
          />
        );
      } else if (selectedVital === bloodGlucoseObj.name) {
        return <BloodGlucoseDeviceIcon value={vitalObj.value} />;
      } else if (selectedVital === temperatureObj.name) {
        return <TemperatureDeviceIcon value={vitalObj.value} />;
      } else if (selectedVital === weightObj.name) {
        return <WeightDeviceIcon value={vitalObj.value} />;
      } else if (selectedVital === oxygenLevelsObj.name) {
        return <OximeterDeviceIcon value={vitalObj.value} />;
      }
    }

    return (
      <Card
        style={{
          borderRadius: 30,
          boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
          border: "none",
          height: "100%",
        }}
      >
        <Row gutter={[8, 16]}>
          <Col span={24}>
            <div style={{ width: "400px", height: "400px" }}>
              {renderVitalTypeImage()}
            </div>
          </Col>
          {renderInputByVitalType()}
          <Col span={24}>
            {vitalsActionStore.selectedVital === "bloodGlucose" && (
              <BloodGlucoseType
                color={colors[vitalsActionStore.selectedVital]}
                onChange={(v) => {
                  console.log("on chage:", v);
                  handleBloodGlucoseType(v);
                }}
              />
            )}
          </Col>
          <Col span={24}>
            <Button
              style={{
                backgroundColor: "#23D098",
                borderColor: "#23D098",
                maxWidth: 140,
                borderRadius: 20,
                color: "#fff",
              }}
              type="primary"
              onClick={() => {
                onSubmit(vitalObj);
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }
);

export default VitalInput;
