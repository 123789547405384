import _ from "lodash";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import { dbstore } from "../dbStore";

class Store {
  constructor() {
    makeAutoObservable(this);
  }
  calendarEvents = [];
  calendarData = {};

  async getCalendarData(id) {
    dbstore.checkSession();

    var path = "/patients/calendar";
    var options = {
      method: "get",

      credentials: "include",
      // headers: { "Content-Type": "application/json" },
    };
    options["cache"] = "no-cache";

    var res = await fetch(`${dbstore.endpoint}${path}`, options);
    res = await res.json();
    console.log(res);
    var meetings = res.meetings;
    var event = {};
    var events = [];
    for (var i = 0; i < meetings.length; i++) {
      var m = meetings[i];
      event = {
        id: m._id,
        title: "Video Call with " + m.name,
        start: moment(m.time).toDate(),
        end: moment(m.time).add(1, "h").toDate(),
        backgroundColor: "#8E2DE2",
        allDay: true,
      };

      events.push(event);
    }

    var pc = res.pc;
    var colors = {
      null: "#4853A4",
      taken: "green",
      missed: "orange",
    };
    for (var i = 0; i < pc.length; i++) {
      var p = pc[i];
      event = {
        id: p._id,
        title: "Pill Compliance " + p.rxno,
        start: moment(Number(p.date))
          .add(Number(p.time.split(":")[0]), "h")
          .toDate(),
        end: moment(Number(p.date))
          .add(Number(p.time.split(":")[0]), "h")
          .add(1, "h")
          .toDate(),
        backgroundColor: colors[String(p.status)],
        allDay: true,
      };

      events.push(event);
    }

    this.calendarEvents = [];
    Object.assign(this.calendarEvents, events);
    console.log(events);
  }

  *resetCalendarStore() {
    yield (this.calendarData = {});
  }
}

const calendarStore = new Store();
export default calendarStore;
