import React from "react";
import BloodPressureIcon from "./BloodPressureDeviceIcon";

const TemperatureDeviceIcon = (props) => {
  return (
    <svg width="100%" height="100%" viewBox="50 -80 250 650">
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M1.278540,474.228363 
C1.000000,317.908752 1.020802,161.817490 0.885663,5.726346 
C0.882114,1.627574 1.761077,0.887640 5.769265,0.891294 
C148.200317,1.021158 290.631500,1.000000 433.531342,1.000000 
C435.709412,4.571982 432.814362,4.943441 430.670837,5.512030 
C420.556671,8.194920 411.247620,12.419048 403.139862,19.159716 
C401.211426,20.762960 399.372375,22.439939 397.607544,24.205936 
C316.563599,105.304550 235.482086,186.365616 154.395096,267.421204 
C142.844727,278.967072 135.853134,293.248322 129.355957,307.956604 
C121.758842,325.154968 114.835030,342.678711 105.650459,359.147644 
C102.377228,365.016907 98.111725,370.194580 93.558281,375.011627 
C80.637825,388.679993 68.189545,402.777008 55.400471,416.563690 
C40.462559,432.666840 25.829351,449.053955 10.833014,465.106628 
C8.892234,467.184143 7.537143,469.707123 5.882315,472.003510 
C4.806134,473.496857 3.901273,475.226929 1.278540,474.228363 
z"
      />
      <path
        fill="#fff"
        opacity="1.000000"
        stroke="none"
        d="
M512.719360,81.774208 
C513.000000,224.264511 512.980835,366.529053 513.105713,508.793427 
C513.108948,512.512207 512.202271,513.089966 508.710541,513.087708 
C352.452850,512.986389 196.195084,513.000000 39.468658,513.000000 
C37.329628,510.668915 38.739368,509.190521 40.584251,508.150696 
C50.882328,502.346191 58.525539,493.367188 67.248116,485.717865 
C83.754150,471.242737 99.922188,456.378632 116.220161,441.664978 
C124.244881,434.420380 132.427231,427.343140 140.267792,419.898712 
C150.989105,409.719025 164.132538,403.735748 177.361511,397.741333 
C194.787704,389.845032 212.778336,383.155609 229.434036,373.591888 
C237.456955,368.985107 244.262085,362.790344 250.711700,356.350494 
C329.819885,277.361877 408.904053,198.349075 487.864166,119.212410 
C497.248993,109.806564 504.913635,99.192940 508.095825,85.896576 
C508.594025,83.814850 509.011230,81.001022 512.719360,81.774208 
z"
      />
      <path
        fill="#313C5B"
        opacity="1.000000"
        stroke="none"
        d="
M513.000000,81.531349 
C511.001129,81.932503 511.164276,83.510513 510.894958,84.762474 
C507.663330,99.786369 498.954742,111.488937 488.361664,122.082039 
C409.069946,201.373978 329.785156,280.672821 250.518539,359.989868 
C237.733994,372.782532 222.091827,380.783203 205.771637,388.062592 
C187.195679,396.348114 167.760101,402.915222 150.752151,414.529663 
C144.685913,418.672211 139.483917,423.801605 134.043579,428.616333 
C118.382675,442.476410 103.115105,456.779938 87.624649,470.833923 
C74.359367,482.869049 60.988968,494.788239 47.691002,506.787445 
C45.087936,509.136261 41.188190,509.376129 38.979847,512.643677 
C32.978848,513.000000 26.957697,513.000000 20.468273,513.000000 
C16.871597,510.886169 12.639072,511.751862 9.704126,508.655731 
C6.035977,504.786102 2.303932,501.137451 1.241525,495.277893 
C1.000000,488.312347 1.000000,481.624725 1.000000,474.468536 
C3.535582,474.186584 3.176884,471.714722 3.841834,470.510895 
C7.689821,463.544342 13.864310,458.566498 18.985373,452.754181 
C31.819145,438.188049 45.616104,424.470581 58.450821,409.905273 
C71.929398,394.609314 86.553154,380.342651 99.224098,364.327209 
C105.899681,355.889587 109.490807,345.854553 114.141808,336.343170 
C123.734810,316.725342 130.061890,295.502502 142.868988,277.473419 
C145.448792,273.841766 148.278458,270.314178 151.421906,267.167114 
C232.872726,185.622635 314.390869,104.145363 395.864929,22.624096 
C405.986633,12.496513 417.800964,5.620133 431.951202,2.965883 
C432.793793,2.807827 433.582764,2.650959 433.983887,1.383132 
C441.020966,1.000000 448.041901,1.000000 455.531433,1.000000 
C456.748566,1.775818 457.709167,1.911407 458.729370,1.996792 
C480.801880,3.844204 494.967224,17.089771 506.116333,34.774136 
C510.305145,41.418308 511.467377,48.879166 512.757935,56.705620 
C513.000000,65.020897 513.000000,73.041801 513.000000,81.531349 
z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
M513.000000,56.531342 
C511.004913,57.032104 511.267151,55.482182 510.959229,54.221058 
C505.858734,33.330803 493.811584,17.947933 474.548920,8.245244 
C468.648041,5.272939 462.081451,4.286905 456.019043,1.384525 
C473.930023,1.000000 491.860809,1.082052 509.789276,0.915234 
C512.614319,0.888948 513.100525,1.557080 513.076843,4.265483 
C512.925842,21.530121 513.000000,38.796726 513.000000,56.531342 
z"
      />
      <path
        fill="#000000"
        opacity="1.000000"
        stroke="none"
        d="
M0.999999,495.469727 
C1.571545,495.228394 2.479761,495.302917 2.664860,495.707947 
C6.250235,503.552948 12.606733,508.459381 20.007551,512.639648 
C14.401554,513.000000 8.800407,512.901428 3.206158,513.054626 
C1.299565,513.106812 0.902018,512.573853 0.952708,510.757538 
C1.090461,505.821564 1.000000,500.879242 0.999999,495.469727 
z"
      />
      <path
        fill="#D8E1E8"
        opacity="1.000000"
        stroke="none"
        d="
M70.117157,453.246216 
C63.718948,447.198700 57.770267,441.146484 51.547409,435.390656 
C49.135124,433.159424 49.763649,431.902496 51.661278,429.847748 
C66.689819,413.574677 81.515984,397.114044 96.600555,380.893555 
C105.767776,371.035980 113.118561,360.121307 118.466515,347.739899 
C125.321899,331.868530 132.622375,316.190033 139.583771,300.363678 
C144.475235,289.243195 151.280289,279.529907 159.860916,270.953979 
C237.619141,193.238602 315.354950,115.500763 393.032379,37.704693 
C395.577637,35.155560 396.990326,34.400356 399.983551,37.434666 
C422.442719,60.201981 445.118866,82.755234 467.870300,105.676537 
C468.933289,107.957047 468.137604,109.489006 466.789551,110.928596 
C465.536774,112.266411 464.264801,113.584129 462.969269,114.879517 
C385.231506,192.608688 307.473816,270.317932 229.786346,348.097382 
C219.430756,358.465210 206.460693,364.424377 193.383636,370.336761 
C175.347275,378.491364 156.795654,385.541779 139.544373,395.395752 
C132.677856,399.317932 126.896561,404.644562 121.155502,409.897278 
C106.061363,423.707611 90.625214,437.139221 75.675117,451.106140 
C74.184662,452.498596 72.755348,453.899078 70.117157,453.246216 
z"
      />

      <path
        fill="#C1CDD3"
        opacity="1.000000"
        stroke="none"
        d="
M69.924744,453.436462 
C78.542061,445.229187 87.305580,437.324249 96.072197,429.422791 
C108.050087,418.627075 119.633018,407.364899 132.303757,397.378143 
C142.201233,389.577209 154.057007,385.265717 165.372925,380.011444 
C181.059631,372.727722 197.368423,366.770142 212.446594,358.198639 
C218.680206,354.654968 224.304016,350.265320 229.411667,345.153076 
C307.559265,266.935059 385.754791,188.764923 463.931152,110.575607 
C465.224213,109.282341 466.417694,107.889557 467.835602,106.260437 
C471.354828,109.214348 474.640686,112.511330 478.062653,115.660400 
C479.596191,117.071663 479.949982,118.184616 478.221222,119.661850 
C476.958771,120.740654 475.860321,122.010750 474.682037,123.188858 
C397.729919,200.128036 320.757812,277.047241 243.850464,354.031158 
C234.900452,362.990051 224.791245,370.055634 213.193481,375.142487 
C197.042374,382.226471 181.026642,389.618958 164.879364,396.711853 
C153.940613,401.516815 144.132858,407.942139 135.297882,415.962250 
C118.518860,431.193665 101.589066,446.260040 84.911217,461.600800 
C81.815094,464.448669 79.962021,464.359314 77.414230,461.234222 
C75.213676,458.535126 72.537971,456.223419 69.924744,453.436462 
z"
      />

      <path
        fill="#9196AA"
        opacity="1.000000"
        stroke="none"
        d="
M474.364563,100.172394 
C450.870331,77.052452 427.790222,54.032185 404.659058,31.063343 
C402.831116,29.248213 402.801300,27.958593 404.665039,26.147696 
C431.168152,0.395835 472.277924,6.368488 490.794006,30.732538 
C502.598114,54.805119 496.560150,76.088364 481.591644,96.005859 
C479.982422,98.147133 478.193909,100.715950 474.364563,100.172394 
z"
      />
      <path
        fill="#9196AA"
        opacity="1.000000"
        stroke="none"
        d="
M13.092659,495.418457 
C7.554802,488.154572 7.588111,478.737061 13.605021,471.813293 
C22.644171,461.411713 32.152920,451.419464 41.357056,441.159027 
C43.200428,439.104065 44.395844,439.413269 46.123474,441.211639 
C51.988041,447.316498 57.969738,453.308838 64.043556,459.657959 
C64.215469,461.945465 63.346111,463.496490 61.893757,464.747894 
C51.953518,473.312805 42.685532,482.652802 32.518181,490.927063 
C27.173719,495.276398 21.067295,499.324951 13.092659,495.418457 
z"
      />
      <path
        fill="#707583"
        opacity="1.000000"
        stroke="none"
        d="
M12.872721,495.548126 
C25.230484,495.775970 33.057991,487.573730 41.311138,480.127411 
C48.690361,473.469543 56.137543,466.887024 63.867577,460.121521 
C75.932991,469.280518 75.964607,469.314911 65.281372,479.132416 
C58.075188,485.754547 50.862106,492.375305 43.476273,498.794373 
C33.515007,507.451721 20.497145,506.114777 12.872721,495.548126 
z"
      />
      <path
        fill="#707583"
        opacity="1.000000"
        stroke="none"
        d="
M474.143433,100.336426 
C488.539001,87.276405 494.529846,70.925835 494.338531,51.844944 
C494.269287,44.940407 492.429474,38.436764 490.969055,31.418310 
C501.898621,42.891815 505.744385,57.269508 504.718475,72.899086 
C503.832672,86.394814 498.528168,98.306053 489.308777,108.408684 
C486.657837,111.313591 484.601746,111.429184 482.009155,108.457581 
C479.606567,105.703796 476.897491,103.217415 474.143433,100.336426 
z"
      />
      <path
        fill="#343F5F"
        opacity="1.000000"
        stroke="none"
        d="
M305.458710,260.459351 
C285.073883,280.842041 264.941132,300.976746 244.802856,321.105957 
C239.255066,326.651245 238.966080,326.644958 233.284195,320.966766 
C219.621826,307.313232 205.956284,293.662842 192.309372,279.993866 
C188.179840,275.857666 188.153046,274.664001 192.213379,270.601135 
C233.174606,229.614578 274.147125,188.639297 315.127136,147.671524 
C319.141693,143.658157 320.593323,143.638672 324.608246,147.639999 
C338.643097,161.627441 352.654205,175.638779 366.647888,189.667450 
C370.855225,193.885284 370.820465,195.086578 366.460510,199.450851 
C346.218689,219.712448 325.960815,239.958008 305.458710,260.459351 
z"
      />
      <path
        fill="#353D5D"
        opacity="1.000000"
        stroke="none"
        d="
M376.793488,146.362045 
C366.382629,139.160950 362.423676,128.760651 365.791809,118.056648 
C369.094421,107.560692 378.446381,100.795914 389.480988,100.920906 
C400.191498,101.042229 409.501221,107.932877 412.399506,118.372604 
C415.132446,128.216812 412.797302,137.006073 404.983765,143.662048 
C396.531982,150.861755 387.020844,151.589569 376.793488,146.362045 
z"
      />
      <path
        fill="#B9DDFC"
        opacity="1.000000"
        stroke="none"
        d="
M284.771851,269.771851 
C270.385284,284.158661 256.183868,298.231812 242.173431,312.492523 
C239.698212,315.011963 238.381790,314.950348 235.952927,312.464264 
C224.660797,300.906128 213.227539,289.482788 201.675903,278.183807 
C199.314957,275.874512 199.456711,274.661957 201.721680,272.404327 
C240.188446,234.062302 278.583618,195.648346 316.918854,157.174805 
C319.159576,154.926025 320.420776,154.650620 322.804535,157.091797 
C334.210358,168.772430 345.741425,180.335312 357.440796,191.721313 
C360.008606,194.220337 359.220093,195.404800 357.155151,197.459946 
C333.065430,221.435089 309.054077,245.488953 284.771851,269.771851 
z"
      />
      <g>
        <text
          dz="M260.88"
          transform="translate(270 280) rotate(-45)"
          style={{
            fill: "black",
            fontSize: "64px",
            fontFamily: "Nunito-SemiBold, Nunito",
            fontWeight: "600",
          }}
        >
          <tspan>{props.value}</tspan>
        </text>
      </g>
      <path
        fill="#ED5361"
        opacity="1.000000"
        stroke="none"
        d="
M399.099762,138.234116 
C392.479523,142.641922 386.012573,142.929901 379.720032,138.625702 
C374.116455,134.792770 371.978241,129.167755 373.041931,122.550339 
C374.115234,115.872818 378.182220,111.535713 384.624115,109.566551 
C391.734985,107.392899 399.206573,110.406685 403.092285,116.857376 
C407.224182,123.716637 405.844940,131.594025 399.099762,138.234116 
z"
      />
    </svg>
  );
};

export default TemperatureDeviceIcon;
