import { Col, Row, Layout, message } from "antd";
import { useEffect, useState } from "react";
import ProfileContactDetails from "../ProfileContactDetails";
import ProfilePersonalDetails from "../ProfilePersonalDetails";
import VitalInfoCard from "../VitalInfoCard";
import { profileStore } from "../../dataLayer/stores/pages/profileStore";
import { observer } from "mobx-react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { vitalsActionStore } from "../../dataLayer/stores/actions/vitalsActionStore";
import { profileActionStore } from "../../dataLayer/stores/actions/profileActionStore";
import { dbstore } from "../../dataLayer/stores/dbStore";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Profile = observer((props) => {
  let history = useHistory();
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={10}>
          <div style={box}>
            {console.log(profileStore.comp1Data)}
            <ProfilePersonalDetails data={profileStore.comp1Data} uploadProfilePic={async (file) => {
              var res = await profileActionStore.setProfilePic(file); if (res == 200) {
                await dbstore.getPatientData()
                message.success("Profile Pic Successfully Changed");

              } else {
                message.error("Oops! something went wrong")
              }
            }} />
          </div>
        </Col>
        <Col span={14}>
          <div style={box}>
            <ProfileContactDetails data={profileStore.comp2Data} onSettingsChange={async (settings) => {
              var res = await dbstore.updateSettings(settings)
              if (res == 200) {
                message.success("Setting changed successfully")
              } else {
                message.error("Oops! something went wrong")
              }
            }} />
          </div>
        </Col>
        {/* <Col span={22}>
          <div style={box}>
            <Row gutter={[32, 32]}>
              <VitalInfoCard
                vitalKey="bloodPressure"
                onClick={(key) => {
                  history.push(`/vitals`);

                  vitalsActionStore.setVital(key);
                }}
                data={profileStore.comp3Data}
              />
              <VitalInfoCard
                vitalKey="bloodGlucose"
                data={profileStore.comp3Data}
                onClick={(key) => {
                  history.push(`/vitals`);

                  vitalsActionStore.setVital(key);
                }}
              />
              <VitalInfoCard
                vitalKey="temperature"
                data={profileStore.comp3Data}
                onClick={(key) => {
                  history.push(`/vitals`);

                  vitalsActionStore.setVital(key);
                }}
              />
              <VitalInfoCard
                vitalKey="weight"
                data={profileStore.comp3Data}
                onClick={(key) => {
                  history.push(`/vitals`);

                  vitalsActionStore.setVital(key);
                }}
              />
              <VitalInfoCard
                vitalKey="oxygenLevels"
                data={profileStore.comp3Data}
                onClick={(key) => {
                  history.push(`/vitals`);

                  vitalsActionStore.setVital(key);
                }}
              />
            </Row>
          </div>
        </Col> */}
      </Row>
    </>
  );
});
export default Profile;
